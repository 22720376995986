import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { AlertOutlined } from '@ant-design/icons';
import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  taskType?: string;
  step?: string;
}

const TutorialTaskTab: React.FC<Props> = ({ taskType, step }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '006_TaskTab' ? true : false;
  const canWriteFeedback =
    !!store.profile?.roles && (store.profile.roles.includes('admin') || store.profile.roles.includes('customer_sucess'))
      ? true
      : false;
  const hasFeedback = store.hasFeedback === true;
  const hasPendingFeedback = store.hasPendingFeedback === true;

  const isStepCheck = step === 'check' ? true : false;
  const isDefault = taskType === 'default' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="title_task"]',
      content: 'Para editar o título, clique e edite. Coloque emoji também se desejar.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'left',
    },
    {
      selector: '[id="cell_task"]',
      content: 'Selecione a célula da atividade.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'left',
    },
    {
      selector: '[id="responsibles_task"]',
      content: 'Selecione os usuários que serão responsáveis pela atividade.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'left',
    },
    ...[
      isDefault
        ? {
            selector: '[id="execution_date_task"]',
            content: 'Defina a data de execução.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
            position: 'left',
          }
        : undefined,
    ],
    {
      selector: '[id="finish_date_task"]',
      content: isDefault ? 'Defina a data de publicação.' : 'Defina a data de encerramento.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'left',
    },
    {
      selector: '[id="step_task"]',
      content: 'As etapas podem ser alteradas clicando no ícono de cada etapa.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'left',
    },
    {
      selector: '[id="description_task"]',
      content: 'Adicione ou edite a descrição da atividade.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'left',
    },
    ...[
      canWriteFeedback && isStepCheck
        ? {
            selector: '[id="write_feedback_task"]',
            content:
              'Quando a atividade estiver em revisão, você pode escrever um feedback em relação a atividade executada.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
            position: 'left',
          }
        : undefined,
    ],
    {
      selector: '[id="subtarefas_task"]',
      content: 'As subtarefas podem ser criadas dentro das atividades. Escreva o nome da subtarefa e crie.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'left',
    },
    {
      selector: '[id="files_task"]',
      content:
        'Anexe arquivos pdf e jpeg nas atividades. Depois de anexar, você pode excluir ou fazer download dos arquivos.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'left',
    },
    ...[
      isDefault && hasFeedback && hasPendingFeedback
        ? {
            selector: '[id="feedbacks_task"]',
            content: () => {
              return (
                <div style={{ marginLeft: 10 }}>
                  <Typography className={styles.text}>Veja os feedbacks de suas atividades.</Typography>

                  <Typography className={styles.orange}>{<AlertOutlined />} Atenção!</Typography>
                  <Typography className={styles.attention_text}>
                    Caso o feedback tenha sido aplicado, clique em "Pendente" para mudar o status para "Concluído".
                  </Typography>
                </div>
              );
            },
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
            position: 'left',
          }
        : undefined,
    ],
    ...[
      isDefault && hasFeedback && !hasPendingFeedback
        ? {
            selector: '[id="feedbacks_see_all_task"]',
            content: () => {
              return (
                <div style={{ marginLeft: 10 }}>
                  <Typography className={styles.text}>
                    Esta atividade não possui feedbacks pendentes, em "Ver todos" veja todos os feedbacks concluídos.
                  </Typography>

                  <Typography className={styles.text}>
                    Quando existem feedbacks pendentes, eles são mostrados automaticamente.
                  </Typography>

                  <Typography className={styles.orange}>{<AlertOutlined />} Atenção!</Typography>
                  <Typography className={styles.attention_text}>
                    Caso o feedback tenha sido aplicado, clique em "Pendente" para mudar o status para "Concluído".
                  </Typography>
                </div>
              );
            },
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
            position: 'left',
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    'title_task',
    'cell_task',
    'responsibles_task',
    ...[isDefault ? 'execution_date_task' : undefined],
    'finish_date_task',
    'step_task',
    'description_task',
    ...[canWriteFeedback && isStepCheck ? 'write_feedback_task' : undefined],
    'subtarefas_task',
    'files_task',
    ...[isDefault && hasFeedback ? 'feedbacks_task' : undefined],
    ...[isDefault && !hasFeedback ? 'feedbacks_see_all_task' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('ant-tabs-content-holder', 'class_name')}
      onBeforeClose={() => enableScroll('ant-tabs-content-holder', 'class_name')}
    />
  );
};

export default observer(TutorialTaskTab);

/*function checkPage(target: string, select: 'tag_name' | 'class_name'): Element {
  let page: any = undefined;

  switch (select) {
    case 'tag_name':
      page = document.getElementsByTagName(target)[0];
      break;
    case 'class_name':
      page = document.getElementsByClassName(target)[0];
      break;
  }

  return page;
}

function preventDefault(e: any) {
  if (e.preventDefault) e.preventDefault();
  e.returnValue = false;
}

function disableScrollTeste(target: string, select: 'tag_name' | 'class_name', currStep: number): void {
  const page = checkPage(target, select);
  if (currStep === 0) page.scrollTop = 0;
  //@ts-ignore
  page.addEventListener('wheel', preventDefault, { passive: false });
}

function enableScrollTeste(target: string, select: 'tag_name' | 'class_name'): void {
  const page = checkPage(target, select);
  //@ts-ignore
  page.removeEventListener('wheel', preventDefault, { passive: false });
}*/
