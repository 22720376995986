import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { DateTime } from 'luxon';
import { Card, Button, notification, Form, Input } from 'antd';

import Clock from '../Clock';

import DatePicker from '@comp/antd/DatePicker';

import { end_timer, update_timer } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  taskId?: string;
  timerId: string;
  startTime: any;
  timerBody: string;
  refresh: () => void;
}

function formatTime(time: string) {
  return DateTime.fromISO(time).toUTC().toFormat('yyyy-LL-dd HH:mm:ss');
}

// Exibição/edição do timer

const CurrentTimer: React.FC<Props> = ({ taskId, startTime, timerId, timerBody, refresh }) => {
  const [form] = Form.useForm();

  const { t } = useTranslation('userTimer');

  //--------------------------- MUTATIONS ---------------------------------

  const [EndTimer] = useMutation(end_timer);
  const [UpdateTimer] = useMutation(update_timer);

  //---------------------------- FUNCTIONS ----------------------------------
  async function Stop() {
    try {
      if (!taskId) {
        notification.open({
          placement: 'bottomRight',
          type: 'error',
          message: t('message_without_task'),
          description: t('description_without_task'),
        });
        return;
      }
      await EndTimer({ variables: { task: taskId } });

      notification.open({
        placement: 'bottomRight',
        type: 'success',
        message: t('stop_message'),
      });

      refresh();
    } catch (err) {
      notification.open({
        placement: 'bottomRight',
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }
  async function Edit(timerId: string, start_date: string, body: string) {
    try {
      await UpdateTimer({
        variables: { id: timerId, start_date, body },
      });

      notification.open({
        type: 'success',
        message: t('edit_message'),
      });

      refresh();
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  const submit = useCallback(() => form.submit(), [form]);

  const format = 'yyyy-LL-dd HH:mm:ss';
  const zone = { zone: 'utc' };

  const start = DateTime.fromFormat(startTime || '', format, zone).toLocal();

  //------------------------------------------------------------------------------

  return (
    <Card className={styles.card}>
      <Form
        form={form}
        layout="horizontal"
        name={`edit_current_timer`}
        onBlur={submit}
        onFinish={({ start_date, body }) => {
          Edit(timerId, formatTime(moment(start_date).toISOString()), body);
        }}
        initialValues={{
          start_date: moment(start.toISO()),
          body: timerBody,
        }}>
        <div className={styles.item}>
          <Form.Item name="start_date" noStyle>
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format="HH:mm:ss - DD/MM/YYYY"
              showSecond={false}
              bordered={false}
            />
          </Form.Item>

          <div className={styles.button}>
            <Button type="primary" style={{ marginRight: '6px' }}>
              <Clock time={startTime} />
            </Button>

            <Button onClick={() => Stop()}>{t('stop_timer')}</Button>
          </div>
        </div>

        <Form.Item name="body">
          <Input.TextArea placeholder={t('body_placeholder')} autoSize={{ minRows: 1, maxRows: 20 }} />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CurrentTimer;
