import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Typography } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';

interface Props {
  taskId: string;
}

const task_from_template = gql`
  query Task($id: ID!) {
    task(id: $id) {
      id
      from_template_item {
        id
        title
        body
        template {
          id
          title
        }
        subitems(pagination: { limit: -1, page: 1 }, filter: { excludeSubitems: true }) {
          data {
            id
            title
          }
        }
      }
    }
  }
`;

// Aba que mostra o template do qual foi criada a atividade

const HelpTab: React.FC<Props> = ({ taskId }) => {
  //--------------------------- Query ------------------------------

  const { data, loading, error } = useQuery(task_from_template, {
    variables: { id: taskId },
    fetchPolicy: 'cache-and-network',
  });

  //-----------------------------------------------------------------

  if (error) return <ErrorAndLoading error={error} />;

  if (loading) return <ErrorAndLoading loading={loading} />;

  const fromTemplate = data?.task?.from_template_item;

  if (!fromTemplate) return null;

  return (
    <div>
      <Typography.Title style={{ fontSize: 14 }}>Título:</Typography.Title>
      <Typography.Title style={{ fontSize: 20, marginTop: 0 }}>{fromTemplate.title}</Typography.Title>

      <div style={{ height: 16 }} />

      <Typography.Title style={{ fontSize: 14 }}>Descrição:</Typography.Title>
      <Typography.Text
        style={{
          fontSize: 16,
          border: '1px solid #e5e5e5',
          borderRadius: 4,
          display: 'flex',
          padding: '2px 4px',
          whiteSpace: 'break-spaces',
        }}>
        {fromTemplate.body}
      </Typography.Text>
    </div>
  );
};

export default HelpTab;
