import { NexusGenFieldTypes } from 'schema';

import { Duration } from 'luxon';

import TaskEvent from './TaskEvent';
import FromTo from './FromTo';

interface Props {
  data: NexusGenFieldTypes['TaskEventPlannedSecondsChanged'];
}

const TaskEventPlannedSecondsChanged: React.FC<Props> = ({ data }) => {
  const from = data.old_planned_seconds
    ? Duration.fromMillis(data.old_planned_seconds * 1000).toFormat('hh:mm:ss')
    : null;

  const to = data.new_planned_seconds
    ? Duration.fromMillis(data.new_planned_seconds * 1000).toFormat('hh:mm:ss')
    : 'em branco';

  return (
    <TaskEvent data={data}>
      alterou a tempo estimado <FromTo from={from} to={to} />
    </TaskEvent>
  );
};

export default TaskEventPlannedSecondsChanged;
