export default function errorMessage(error_type: string, error: any) {
  console.error(error);

  let description = '';
  let message = '';

  if (error_type === 'graph_err') description = error.graphQLErrors?.map((e: any) => e.message);

  switch (error_type) {
    case 'form_validation':
      message = 'Verifique os dados e tente novamente';
      break;
    default:
      message = 'Ocorreu um erro, tente novamente';
  }

  return { message, description };
}
