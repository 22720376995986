import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, notification, Row, Col, Typography } from 'antd';
import { useMutation } from '@apollo/client';

import EmojiLinkInput from '@comp/EmojiLinkInput';
import Help from '@comp/Help';

import { route } from '@router';
import errorMessage from '@logic/functions/errorHandeling';
import { account_info_create } from '@logic/mutations';

import styles from './styles.module.less';

interface Props {
  account?: string;
}

const NewInfo: React.FC<Props> = ({ account }) => {
  const { id: paramAccountId } = useParams<any>();
  const navigate = useNavigate();

  const accountId = account || paramAccountId;

  const defaultValues: any = { account: accountId, title: '', body: '' };

  const [info, setInfo] = useState<any>(defaultValues);

  //---------------------- QUERIES & MUTATIONS -----------------------------

  const [CreateAccountInfo] = useMutation(account_info_create);

  //--------------Save function ------------------------------------------

  async function doSave(title: string, body?: string) {
    if (!title) return;

    try {
      const { data } = await CreateAccountInfo({ variables: { account: accountId, title, body } });

      navigate(route('id.home.infos', { id: accountId }) + '#acc_info/' + data.createAccountInfo?.id);
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  //-------------------------- Form function -------------------------------

  const handleChange = ({ target: { name, value } }: any) => {
    setInfo((t: any) => ({ ...t, [name]: value }));
  };

  //------------------------------------------------------------------------------

  return (
    <div className={styles.root} style={{ height: '100%' }}>
      <Row id="title_info" className={styles.title}>
        <Col span={3} className={styles.column}>
          <Typography.Text className={styles.label}>Título: </Typography.Text>
          <Help
            title="Título da informação"
            description="Este é um campo obrigatório, a informação só pode ser salva se houver um título"
            placement="right"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={21}>
          <EmojiLinkInput
            name="title"
            placeholder="Título da informação útil"
            border
            value={info.title}
            onChange={handleChange}
            emojiPicker="focus"
          />
        </Col>
      </Row>

      <Row id="description_info" className={styles.body}>
        <Col span={3} className={styles.column}>
          <Typography.Text className={styles.label}>Descrição: </Typography.Text>
          <Help
            title="Descrição da informação"
            description="Neste campo escreva a informação referente ao cliente."
            placement="right"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={21}>
          <EmojiLinkInput
            md
            name="body"
            placeholder="Descrição da informação útil"
            border
            multiline
            value={info.body}
            onChange={handleChange}
            emojiPicker="focus"
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={6}>
          <Button
            id="save_info_button"
            type="primary"
            htmlType="submit"
            disabled={!info.title}
            block
            onClick={() => doSave(info.title, info.body)}>
            Salvar
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NewInfo;
