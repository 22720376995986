import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Tooltip } from 'antd';

import AvatarPicture from '@comp/AvatarPicture';

interface Props {
  accountId: string;
}
const fetch_account_logo_and_name = gql`
  query AccountLogoAndName($id: ID!) {
    account(id: $id) {
      id
      name
      logoUrl
    }
  }
`;

const ActivityAccountLogo: React.FC<Props> = ({ accountId }) => {
  // Fetch account name and logo
  const { data } = useQuery(fetch_account_logo_and_name, {
    variables: { id: accountId },
    //fetchPolicy:'cache-and-network'
  });

  return (
    <div id="icon_account_activities_calendar">
      <Tooltip destroyTooltipOnHide={{ keepParent: false }} placement="left" title={data?.account?.name}>
        <div style={{ marginLeft: 4, marginRight: 4, cursor: 'pointer' }}>
          <AvatarPicture pictureLink={data?.account?.logoUrl} target="Account" size={20} name={data?.account?.name} />
        </div>
      </Tooltip>
    </div>
  );
};

export default ActivityAccountLogo;
