import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useHashInfo(): any {
  const location = useLocation();

  const [hashObject, setHashObject] = useState({});

  useEffect(() => {
    setHashObject(location.hash.split('/').reduce((acc, curr, idx) => ({ ...acc, [idx]: curr }), {}));
  }, [location.hash]);

  return hashObject;
}
