import React from 'react';
import { Card, Row, Typography } from 'antd';

import { RiMedalLine } from 'react-icons/ri';

import examplePicture01 from '../../../img/example/example01.jpg';
import examplePicture02 from '../../../img/example/example02.jpg';
import examplePicture03 from '../../../img/example/example03.jpg';

import useWindowSize from '@logic/functions/windowSize';

import AdsTable from './AdsTable';

import styles from './styles.module.less';

//Card de top 3 anúncios
//exibe os 3 melhores anúncios com seus resultados
//de clique, alcance, impressões e valor gasto
//os dados ficam dentro de uma tabela

const TopAds: React.FC = () => {
  const { width } = useWindowSize();

  //  TODO  query para exibir os dados reais quando o back estiver pronto

  return (
    <Card style={cardStyle} bodyStyle={cardBodyStyle}>
      <Row className={styles.title}>
        <Typography.Title className={styles.title_text}>
          <RiMedalLine style={{ color: 'rgba(250, 190, 44, 1)' }} className={styles.title_icon} size={18} />
          <RiMedalLine style={{ color: ' rgba(118, 131, 143, 1) ' }} className={styles.title_icon} size={18} />
          <RiMedalLine style={{ color: 'rgba(216, 139, 86, 1)' }} className={styles.title_icon} size={18} />
          Top 3 Anúncios
        </Typography.Title>
      </Row>

      <Row style={{ minHeight: width > 1600 && width < 1684 ? 270 : 290 }} className={styles.ads_row}>
        <div className={styles.item}>
          <AdsTable data={data} />
        </div>
      </Row>
    </Card>
  );
};
export default TopAds;

const cardStyle = { borderRadius: 4, overflow: 'hidden', boxShadow: '0 1px 4px #00000040', height: 362 };
const cardBodyStyle = { padding: 0 };

//dados mockups para exemplo
//colocado nome gigante para verificar se ele quebra em 3 linhas e depois coloca ellipsis
const data = [
  {
    img: examplePicture01,
    name:
      '12.2020 - PERF_FB_IG_CONV(LP)_SEGURO01_teste_teste_teste_teste_teste_teste_teste_tttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt',
    clicks: 114,
    reach: 8244,
    impressions: 8244,
    spent_amount: 124.05,
  },
  {
    img: examplePicture02,
    name: '12.2020 - PERF_FB_IG_CONV(LP)_SEGURO02',
    clicks: 81,
    reach: 7417,
    impressions: 12806,
    spent_amount: 157.2,
  },
  {
    img: examplePicture03,
    name: '12.2020 - PERF_FB_IG_CONV(LP)_SEGURO03',
    clicks: 240,
    reach: 9642,
    impressions: 13506,
    spent_amount: 148.74,
  },
];
