import React, { useCallback, useRef, PropsWithChildren } from 'react';

interface Props {
  onClick: () => void;
  onDbClick: () => void;
  onBlur?: () => void;
  style?: React.CSSProperties;
}

const Clickable: React.FC<PropsWithChildren<Props>> = ({ onClick, onDbClick, onBlur, style, children }) => {
  let status = useRef<any>();

  // A reação do primeiro clique é retardada em 300ms para que, se houver um segundo clique
  // o callback "doubleClick" tenha tempo de cancelar a ação do "singleClick"

  const singleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      status.current = 1;

      setTimeout(() => {
        if (status.current === 1) {
          status.current = 0;
          onClick();
        }
      }, 300);
    },
    [onClick, status],
  );

  // O clique duplo sempre vai acionar o clique único,
  // mas caso haja um segundo clique a ação do primeiro é cancelada

  const doubleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();

      if (status.current === 1) {
        status.current = 0;
        onDbClick();
      }
    },
    [status, onDbClick],
  );

  return (
    <div style={style} onBlur={onBlur} onClick={singleClick} onDoubleClick={doubleClick}>
      {children}
    </div>
  );
};

export default Clickable;
