import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { Card, Button, Form, Input, notification, Typography, List, Select, Popover, Tag } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import ListWithAddTo from '@comp/ListWithAddTo';
import UploadAvatar from '@comp/UploadAvatar';
import AvatarPicture from '@comp/AvatarPicture';
import TutorialButton from '@comp/TutorialButton';
// import LoginButtons from './LoginButtons';
import TutorialEditAccount from './TutorialEditAccount';

import {
  account_edit,
  add_user_to_account,
  remove_user_from_account,
  add_account_to_cell,
  remove_account_from_cell,
  sync_leads,
  add_mailerlite_field,
  delete_mailerlite_field,
  refresh_api_key,
} from '@logic/mutations';
import { accounts_info, account_customers_select, cells_select } from '@logic/queries';
import errorMessage from '@logic/functions/errorHandeling';
import { route } from '@router';

import styles from './styles.module.less';
import Help from '@comp/Help';

const EditAccount: React.FC = () => {
  const [confirmUser, setConfirmUser] = useState<number | undefined>();
  const [confirmCell, setConfirmCell] = useState<number | undefined>();
  const [userIdList, setUserIdList] = useState<string[]>([]);
  const [cellIdList, setCellIdList] = useState<string[]>([]);
  const [accountInfo, setAccountInfo] = useState<{
    name: string;
    status: string;
    journey: string;
    monthly_planned_seconds: number;
    mailerlite_apikey: string;
    api_key: string;
  }>();
  const [addUser, setAddUser] = useState(false);
  const [addCell, setAddCell] = useState(false);

  const [showRefreshApiModal, setShowRefreshApiModal] = useState(false);

  const { t } = useTranslation('editAccount');

  const [form] = Form.useForm();
  const { account } = useParams<any>();
  const navigate = useNavigate();

  const [mailerliteField, setMailerliteField] = useState({ key: '', name: '' });

  //---------------------- QUERIES & MUTATIONS -----------------------------

  //----------------------- INFO ------------------------

  const [UpdateAccount] = useMutation(account_edit);
  const { data, loading, error, refetch } = useQuery(accounts_info, {
    variables: { id: account },
    fetchPolicy: 'network-only',
  });

  //----------------------- USERS -----------------------

  const [AttachUserToAccount] = useMutation(add_user_to_account);
  const [DetachUserFromAccount] = useMutation(remove_user_from_account);

  //----------------------- CELLS -----------------------

  const [AttachAccountToCell] = useMutation(add_account_to_cell);
  const [DetachAccountFromCell] = useMutation(remove_account_from_cell);

  const [SyncLeads] = useMutation(sync_leads);
  const [RefreshApiKey] = useMutation(refresh_api_key);
  const [AddMailerliteField] = useMutation(add_mailerlite_field);
  const [DeleteMailerliteField] = useMutation(delete_mailerlite_field);

  //--------- cria o array para o select de celulas e de usuários -------------

  useEffect(() => {
    if (!data) return;

    const newAccountInfo = data.account;
    setAccountInfo({
      name: newAccountInfo.name,
      status: newAccountInfo.status,
      journey: newAccountInfo.journey,
      monthly_planned_seconds: newAccountInfo.monthly_planned_seconds / 3600,
      mailerlite_apikey: newAccountInfo.mailerlite_apikey,
      api_key: newAccountInfo.api_key,
    });

    const idListUsers = data.account?.customers?.data?.map(({ id }: { id: string }) => id);
    setUserIdList(idListUsers);

    const idListCells = data.account?.cells?.data?.map(({ id }: { id: string }) => id);
    setCellIdList(idListCells);
  }, [data]);

  useLayoutEffect(() => {
    if (!accountInfo) return;

    form.setFieldsValue({
      name: accountInfo.name,
      status: accountInfo.status,
      journey: accountInfo.journey,
      monthly_planned_seconds: accountInfo.monthly_planned_seconds,
      mailerlite_apikey: accountInfo.mailerlite_apikey,
      api_key: accountInfo.api_key,
    });
  }, [accountInfo, form]);

  //----------------- Save, delete, edit functions -----------------------------

  async function doSave(
    id: string,
    name: string,
    status: string,
    journey: string,
    monthly_planned_seconds: number,
    mailerlite_apikey: string,
  ) {
    try {
      const res = await UpdateAccount({
        variables: { id, name, status, journey, monthly_planned_seconds, mailerlite_apikey },
      });

      notification.open({
        type: 'success',
        message: t('update_account_message'),
        description: res.data?.updateAccount?.name,
      });
      refetch({ id: account });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then((values) => {
        doSave(
          account ?? '',
          values.name,
          values.status,
          values.journey,
          values.monthly_planned_seconds * 3600,
          values.mailerlite_apikey,
        );

        form.resetFields();
      })
      .catch((info) => {
        notification.open({
          type: 'error',
          ...errorMessage('form_validation', info),
        });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    notification.open({
      type: 'error',
      ...errorMessage('', errorInfo),
    });
  };

  async function doSyncLeads() {
    try {
      await SyncLeads({ variables: { account } });

      notification.open({
        type: 'success',
        message: 'Sincronização iniciada',
        description: 'Dependendo da quantidade de leads, este processo pode levar vários minutos.',
      });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  async function doRefreshApiKey() {
    try {
      await RefreshApiKey({ variables: { account } });

      notification.open({
        type: 'success',
        message: 'Nova chave criada',
        description:
          'A chave da API foi atualizada com sucesso. A chave anterior foi invalidada e não irá mais funcionar.',
      });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  async function doAddMailerliteField() {
    try {
      await AddMailerliteField({ variables: { account, key: mailerliteField.key, name: mailerliteField.name } });

      setMailerliteField({ key: '', name: '' });

      notification.open({
        type: 'success',
        message: 'Campo adicionado',
        description: `O campo "${mailerliteField.key}" foi adicionado com sucesso`,
      });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  async function doDeleteMailerliteField(key: string) {
    try {
      await DeleteMailerliteField({ variables: { account, key: key } });

      notification.open({
        type: 'success',
        message: 'Campo removido',
        description: `O campo "${key}" foi removido com sucesso`,
      });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  async function Delete(id: string, type: 'user' | 'cell') {
    try {
      if (type === 'user') await DetachUserFromAccount({ variables: { type: 'Account', user: id, account } });
      if (type === 'cell') await DetachAccountFromCell({ variables: { cell: id, account } });
      notification.open({
        type: 'success',
        message: t('delete_account_message'),
      });
      refetch({ id: account });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  async function AttachTo(id: string, type: 'user' | 'cell') {
    try {
      if (type === 'user') await AttachUserToAccount({ variables: { type: 'Account', user: id, account } });
      if (type === 'cell') await AttachAccountToCell({ variables: { cell: id, account } });
      refetch({ id: account });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  //----------------------------------------------------------------------
  if (loading) return <ErrorAndLoading loading={loading} />;
  if (error) return <ErrorAndLoading error={error} />;

  const cells = data?.account?.cells?.data;
  const customers = data?.account?.customers?.data;
  const mailerliteFields = data?.account?.mailerlite_fields;

  return (
    <>
      <Card style={{ marginBottom: '16px' }}>
        {/*<Row style={{ width: '100%' }} gutter={[0, 24]}>
          <Col lg={24} xl={16}>*/}
        <div className={styles.div_avatar}>
          <div id="avatar_account" className={styles.avatar} style={{ padding: 0, margin: 0 }}>
            <UploadAvatar
              id={account ?? ''}
              query={accounts_info}
              params={{ variables: { id: account } }}
              extractData={(logoUrl) => data?.account?.logoUrl}
              radius="10%"
              target="Account"
            />
          </div>

          <div className={styles.form}>
            <Typography.Title style={{ fontSize: '20px' }}>{t('account_info')}</Typography.Title>

            <Form
              form={form}
              labelCol={{
                span: 2,
                style: {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  minWidth: 120,
                },
              }}
              wrapperCol={{ style: { display: 'flex', flex: 1 } }}
              layout="horizontal"
              name="account_edit"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{
                name: accountInfo?.name || '',
                status: accountInfo?.status || '',
                monthly_planned_seconds: accountInfo?.monthly_planned_seconds || '',
                mailerlite_apikey: accountInfo?.mailerlite_apikey || '',
                api_key: accountInfo?.api_key || '',
              }}>
              <div id="name_account">
                <Form.Item name="name" label={t('name')}>
                  <Input type="text" />
                </Form.Item>
              </div>

              <div id="status_account">
                <Form.Item name="status" label={t('status')}>
                  <Select
                    value={form.getFieldValue('status')}
                    options={[
                      { value: 'active', label: t('active') },
                      { value: 'inactive', label: t('inactive') },
                      { value: 'suspended', label: t('suspended') },
                    ]}
                  />
                </Form.Item>
              </div>

              <div id="journey_account">
                <Form.Item name="journey" label="Jornada">
                  <Select
                    value={form.getFieldValue('journey')}
                    options={[
                      { value: 'implantation', label: 'Implantação' },
                      { value: 'channel_proof', label: 'Prova de canais' },
                      { value: 'validation', label: 'Validação' },
                      { value: 'otimization', label: 'Otimização' },
                      { value: 'scale', label: 'Escalada' },
                      { value: 'new_journey', label: 'Nova jornada' },
                    ]}
                  />
                </Form.Item>
              </div>

              <div id="planned_hours_account">
                <Form.Item name="monthly_planned_seconds" label={t('planned_hours')}>
                  <Input type="number" />
                </Form.Item>
              </div>

              <div id="mailerlite_key">
                <Form.Item label={t('mailerlite')}>
                  <div style={{ display: 'flex' }}>
                    <Form.Item name="mailerlite_apikey" style={{ width: '100%', marginBottom: 0 }}>
                      <Input />
                    </Form.Item>

                    <Button type="default" onClick={() => doSyncLeads()} style={{ marginLeft: 8, width: 200 }}>
                      Sincronizar leads
                    </Button>
                  </div>
                </Form.Item>
              </div>

              <Form.Item label="Chave da API">
                <div style={{ display: 'flex' }}>
                  <Form.Item name="api_key" noStyle>
                    <Input readOnly />
                  </Form.Item>

                  <Popover
                    destroyTooltipOnHide={{ keepParent: false }}
                    content={
                      <>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowRefreshApiModal(false);
                          }}>
                          Cancelar
                        </Button>
                        <Button
                          style={{ marginLeft: 6 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowRefreshApiModal(false);
                            doRefreshApiKey();
                          }}>
                          Sim
                        </Button>
                      </>
                    }
                    title={
                      <>
                        Você tem certeza que deseja criar uma nova chave de API?
                        <br />
                        Todas as conexões criadas serão invalidadas
                      </>
                    }
                    trigger="click"
                    placement="left"
                    visible={showRefreshApiModal}
                    onVisibleChange={() => setShowRefreshApiModal(true)}>
                    <Button type="default" style={{ marginLeft: 8, width: 200 }} onClick={(e) => e.stopPropagation()}>
                      Gerar nova chave
                    </Button>
                  </Popover>
                </div>
              </Form.Item>

              <Form.Item noStyle>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="primary" htmlType="reset" onClick={() => navigate(-1)}>
                    {t('go_back_button')}
                  </Button>

                  <Button id="save_button" type="primary" htmlType="submit" loading={loading} style={{ marginLeft: 6 }}>
                    {t('save_button')}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        {/*
          </Col>

          <Col
            lg={24}
            xl={8}
            style={{
              display: 'flex',
              flexDirection: 'column',
              //alignItems: 'center',
              padding: '0 24px',
            }}>
            <Typography.Title style={{ fontSize: '18px' }}>{t('connected_accounts')}</Typography.Title>

            <LoginButtons account={account} />
          </Col>
          </Row>*/}
      </Card>

      <Card style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex' }}>
          <Typography.Title level={4}>Campos Mailerlite</Typography.Title>
          <Help
            title="Campos personalizados"
            description="Cadastre os campos personalizados do Mailerlite para serem exibidos na ID Action"
            iconStyle={{ color: '#5c5c5c', fontSize: 12, position: 'relative', left: 4, top: 9 }}
          />
        </div>

        <div>
          {mailerliteFields && mailerliteFields.length === 0 && (
            <div
              style={{
                textAlign: 'center',
                backgroundColor: '#f6f6f6',
                borderRadius: 4,
                padding: 8,
              }}>
              Não há campos personalizados cadastrados
            </div>
          )}

          {mailerliteFields &&
            mailerliteFields.map((field: any) => (
              <div key={field.key} style={{ display: 'flex', marginBottom: 4, alignItems: 'center' }}>
                <div
                  style={{
                    flex: 1,
                    border: '1px solid #ddd',
                    padding: '4px 10px',
                    borderRadius: 2,
                    backgroundColor: '#f6f6fa',
                  }}>
                  {field.key}
                </div>
                <div style={{ width: 8 }} />
                <div
                  style={{
                    flex: 1,
                    border: '1px solid #ddd',
                    padding: '4px 10px',
                    borderRadius: 2,
                    backgroundColor: '#f6f6fa',
                  }}>
                  {field.name}
                </div>
                <div style={{ width: 8 }} />
                <Button onClick={() => doDeleteMailerliteField(field.key)}>Remover</Button>
              </div>
            ))}

          <Typography.Title level={5} style={{ marginTop: 36 }}>
            Adicionar novo campo personalizado
          </Typography.Title>

          <div style={{ display: 'flex' }}>
            <Input
              placeholder="Key"
              value={mailerliteField.key}
              onChange={({ target }) => setMailerliteField((f) => ({ ...f, key: target.value }))}
            />
            <div style={{ width: 12 }} />
            <Input
              placeholder="Nome"
              value={mailerliteField.name}
              onChange={({ target }) => setMailerliteField((f) => ({ ...f, name: target.value }))}
            />
            <div style={{ width: 12 }} />
            <Button onClick={() => doAddMailerliteField()}>Adicionar</Button>
          </div>
        </div>
      </Card>

      <ListWithAddTo
        title={t('cell_title')}
        button={t('cell_button')}
        buttonId="add_cell"
        helpTitle={t('help_cell_title')}
        helpDescription={t('help_cell_description')}
        idList={cellIdList}
        query={cells_select}
        extractData={(data) => {
          setAddCell(data.me.cells.data.length > 0);
          return data.me.cells.data;
        }}
        AttachAtoB={(a, b) => {
          AttachTo(a || '', 'cell');
        }}
        items={cells}
        renderItem={(item, idx) => (
          <List.Item
            id="open_cell"
            key={item.id}
            onClick={() => navigate(route('admin.cells.edit', { cell: item.id }))}
            className={styles.item}
            actions={[
              <Popover
                destroyTooltipOnHide={{ keepParent: false }}
                content={
                  <>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmCell(undefined);
                      }}>
                      {t('remove_button_no')}
                    </Button>
                    <Button
                      style={{ marginLeft: 6 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmCell(undefined);
                        Delete(item.id, 'cell');
                      }}>
                      {t('remove_button_yes')}
                    </Button>
                  </>
                }
                title={t('remove_cell_question')}
                trigger="click"
                visible={confirmCell === idx}
                onVisibleChange={() => setConfirmCell(idx)}>
                <Button id="delete_cell" onClick={(e) => e.stopPropagation()}>
                  {t('remove_button')}
                </Button>
              </Popover>,
              <Button id="open_cell_button" onClick={() => navigate(route('admin.cells.edit', { cell: item.id }))}>
                {t('view_button')}
              </Button>,
            ]}>
            <List.Item.Meta
              avatar={<AvatarPicture pictureLink={item.logoUrl} target="Cell" size={40} name={item.name} />}
              title={item.name}
              description={`${item.members.total} usuários`}
            />
          </List.Item>
        )}
      />

      <ListWithAddTo
        title={t('user_title')}
        button={t('user_button')}
        buttonId="add_user"
        helpTitle={t('help_user_title')}
        helpDescription={t('help_user_description')}
        idList={userIdList}
        query={account_customers_select}
        params={{ variables: { id: account } }}
        extractData={(data) => {
          setAddUser(data?.account?.realm?.customers?.data.length > 0);

          const filtered = (data?.account?.realm?.customers?.data || []).filter(
            (user: any) => user?.status !== 'inactive',
          );

          return filtered;
        }}
        AttachAtoB={(a, b) => {
          AttachTo(a || '', 'user');
        }}
        items={customers}
        renderItem={(item, idx) => (
          <List.Item
            id="open_user"
            key={item.id}
            onClick={() => navigate(route('admin.users.edit', { user: item.id }))}
            className={styles.item}
            actions={[
              <Popover
                destroyTooltipOnHide={{ keepParent: false }}
                content={
                  <>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmUser(undefined);
                      }}>
                      {t('remove_button_no')}
                    </Button>
                    <Button
                      style={{ marginLeft: 6 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmUser(undefined);
                        Delete(item.id, 'user');
                      }}>
                      {t('remove_button_yes')}
                    </Button>
                  </>
                }
                title={t('remove_user_question')}
                trigger="click"
                visible={confirmUser === idx}
                onVisibleChange={() => setConfirmUser(idx)}>
                <Button id="delete_account_cell" onClick={(e) => e.stopPropagation()}>
                  {t('remove_button')}
                </Button>
              </Popover>,
              <Button id="open_user_button" onClick={() => navigate(route('admin.users.edit', { user: item.id }))}>
                {t('view_button')}
              </Button>,
            ]}>
            <List.Item.Meta
              avatar={<AvatarPicture pictureLink={item.avatarUrl} target="User" size={40} name={item.name} />}
              title={
                <>
                  {item.name}

                  {item.roles.map((role: string) => (
                    <Tag
                      key={role}
                      color="blue"
                      style={{
                        position: 'relative',
                        top: -2,
                        margin: '0 0 0 8px',
                        borderRadius: 4,
                        fontSize: 7,
                        fontWeight: 700,
                        padding: '4px 6px',
                        lineHeight: '7px',
                        cursor: 'pointer',
                      }}>
                      {role === 'admin' && t('role_admin')}
                      {role === 'customer_success' && t('role_customer_success')}
                      {role === 'media_tester' && t('role_media_tester')}
                      {role === 'creator' && t('role_creator')}
                      {role === 'customer' && t('role_customer')}
                    </Tag>
                  ))}
                </>
              }
              description={item.email}
            />
          </List.Item>
        )}
      />

      <TutorialButton
        tourId="025_EditAccountManagement"
        top={80}
        right={20}
        placement="left"
        title={t('tutorial_tooltip')}
      />

      <TutorialEditAccount
        hasCell={cells.length > 0}
        hasUser={customers.length > 0}
        addCellButton={addCell}
        addUserButton={addUser}
      />
    </>
  );
};

export default EditAccount;
