import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  hasCell: boolean;
}

const TutorialCellList: React.FC<Props> = ({ hasCell }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '017_CellListManagement' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="add_new_cell"]',
      content: 'Clique aqui para adicionar uma nova célula na sua organização.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      hasCell
        ? {
            selector: '[id="open_cell_button"]',
            content: 'Para ver e editar a célula, clique em "Visualizar".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasCell
        ? {
            selector: '[id="open_cell"]',
            content: 'Ou clique sobre a célula.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasCell
        ? {
            selector: '[id="delete_cell"]',
            content: 'Delete a célula, clicando em "Excluir".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    'add_new_cell',
    ...[hasCell ? 'open_cell' : undefined],
    ...[hasCell ? 'delete_cell' : undefined],
  ].filter((step) => !!step);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialCellList);
