import React from 'react';

import Task from '@pages/Activities/Task';
import InfoDrawer from '@pages/Info/InfoDrawer';
import TemplateItem from '@pages/Templates/TemplateItem';
import UrlRedirect from '@pages/Links/UrlRedirect';

import useHashInfo from '@logic/functions/currentTask';
import LeadDrawer from '@pages/Leads/LeadDrawer';

// Observa as mudanças na hash para abrir/fechar o drawer

const DrawersManager: React.FC = () => {
  const { 0: drawer, 1: id, 2: tab } = useHashInfo();

  switch (drawer) {
    case '#task':
      return <Task taskId={id} tab={tab} />;
    case '#acc_info':
      return <InfoDrawer infoId={id} />;
    case '#template':
      return <TemplateItem templateId={id} />;
    case '#url_link':
      return <UrlRedirect linkId={id} />;
    case '#url_lead':
      return <LeadDrawer leadId={id} />;
    default:
      return null;
  }
};

export default DrawersManager;
