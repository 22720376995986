import React from 'react';
import Tour from 'reactour';
import { observer } from 'mobx-react-lite';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  hasAccount: boolean;
}

const TutorialAccountList: React.FC<Props> = ({ hasAccount }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '023_AccountListManagement' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="add_new_account"]',
      content: 'Clique aqui para adicionar uma novo cliente na sua organização.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      hasAccount
        ? {
            selector: '[id="open_account_button"]',
            content: 'Para ver e editar o cliente, clique em "Visualizar".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasAccount
        ? {
            selector: '[id="open_account"]',
            content: 'Ou clique sobre o cliente.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasAccount
        ? {
            selector: '[id="delete_account"]',
            content: 'Delete um cliente, clicando em "Excluir".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    'add_new_account',
    ...[hasAccount ? 'open_account_button' : undefined],
    ...[hasAccount ? 'open_account' : undefined],
    ...[hasAccount ? 'delete_account' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialAccountList);
