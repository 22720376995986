interface Props {
  fromPrefix?: string;
  toPrefix?: string;
  from?: any;
  to?: any;
}

const FromTo: React.FC<Props> = ({ fromPrefix, toPrefix, from, to }) => {
  if (!from && !to) return null;

  return (
    <span>
      {from && (
        <>
          {fromPrefix} <b>{from}</b>&nbsp;
        </>
      )}

      {to && (
        <>
          {toPrefix} <b>{to}</b>&nbsp;
        </>
      )}
    </span>
  );
};

FromTo.defaultProps = {
  fromPrefix: 'de',
  toPrefix: 'para',
};

export default FromTo;
