import React from 'react';
import { Input } from 'antd';
import { route } from '../Router';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Search } = Input;
const SearchBar: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation('searchBar');

  return (
    <Search
      id="search"
      placeholder={t('placeholder')}
      onSearch={(value) => navigate(route('search.subject', { search: value }))}
      style={{ width: 240 }}
    />
  );
};

export default SearchBar;
