import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { DeleteOutlined, SwapOutlined } from '@ant-design/icons';
import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

const TutorialLinkList: React.FC = () => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '014_LinkList' ? true : false;
  const hasLink = store.hasLink;

  const steps: any[] = [
    {
      selector: '[id="linkListItem"]',
      content: 'Crie um novo link de redirecionamento',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      hasLink
        ? {
            selector: '[id="open_link"]',
            content: 'Clicando aqui você pode ver detalhes do link de redirecionamento',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasLink
        ? {
            selector: '[id="options_link"]',
            content: () => {
              return (
                <div>
                  <Typography className={styles.text}>Existem ainda algumas opções para links:</Typography>

                  <Typography className={styles.item}>
                    {<SwapOutlined className={styles.icon} />} Converter link
                  </Typography>
                  <Typography className={styles.item}>
                    {<DeleteOutlined className={styles.orange} />}Deletar link
                  </Typography>
                </div>
              );
            },
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    'create_link',
    ...[hasLink ? 'open_link' : undefined],
    ...[hasLink ? 'options_link' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      accentColor="#ff5333"
      maskSpace={4}
      rounded={4}
      maskClassName={styles.mask}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialLinkList);
