import React, { useMemo } from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { Carousel, Modal, Button, Popconfirm } from 'antd';

import { DeleteFilled } from '@ant-design/icons';

import styles from './styles.module.less';

interface Props {
  archivesParam: any[];
  reloadParam: () => void;
  openMd: { idx: number; id: string } | undefined;
  setOpenMd: (idx: number, id: string) => void;
  closeModal: () => void;
  deleteFile: (fileId: string, fileidx?: number) => void;
}

// Carrossel de arquivos no modal, apenas arquivos compatíveis (imagens e vídeos)

const ModalCarrousel: React.FC<Props> = ({ reloadParam, archivesParam, openMd, deleteFile, setOpenMd, closeModal }) => {
  const { t } = useTranslation('upload');
  const archives = useMemo(() => {
    if (archivesParam) {
      let compatible: any[] = [];

      for (const att of archivesParam) {
        const mimetype = att.info?.mimetype || att.opt_info?.mimetype;

        if (mimetype && (mimetype.startsWith('video') || mimetype.startsWith('image'))) {
          compatible.push({ ...att, mimetype });
          continue;
        }
      }

      return compatible;
    }
  }, [archivesParam]);

  if (!openMd) return null;

  return (
    <Modal
      bodyStyle={modalBodyStyle}
      width={'60vw'}
      visible
      closable={false}
      onCancel={() => closeModal()}
      footer={
        <div style={modalFooterDivStyle}>
          <Popconfirm
            title={t('delete_question')}
            okText={t('ok_text')}
            cancelText={t('cancel_text')}
            onConfirm={() => deleteFile(openMd.id, openMd.idx)}>
            <Button icon={<DeleteFilled />} type="primary" />
          </Popconfirm>

          <div>
            {archives && (
              <a href={archives[openMd?.idx]?.info?.url || ''} target="_blank" rel="noopener noreferrer">
                <Button style={{ marginLeft: '6px' }} type="primary">
                  {t('modal_button_download')}
                </Button>
              </a>
            )}

            <Button
              style={{ marginLeft: '6px' }}
              onClick={() => {
                closeModal();
              }}>
              {t('modal_button_close')}
            </Button>
          </div>
        </div>
      }>
      {!!archives && archives.length > 0 && (
        <Carousel
          {...modalCarrouselSettings}
          className={styles.carousel}
          initialSlide={openMd?.idx}
          dots={{ className: styles.dots }}
          afterChange={(currentSlide) => {
            setOpenMd(currentSlide, archives[currentSlide].id);
          }}>
          {archives.map(({ info, opt_info, id }: any, idx: number) => {
            const file = opt_info || info;
            const mimetype = file?.mimetype || '';
            const url = file?.url;

            if (mimetype.startsWith('video'))
              return (
                <div key={`${id}modal`}>
                  <ReactPlayer
                    controls
                    url={url}
                    width={'60vw'}
                    height={'70vh'}
                    config={{
                      file: {
                        attributes: {
                          disablePictureInPicture: true,
                          controlsList: 'nodownload',
                        },
                      },
                    }}
                  />
                </div>
              );

            if (mimetype.startsWith('image'))
              return (
                <div
                  key={`${id}modal`}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '70vh',
                    width: '60vw',
                    overflow: 'hidden',
                  }}>
                  <div
                    style={{
                      backgroundImage: `url(${url})`,
                      width: '100%',
                      height: '70vh',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                </div>
              );

            return null;
          })}
        </Carousel>
      )}
    </Modal>
  );
};

export default ModalCarrousel;

const modalCarrouselSettings = {
  infinite: false,
  centerMode: true,
  fade: true,
  centerPadding: '8px',
  slidesToShow: 1,
  slidesToScroll: 1,
};

const modalBodyStyle: React.CSSProperties = {
  padding: 0,
  maxHeight: '70vh',
  maxWidth: '60vw',
  alignItems: 'center',
  justifyContent: 'center',
};

const modalFooterDivStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};
