import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  hasNotes?: boolean;
}

const TutorialNoteTab: React.FC<Props> = ({ hasNotes }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '007_NotesTab' ? true : false;

  const isAdmin = !!store.profile && store.profile.roles.includes('admin') ? true : false;

  const steps: any[] = [
    {
      selector: '[id="create_note"]',
      content: 'Notas são algumas anotações referente a atividade. Você pode criar notas e anexar arquivos também.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      hasNotes
        ? {
            selector: '[id="edit_note"]',
            content: isAdmin
              ? 'As notas criadas, podem ser editadas ou excluídas conforme a necessidade.'
              : 'As notas que você criou, podem ser editadas ou excluídas. Já as notas que não foram criar por você apenas ficam visíveis, mas não podem ser editadas ou excluídas.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = ['create_note', ...[hasNotes ? 'edit_note' : undefined]].filter((id) => !!id);

  return (
    <>
      <Tour
        startAt={0}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => store.setTour(undefined)}
        accentColor="#ff5333"
        maskSpace={4}
        maskClassName={styles.mask}
        rounded={4}
        getCurrentStep={(curr) => changeStep(curr, ids)}
        onAfterOpen={() => disableScroll('ant-tabs-content-holder', 'class_name')}
        onBeforeClose={() => enableScroll('ant-tabs-content-holder', 'class_name')}
      />
    </>
  );
};

export default observer(TutorialNoteTab);
