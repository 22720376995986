import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { DeleteOutlined } from '@ant-design/icons';
import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

const TutorialInfo: React.FC = () => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '012_Info' ? true : false;
  const hasInfo = store.hasInfo;

  const steps: any[] = [
    {
      selector: '[id="infoListItem"]',
      content: 'Crie uma nova informação referente ao cliente.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      hasInfo
        ? {
            selector: '[id="open_info"]',
            content: 'Clicando aqui você pode visualizar e editar a informação.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasInfo
        ? {
            selector: '[id="options_info"]',
            content: () => {
              return (
                <div>
                  <Typography className={styles.text}>Existe ainda a opção de excluir uma informação:</Typography>

                  <Typography className={styles.item}>
                    {<DeleteOutlined className={styles.orange} />}Deletar link
                  </Typography>
                </div>
              );
            },
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = ['create_info', ...[hasInfo ? 'open_info' : undefined]].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      accentColor="#ff5333"
      maskSpace={4}
      rounded={4}
      maskClassName={styles.mask}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialInfo);
