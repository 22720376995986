import { Button, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { DownloadOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useUploadManager } from '@logic/context';
import errorMessage from '@logic/functions/errorHandeling';
import { useSocketEvent } from '@logic/functions/socket';
import { delete_file, move_after, move_before } from '@logic/mutations';

import ErrorAndLoading from '../../ErrorAndLoading';
import FilesListItem from './FileListItem';
import ModalCarrousel from './ModalCarrousel';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

interface Props {
  targetId?: string;
  targetType: string;
  parentId: string;
  viewOnly?: boolean;
  previewSize?: number;
}

const fetch_files = gql`
  query FetchFiles($targetType: FileTarget!, $targetId: ID!) {
    files(type: $targetType, target: $targetId, pagination: { limit: -1, page: 1 }) {
      data {
        id
        status
        name
        thumbnailUrl
        info {
          uuid
          mimetype
          size
          url
        }
        opt_info {
          uuid
          mimetype
          size
          url
        }
      }
    }
  }
`;

const FilesList: React.FC<Props> = ({ targetId, targetType, parentId, viewOnly, previewSize }) => {
  const uploadManager = useUploadManager();

  const { t } = useTranslation('upload');

  const [modal, setModal] = useState<{ idx: number; id: string } | undefined>(undefined);
  const [archives, setArchives] = useState<any[] | undefined>(undefined);
  const [downloadingMultiple, setDownloadingMultiple] = useState(false);

  //----------------------- Query & Mutation ----------------------

  const [fetchFiles, { data, error, refetch }] = useLazyQuery(fetch_files, {
    variables: { targetId, targetType },
    fetchPolicy: 'cache-first',
  });

  const handleSocket = useCallback(
    (data: any) => {
      //console.log('handleSocket >', targetId, data);
      if (refetch) refetch();
    },
    [refetch],
  );

  useSocketEvent(targetId, 'files_changed', handleSocket);

  const [Delete] = useMutation(delete_file);

  const [MoveAfter] = useMutation(move_after);
  const [MoveBefore] = useMutation(move_before);

  //----------------------- Handeling data ----------------------

  const temporaryFiles = uploadManager.temporaryFiles;

  useEffect(() => {
    let files: any[] | undefined = undefined;

    if (targetId && data) {
      files = data.files.data;
    } else if (!targetId) {
      files = temporaryFiles
        .filter((item) => item.parentId === parentId)
        .map((item) => ({
          id: item.fileKey,
          name: item.file.name,
          status: 'done',
          thumbnailUrl: item.fileUrl,
          info: {
            mimetype: item.file.type,
            size: item.file.size,
            url: item.fileUrl,
          },
        }));
    }

    setArchives(files);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, parentId, targetId, temporaryFiles.map((file) => file.fileKey).join()]);

  useLayoutEffect(() => {
    if (!targetId) return;

    fetchFiles({ variables: { targetId, targetType } });
  }, [fetchFiles, targetId, targetType]);

  //----------------------- Delete file ----------------------

  async function deleteFile(fileId: string, fileidx?: number) {
    if (!archives) return;

    if (!targetId) {
      uploadManager.removeTemporaryFile(fileId);
      return;
    }

    await Delete({ variables: { id: fileId } })
      .then(() => {
        if (modal) {
          const compatible: any[] = archives.filter((att: any) => {
            const mimetype = att.info?.mimetype || att.opt_info?.mimetype;

            if (mimetype && (mimetype.startsWith('video') || mimetype.startsWith('image'))) {
              return true;
            } else return false;
          });

          if (compatible.length === 1) {
            setModal(undefined);
            return;
          }

          let idx = modal.idx;

          if (idx > compatible.length + 1) {
            idx = idx - 1;
          }

          setModal({ id: compatible[idx].id, idx });
        }

        notification.open({
          placement: 'bottomRight',
          duration: 2,
          type: 'success',
          message: 'Arquivo removido',
        });

        if (fileidx) removeFileFromArray(fileidx);
        if (refetch) refetch();
      })
      .catch((err) =>
        notification.open({
          type: 'error',
          ...errorMessage('graph_err', err),
        }),
      );
  }
  function removeFileFromArray(fileIdx: number) {
    if (!archives) return;

    const newArchives = [...archives];
    newArchives.splice(fileIdx, 1);

    setArchives(newArchives);
  }

  // -------------------------- Order Files ----------------------------------

  // Remove o item do index antigo e insere ele no novo index
  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !archives) return;

    const from = result.source.index;
    const to = result.destination.index;

    if (from === to) return;

    const reference = from < to ? 'after' : 'before';
    const fileOrdId = archives[from].id;
    const fileRefId = archives[to].id;

    if (!!targetId) Order(reference, fileRefId, fileOrdId);

    const newArchives = [...archives];
    const originItem = archives.find((t: any) => t.id === fileOrdId);
    newArchives.splice(from, 1);
    newArchives.splice(to, 0, originItem);

    setArchives(newArchives);
  };

  // Reordena no graph a lista de arquivos
  async function Order(reference: 'before' | 'after', fileRefId: string, fileOrdId: string) {
    try {
      if (reference === 'after') await MoveAfter({ variables: { type: 'File', id: fileOrdId, target: fileRefId } });
      if (reference === 'before') await MoveBefore({ variables: { type: 'File', id: fileOrdId, target: fileRefId } });
    } catch (err) {
      console.error(err);
    } finally {
      if (refetch) refetch();
    }
  }

  const handleDownloadAllFiles = async () => {
    if (!archives || archives.length === 0) return;

    setDownloadingMultiple(true);
    const zip = new JSZip();

    for (let index = 0; index < archives.length; index++) {
      const file = await download(archives[index].info.url);
      if (!file) continue;

      zip.file(`${index}_${archives[index].name}`, file);
    }

    try {
      const blob = await zip.generateAsync({ type: 'blob' });
      saveAs(blob, 'arquivos.zip');
    } catch {
      alert('Ocorreu um erro inesperado ao criar o arquivo zip');
    }

    setDownloadingMultiple(false);
  };

  // --------------------------------------------------------------

  return (
    <>
      {archives && !!modal && (
        <ModalCarrousel
          archivesParam={archives}
          openMd={modal}
          reloadParam={() => {
            if (refetch) refetch();
          }}
          closeModal={() => setModal(undefined)}
          setOpenMd={(idx, id) => setModal({ idx, id })}
          deleteFile={deleteFile}
        />
      )}

      {error && <ErrorAndLoading error={error} />}

      {!viewOnly && (!archives || archives.length === 0) && <div style={{ height: 16 }} />}
      {/* Grade de arquivos */}
      {archives && archives.length > 0 && (
        <div style={{ display: 'flex' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId={`files_${targetId || parentId}`}
              direction="horizontal"
              renderClone={(provided, snapshot, rubric) => (
                <div
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, previewSize)}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}>
                  <FilesListItem
                    id={archives[rubric.source.index].id}
                    idx={archives[rubric.source.index].idx}
                    name={archives[rubric.source.index].name}
                    info={archives[rubric.source.index].info}
                    opt_info={archives[rubric.source.index].opt_info}
                    status={archives[rubric.source.index].status}
                    thumbnailUrl={archives[rubric.source.index].thumbnailUrl}
                    setOpenMd={(idx, id) => setModal({ idx, id })}
                    deleteFile={(id) => deleteFile(id)}
                    previewSize={previewSize}
                  />
                </div>
              )}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                  {archives.map(({ info, opt_info, status, id, name, thumbnailUrl }: any, idx: number) => {
                    return (
                      <Draggable draggableId={id} key={`${id}_${idx}`} index={idx}>
                        {(provided, snapshot) => {
                          const modalArchives = getCompatibleFiles(archives);
                          const modalIdx = modalArchives.findIndex((value) => value.id === id);

                          return (
                            <div
                              id="draggable"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, previewSize)}>
                              <FilesListItem
                                id={id}
                                idx={idx}
                                name={name}
                                info={info}
                                opt_info={opt_info}
                                status={status}
                                thumbnailUrl={thumbnailUrl}
                                setOpenMd={(idx, id) => setModal({ idx: modalIdx, id })}
                                deleteFile={(id) => deleteFile(id)}
                                previewSize={previewSize}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
      {/* download automático consecutivo de todos os arquivos */}
      {!viewOnly && archives && archives.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12 }}>
          <Button
            type="default"
            size="small"
            disabled={downloadingMultiple}
            loading={downloadingMultiple}
            title={t('download_all')}
            icon={<DownloadOutlined />}
            onClick={handleDownloadAllFiles}>
            {t('download_all')}
          </Button>
        </div>
      )}
    </>
  );
};

export default observer(FilesList);

const getItemStyle = (isDragging: any, draggableStyle: any, previewSize?: number): React.CSSProperties => {
  const size = previewSize || 120;
  return {
    userSelect: 'none',
    width: size + size * 0.4,
    ...draggableStyle,
  };
};
const getListStyle = (isDraggingOver: any): React.CSSProperties => {
  return {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 4,
    width: '100%',
  };
};

function getCompatibleFiles(archives: any[]) {
  let compatible: any[] = [];

  for (const att of archives) {
    const mimetype = att.info?.mimetype || att.opt_info?.mimetype;

    if (mimetype && (mimetype.startsWith('video') || mimetype.startsWith('image'))) {
      compatible.push({ ...att, mimetype });
      continue;
    }
  }

  return compatible;
}

async function download(url: string) {
  try {
    const data = await fetch(url);
    const blob = await data.blob();
    return blob;
  } catch {
    return null;
  }
}
