import React from 'react';
import { useParams } from 'react-router-dom';

import { Card, Typography } from 'antd';

import styles from './styles.module.less';

const Search: React.FC = () => {
  const { search } = useParams<any>();

  return (
    <Card className={styles.root}>
      <Typography.Title className={styles.title}>Página de pesquisa</Typography.Title>
      <Typography.Text className={styles.text}>Exibindo resultados para: {search}</Typography.Text>
    </Card>
  );
};

export default Search;
