import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Input, Typography, notification, Row, Col, Button, Modal, Switch } from 'antd';
import { useMutation, useQuery } from '@apollo/client';

import { SearchOutlined } from '@ant-design/icons';

import Help from '@comp/Help';

import { route } from '@router';
import { account_mailerlite_groups } from '@logic/queries';
import { create_link } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

const NewWhatsappLink: React.FC = () => {
  const { id: paramId } = useParams<any>();
  const navigate = useNavigate();

  const defaultValues: any = {
    account: paramId,
    subdomain: '',
    path: '',
    dest: '',
    title: '',
    body: '',
    ml_sync: false,
    ml_group_id: '',
  };

  const [link, setLink] = useState<any>(defaultValues);

  const [groupsModalVisible, setGroupsModalVisible] = useState(false);

  //----- Mutation ------------------------------------------------

  const [CreateLink] = useMutation(create_link);

  const {
    data: mlGroups,
    loading: mlGroupsLoading,
    error: mlGroupsError,
  } = useQuery(account_mailerlite_groups, {
    fetchPolicy: 'network-only',
    variables: { account: paramId },
  });

  //--------------Save function ------------------------------------------

  async function doSave(
    subdomain: string,
    path: string,
    dest: string,
    ml_sync: boolean,
    ml_group_id: string,
    title?: string,
    body?: string,
  ) {
    try {
      const { data } = await CreateLink({
        variables: {
          type: 'whatsapp',
          account: paramId,
          subdomain,
          path,
          dest,
          title,
          body,
          ml_sync,
          ml_group_id,
        },
      });

      navigate(route('id.home.links', { id: paramId }) + '#url_link/' + data.createLink?.id);
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }
  //------------- Form functions ------------------------------------------------

  const handleChange = ({ target: { name, value } }: any) => {
    setLink((t: any) => ({ ...t, [name]: value }));
  };

  const handleMlSyncChanged = (checked: boolean) => {
    setLink((t: any) => ({ ...t, ml_sync: checked }));
  };

  //------------------------------------------------------------------------------

  return (
    <div className={styles.root} style={{ height: '100%' }}>
      <Row id="link_subdomain" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Subdomínio: </Typography.Text>
          <Help
            title="Subdomínio"
            description="Subdomínio do link. Este é um campo obrigatório."
            text={
              <Typography.Text style={{ color: '#FFF' }}>
                https://<b className={styles.bold}>subdominio</b>.idaction.link/caminho
              </Typography.Text>
            }
            overlayStyle={{ minWidth: 320 }}
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="subdomain"
            placeholder="Subdomínio do link"
            value={link.subdomain}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="link_path" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Caminho: </Typography.Text>
          <Help
            title="Caminho"
            description="É um identificador que precisa ser único para o mesmo subdomínio. Este é um campo obrigatório."
            text={
              <Typography.Text style={{ color: '#FFF' }}>
                https://empresa.idaction.link/<b className={styles.bold}>caminho</b>
              </Typography.Text>
            }
            overlayStyle={{ minWidth: 320 }}
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="path"
            placeholder="Caminho do link"
            value={link.path}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="link_dest" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Destino: </Typography.Text>
          <Help
            title="Destino"
            description="É o site ou link que será redirecionado. Este é um campo obrigatório."
            text={
              <Typography.Text style={{ color: '#FFF' }}>
                <b className={styles.bold}>https://www.link-para-abrir.com.br</b>
              </Typography.Text>
            }
            overlayStyle={{ minWidth: 320 }}
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="dest"
            placeholder="Destino do link"
            value={link.dest}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="link_title" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Título: </Typography.Text>
          <Help
            title="Título"
            description="É o título da conversa de whatsapp."
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="title"
            placeholder="Título do link"
            value={link.title}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="link_body" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Descrição: </Typography.Text>
          <Help
            title="Descrição"
            description="É o texto da conversa de whatsapp."
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="body"
            placeholder="Descrição do link"
            value={link.body}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Usar Mailerlite</Typography.Text>
          <Help
            title="Enviar para Mailerlite"
            description="Define se os leads gerados por esse link serão ou não sincronizados no Mailerlite. Caso fique desmarcado, o lead é criado diretamente na IDLAB e um evento poderá ser disparado para ferramentas de integração, como o Make ou Integromat."
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18} style={{ display: 'flex' }}>
          <Switch checked={link.ml_sync} onChange={handleMlSyncChanged} />
        </Col>
      </Row>

      {link.ml_sync && (
        <Row id="ml_group_id" className={styles.title}>
          <Col span={6} className={styles.column}>
            <Typography.Text className={styles.label}>Grupo MailerLite: </Typography.Text>
            <Help
              title="MailerLite"
              description="ID do grupo que o lead será criado dentro do MailerLite."
              placement="left"
              iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            />
          </Col>
          <Col span={18} style={{ display: 'flex' }}>
            <Input
              name="ml_group_id"
              placeholder="ID do grupo do MailerLite"
              value={link.ml_group_id}
              onChange={handleChange}
              style={{
                border: undefined,
                width: '100%',
                borderRadius: 4,
                padding: '2px 4px',
                whiteSpace: 'break-spaces',
              }}
            />
            <Button
              style={{ marginLeft: 4 }}
              icon={<SearchOutlined />}
              onClick={() => setGroupsModalVisible(true)}
              disabled={mlGroupsLoading || !!mlGroupsError}
            />
          </Col>
        </Row>
      )}

      <Modal visible={groupsModalVisible} onCancel={() => setGroupsModalVisible(false)} footer={null} width="60vw">
        {(mlGroups?.account?.mailerlite_groups ?? []).map(({ id, name }: any) => (
          <Button
            style={{ width: '100%', textAlign: 'left', marginTop: 8, overflow: 'hidden' }}
            size="large"
            onClick={() => {
              setLink((old: any) => ({ ...old, ml_group_id: id }));
              setGroupsModalVisible(false);
            }}>
            <b>[{id}]</b>&nbsp;{name}
          </Button>
        ))}
      </Modal>

      <Row gutter={24}>
        <Col span={6}>
          <Button
            id="link_save_button"
            type="primary"
            htmlType="submit"
            block
            disabled={
              !link.subdomain ||
              !link.path ||
              !link.dest ||
              !link.title ||
              !link.body ||
              (link.ml_sync && !link.ml_group_id)
            }
            onClick={() =>
              doSave(link.subdomain, link.path, link.dest, link.ml_sync, link.ml_group_id, link.title, link.body)
            }>
            Salvar
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NewWhatsappLink;
