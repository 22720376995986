import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery, gql, useMutation } from '@apollo/client';
import { notification, Upload, Popconfirm } from 'antd';
import ImgCrop from 'antd-img-crop';
import { DocumentNode } from 'graphql';
import AvatarPicture from '@comp/AvatarPicture';
import ErrorAndLoading from '@comp/ErrorAndLoading';

import { uploadFileAndAttach } from '@logic/upload';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  id: string;
  query: DocumentNode;
  params?: any;
  extractData: (data: any) => string;
  radius: string;
  target: 'Account' | 'Cell' | 'User';
}

const applyAvatar = gql`
  mutation AttachAvatar($target: AvatarTarget!, $id: ID!, $key: String!) {
    attachAvatar(target: $target, id: $id, key: $key)
  }
`;

const removeAvatar = gql`
  mutation DetachAvatar($target: AvatarTarget!, $id: ID!) {
    detachAvatar(target: $target, id: $id)
  }
`;

const UploadAvatar: React.FC<Props> = ({ id, query, params, extractData, radius, target }) => {
  const [picture, setPicture] = useState<string>();
  const [upload, setUpload] = useState(false);

  const { t } = useTranslation('uploadAvatar');

  //-------------------Queries------------------------------------------

  const { data, loading, error, refetch } = useQuery(query, params);

  useEffect(() => {
    if (!data) return;
    setPicture(extractData(data));
  }, [data, extractData, refetch]);

  //-------------------Mutations------------------------------------------------

  const [Deletepicture] = useMutation(removeAvatar, {
    variables: { id, target },
  });

  //-------------------Upload Functions------------------------------------------

  async function customUpload({ file, onSuccess }: any) {
    delete file['uid'];

    try {
      setUpload(true);

      await uploadFileAndAttach({
        file,
        applyMutation: applyAvatar,
        applyVariables: { target, id },
      });

      notification.open({
        type: 'success',
        message: t('message_success'),
      });

      await refetch();
      setUpload(false);
    } catch (err) {
      console.error(err);
    }

    onSuccess('ok');
  }

  //-----------------Delete Functions-------------------------------

  async function Delete() {
    try {
      await Deletepicture();

      notification.open({
        placement: 'bottomRight',
        duration: 2,
        type: 'success',
        message: t('message_delete'),
      });

      await refetch();
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  //----------------------------------------------------------------
  const avatar = useMemo(
    () => <AvatarPicture upload={upload} pictureLink={picture} target={target} size={160} />,
    [picture, target, upload],
  );

  if (error) return <ErrorAndLoading error={error} />;

  if (loading) return <ErrorAndLoading loading={loading} />;

  return (
    <div className={styles.root}>
      <div className={styles.uploadPicture}>
        <ImgCrop zoomSlider rotationSlider modalTitle="Editar imagem">
          <Upload customRequest={customUpload} action={undefined} showUploadList={false} listType="picture">
            {avatar}
          </Upload>
        </ImgCrop>
      </div>

      <Popconfirm
        overlayStyle={{ width: 300 }}
        title={t('popconfirm_question')}
        onConfirm={() => Delete()}
        okText={t('ok_text')}
        cancelText={t('cancel_text')}>
        <p className={styles.removeButton}>{t('remove_button')}</p>
      </Popconfirm>
    </div>
  );
};

export default UploadAvatar;
