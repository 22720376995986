import { NexusGenFieldTypes } from 'schema';
import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventApproveTask'];
}

const TaskEventApproveTask: React.FC<Props> = ({ data }) => {
  return <TaskEvent data={data}>aprovou a atividade</TaskEvent>;
};

export default TaskEventApproveTask;
