import { NexusGenFieldTypes } from 'schema';
import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventRejectTask'];
}

const TaskEventRejectTask: React.FC<Props> = ({ data }) => {
  return <TaskEvent data={data}>rejeitou a atividade</TaskEvent>;
};

export default TaskEventRejectTask;
