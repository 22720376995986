import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Card, notification, message, Tooltip, Button } from 'antd';
import { DocumentNode, gql, useMutation, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';
import { BiBook } from 'react-icons/bi';

import ActivitiesFilters from '../ActivitiesFilters';
import TutorialActivitiesCalendar from './TutorialActivitiesCalendar';
import TutorialFilters from '../ActivitiesFilters/TutorialFilters';
import { DateCell } from './Activity';
import ErrorAndLoading from '@comp/ErrorAndLoading';
import TutorialButton from '@comp/TutorialButton';
import Calendar from '@comp/antd/Calendar';

import errorMessage from '@logic/functions/errorHandeling';
import useConfig from '@logic/config';
import { route } from '@router';

import styles from './styles.module.less';
import { my_current_task_list, my_tasks_list, realm_tasks_list, selected_account_tasks_list } from '../tasksQueries';

interface Props {
  accountId: string;
  page: 'my_activities' | 'account_activities' | 'realm_activities' | 'home_dashboard';
  setSelected: (taskId: string, param?: string) => void;
  query: DocumentNode;
  variables: any;
  getData: (data: any) => any;
  view: string;
}

// moment.updateLocale('pt-BR', {
//   weekdaysMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
//   weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
//   weekdays: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
// });

const ActivitiesCalendar: React.FC<Props> = ({ setSelected, getData, page, accountId, query, variables, view }) => {
  const [tasks, setTasks] = useState<any>();
  const [accountIdTutorial, setAccountIdTutorial] = useState('');

  const navigate = useNavigate();

  const config = useConfig();
  const hideWeekend = !!config.hide_calendar_weekend;

  const { t } = useTranslation('activitiesCalendar');

  const beginVariable = variables.begin ? variables.begin : moment().format('YYYY-MM-DD');
  const dateType: any = config.tasks_filter_date || 'finish_date';

  const dateTimeTime = {
    begin: DateTime.fromFormat(beginVariable, 'yyyy-LL-dd').startOf('month'),
    end: DateTime.fromFormat(beginVariable, 'yyyy-LL-dd').endOf('month'),
  };

  const begin = dateTimeTime.begin.startOf('week').minus({ day: 1 }).toFormat('yyyy-LL-dd');
  const end = dateTimeTime.end.endOf('week').plus({ days: 6 }).toFormat('yyyy-LL-dd');

  const { data, loading, error, refetch } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: { ...variables, begin, end, periodSelector: config.tasks_filter_date || 'finish_date' },
  });

  const [UpdateTask] = useMutation(update_task_dates, {
    refetchQueries: [selected_account_tasks_list, my_tasks_list, realm_tasks_list, my_current_task_list],
  });

  const changeFinishDate = useCallback(
    async (id: string, finish_date: string | null, execution_date?: string | null) => {
      try {
        await UpdateTask({
          variables: !!execution_date ? { id, finish_date, execution_date } : { id, finish_date },
        });
      } catch (err) {
        notification.open({
          type: 'error',
          ...errorMessage('graph_err', err),
        });
      }
    },
    [UpdateTask],
  );

  const changeExecutionDate = useCallback(
    async (id: string, execution_date: string | null, finish_date?: string | null) => {
      try {
        await UpdateTask({
          variables: !!finish_date ? { id, finish_date, execution_date } : { id, execution_date },
        });
      } catch (err) {
        notification.open({
          type: 'error',
          ...errorMessage('graph_err', err),
        });
      }
    },
    [UpdateTask],
  );

  useEffect(() => {
    if (!data) return;

    const tasks = getData(data);

    if (tasks.data.length === 0) setAccountIdTutorial('');
    if (tasks.data.length > 0) setAccountIdTutorial(tasks.data[0].account_id);

    const filteredTasks = tasks.data.filter((task: any) => {
      if (dateType === 'finish_date') {
        return !!task.finish_date;
      } else return !!task.execution_date || !!task.finish_date;
    });

    const organizedData = filteredTasks.map((task: any) => {
      return {
        content: task.title,
        id: task.id,
        account: task.account_id,
        step: task.step,
        type: task.type,
        execution_date: task.execution_date,
        finish_date: task.finish_date,
        problem: task.has_problem,
      };
    });

    // Cria um objeto com os dias como [keys] para melhorar a performance na renderização
    // e para utilizar a [key] como "droppableId" do <Droppable/>
    const tasksObject = organizedData.reduce((acc: any, curr: any) => {
      const day = DateTime.fromFormat(curr[dateType] || curr.finish_date, 'yyyy-LL-dd', { zone: 'utc' }).toFormat(
        'yyyy-LL-dd',
      );

      if (!acc[day]) {
        acc = { ...acc, [day]: [] };
      }
      acc[day] = [...acc[day], curr];

      return acc;
    }, {});

    setTasks(tasksObject);
  }, [data, refetch, getData, dateType]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (loading) message.loading(t('loading'));
    if (!loading) message.destroy();
  }, [loading, t]);

  // Total de atividades nos finais de semana
  const totalWeekendActivities = Object.keys(tasks || []).reduce((acc, date) => {
    const taskWeekday = DateTime.fromFormat(date, 'yyyy-LL-dd').weekday;
    if (taskWeekday === 6 || taskWeekday === 7) acc = acc + tasks[date].length;
    return acc;
  }, 0);

  // Arrastar atividades para alterar as datas de execução e de publicação
  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !tasks) return;
    if (result.destination.droppableId === result.source.droppableId) return;

    const taskId = result.draggableId;
    const oldTaskDate = result.source.droppableId;
    const newTaskDate = result.destination.droppableId;

    const oldTaskDateArr = tasks[oldTaskDate];
    const newTaskDateArr = tasks[newTaskDate] || [];

    const task = oldTaskDateArr.find((t: any) => t.id === taskId);
    const taskIndex = oldTaskDateArr.findIndex((t: any) => t.id === taskId);

    const taskExecutionDate = !!task.execution_date
      ? DateTime.fromFormat(task.execution_date, 'yyyy-LL-dd')
      : undefined;
    const taskFinishDate = !!task.finish_date ? DateTime.fromFormat(task.finish_date, 'yyyy-LL-dd') : undefined;

    const defaultTask = task.type === 'default';

    if (dateType === 'finish_date') {
      if (defaultTask && !!taskExecutionDate && DateTime.fromFormat(newTaskDate, 'yyyy-LL-dd') <= taskExecutionDate) {
        newTaskDateArr.push({ ...task, finish_date: newTaskDate, execution_date: newTaskDate });
        changeFinishDate(taskId, newTaskDate, newTaskDate);
      } else {
        newTaskDateArr.push({ ...task, finish_date: newTaskDate });
        changeFinishDate(taskId, newTaskDate);
      }
    } else if (dateType === 'execution_date' && !!taskExecutionDate && defaultTask) {
      if (defaultTask && !!taskFinishDate && DateTime.fromFormat(newTaskDate, 'yyyy-LL-dd') >= taskFinishDate) {
        newTaskDateArr.push({ ...task, finish_date: newTaskDate, execution_date: newTaskDate });
        changeExecutionDate(taskId, newTaskDate, newTaskDate);
      } else {
        newTaskDateArr.push({ ...task, execution_date: newTaskDate });
        changeExecutionDate(taskId, newTaskDate);
      }
    } else {
      newTaskDateArr.push({ ...task, finish_date: newTaskDate });
      changeFinishDate(taskId, newTaskDate);
    }

    oldTaskDateArr.splice(taskIndex, 1);
    setTasks((t: any) => ({ ...t, [oldTaskDate]: oldTaskDateArr, [newTaskDate]: newTaskDateArr }));
  };

  const getListData = useMemo(
    () => (value: any) => {
      const key = value.format('YYYY-MM-DD');

      return tasks[key] || [];
    },
    [tasks],
  );

  const dateCellRender = useMemo(
    () => (value: any) => {
      const listData = getListData(value);

      if (listData) {
        return (
          <DateCell
            accountIdTutorial={accountIdTutorial}
            data={listData}
            date={value}
            setSelected={(id, param) => setSelected(id, param)}
            showAccountLogo={page !== 'account_activities'}
            canCreateTask
          />
        );
      }
    },
    [getListData, page, setSelected, accountIdTutorial],
  );

  const filters = (value: moment.Moment, onChange: (date: moment.Moment) => void) => (
    <div style={{ padding: 8 }}>
      <ActivitiesFilters
        accountId={accountId}
        params={{ ...variables }}
        page={page}
        newTask={() => setSelected('new')}
        value={value}
        onChange={onChange}
        view={view}
        totalWeekendActivities={totalWeekendActivities}
      />
    </div>
  );

  const tourId = useMemo(() => {
    if (!!tasks && Object.keys(tasks).length === 0) {
      return ['003_ActivitiesFilters'];
    }

    if ((!!tasks && Object.keys(tasks).length > 0) || !tasks) {
      return ['003_ActivitiesFilters', '005_ActivitiesCalendar'];
    }
  }, [tasks]);

  const title = useMemo(() => {
    if (!!tasks && Object.keys(tasks).length === 0) {
      return [t('activities_filter')];
    }

    if ((!!tasks && Object.keys(tasks).length > 0) || !tasks) {
      return [t('activities_filter'), t('calendar_activity')];
    }
  }, [tasks, t]);

  const hasMoreItems = useMemo(() => {
    function checkItems(item: string) {
      return tasks[item].length > 5;
    }

    if (!!tasks && Object.keys(tasks).findIndex(checkItems) !== -1) return true;

    return false;
  }, [tasks]);

  return (
    <>
      <Card className={styles.root} style={{ display: 'flex', width: '100%' }} bodyStyle={{ width: '100%' }}>
        {!tasks && error && <ErrorAndLoading error={error} borderless />}

        {tasks && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Calendar
              mode="month"
              dateCellRender={dateCellRender}
              defaultValue={beginVariable ? moment(beginVariable) : undefined}
              headerRender={({ value, onChange }) => filters(value, onChange)}
              className={hideWeekend ? 'no-weekend' : undefined}
            />
          </DragDropContext>
        )}
      </Card>

      <div style={{ position: 'fixed', bottom: 10, right: 30, display: 'inline-flex' }}>
        <div className={styles.buttons_view} id="drawerException">
          <Tooltip
            destroyTooltipOnHide={{ keepParent: false }}
            title={t('tooltip_create_task')}
            placement="left"
            mouseEnterDelay={0.6}>
            <Button
              id="create_task_bottom"
              type="primary"
              shape="circle"
              size="large"
              icon={<PlusOutlined style={{ fontSize: 20 }} />}
              onClick={() => setSelected('new')}
            />
          </Tooltip>

          {page === 'account_activities' && (
            <Tooltip
              destroyTooltipOnHide={{ keepParent: false }}
              title={t('tooltip_templates')}
              placement="left"
              mouseEnterDelay={0.6}>
              <Button
                id="show_account_templates_bottom"
                type="primary"
                shape="circle"
                size="large"
                icon={<BiBook style={{ fontSize: 24, paddingTop: 4 }} />}
                className={styles.blue_button}
                onClick={() => navigate(route('id.home.templates', { id: accountId }))}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <TutorialButton tourId={tourId} top={80} right={20} title={title} />
      <TutorialActivitiesCalendar page={page} hasMoreItems={hasMoreItems} />
      <TutorialFilters view={view} page={page} />
    </>
  );
};

export default ActivitiesCalendar;

const update_task_dates = gql`
  mutation UpdateTaskStep($id: ID!, $finish_date: Date, $execution_date: Date) {
    updateTask(id: $id, finish_date: $finish_date, execution_date: $execution_date) {
      id
      finish_date
      ... on DefaultTask {
        execution_date
      }
    }
  }
`;
