import React from 'react';
import { Typography } from 'antd';
import { FallOutlined, RiseOutlined } from '@ant-design/icons';

import styles from './styles.module.less';

interface Props {
  value: number;
  unit?: string;
  title: string;
  text?: string;
  up?: boolean;
  rootFlex?: boolean;
  format?: 'currency' | 'decimal';
}

//Bloco que contém informações de título, valor e texto com ícone informando queda ou aumento do valor
//esses 3 items ficam alinhados e justificados no centro
//utilizado para relatórios de google e facebook

const StatsBlock: React.FC<Props> = ({ value, unit, title, text, up, rootFlex, format }) => {
  const fmt = new Intl.NumberFormat('pt-BR', { style: format, currency: 'BRL' });

  return (
    <div style={{ flex: rootFlex ? undefined : 1 }} className={styles.root}>
      <Typography.Text className={styles.title}>{title}</Typography.Text>
      <div className={styles.progress}>
        <div>
          <Typography.Text className={styles.value}>{fmt.format(value)}</Typography.Text>
          <Typography.Text className={styles.value}>{unit}</Typography.Text>
        </div>

        {/*<Progress percent={100} strokeColor={'#62A8EA'} trailColor="rgba(0, 0, 0, 0.5)" showInfo={false} size="small" />*/}
      </div>

      <div>
        {!!text && up && <RiseOutlined className={styles.icon_up} />}
        {!!text && !up && <FallOutlined className={styles.icon_down} />}
        <Typography.Text className={styles.text}>{text}</Typography.Text>
      </div>
    </div>
  );
};

export default StatsBlock;
