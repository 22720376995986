import React, { useMemo } from 'react';
import { Button } from 'antd';
import moment from 'moment';
import { DateTime } from 'luxon';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import DatePicker from '@comp/antd/DatePicker';
import useConfig from '@logic/config';

const ReportsPeriodFilter: React.FC = () => {
  const config = useConfig();

  const { begin, end } = {
    begin: (config.reports_timers_filter_begin as any) || DateTime.local().startOf('month').toFormat('yyyy-LL-dd'),
    end: (config.reports_timers_filter_end as any) || DateTime.local().endOf('month').toFormat('yyyy-LL-dd'),
  };

  //-------------------------- QUERY -------------------------------------

  const timeDiff = useMemo(
    () =>
      begin && end
        ? DateTime.fromFormat(end, 'yyyy-LL-dd').diff(DateTime.fromFormat(begin, 'yyyy-LL-dd'), 'days').days + 1
        : 0,
    [begin, end],
  );

  function setPeriod(period: { begin: string; end: string }) {
    config.setConfig('reports_timers_filter_begin', period.begin);
    config.setConfig('reports_timers_filter_end', period.end);
  }

  const onCalendarChange = (range: any, rangeString: any) => {
    if (rangeString[0] && rangeString[1]) {
      setPeriod({ begin: formatTime(rangeString[0]), end: formatTime(rangeString[1]) });
    }
  };
  const fowardPeriodFilter = () => {
    if (!begin || !end) return;
    setPeriod({
      begin: DateTime.fromFormat(begin, 'yyyy-LL-dd').plus({ day: timeDiff }).toFormat('yyyy-LL-dd'),
      end: DateTime.fromFormat(end, 'yyyy-LL-dd').plus({ day: timeDiff }).toFormat('yyyy-LL-dd'),
    });
  };

  const backwardsPeriodFilter = () => {
    if (!begin || !end) return;
    setPeriod({
      begin: DateTime.fromFormat(begin, 'yyyy-LL-dd').minus({ day: timeDiff }).toFormat('yyyy-LL-dd'),
      end: DateTime.fromFormat(end, 'yyyy-LL-dd').minus({ day: timeDiff }).toFormat('yyyy-LL-dd'),
    });
  };

  return (
    <>
      {begin && end && <Button icon={<LeftOutlined />} onClick={backwardsPeriodFilter} />}

      <DatePicker.RangePicker
        style={{ marginLeft: '6px' }}
        ranges={{
          Hoje: [moment(), moment()],
          'Esta semana': [moment().startOf('week'), moment().endOf('week')],
          'Este mês': [moment().startOf('month'), moment().endOf('month')],
        }}
        format="DD/MM/YYYY"
        value={[
          !!begin ? moment(DateTime.fromFormat(begin, 'yyyy-LL-dd').toISO()) : null,
          !!end ? moment(DateTime.fromFormat(end, 'yyyy-LL-dd').toISO()) : null,
        ]}
        onCalendarChange={onCalendarChange}
      />

      {begin && end && <Button style={{ marginLeft: '6px' }} icon={<RightOutlined />} onClick={fowardPeriodFilter} />}
    </>
  );
};

export default ReportsPeriodFilter;

function formatTime(time: string) {
  return DateTime.fromFormat(time, 'dd/LL/yyyy').toFormat('yyyy-LL-dd');
}
