import React, { useCallback, useLayoutEffect, useState, PropsWithChildren } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Button, Checkbox, Collapse, Empty, Input, notification } from 'antd';

import TemplateItemsList from './TemplateItemsList';
import TemplatesListItem from './TemplatesListItem';

import { useMutation } from '@apollo/client';
import { create_template, move_before, move_after } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';
import { useStore } from '@logic/context';

import styles from './styles.module.less';

interface Props {
  templatesArray: any[];
  openItem: (id: string) => void;
  onSelect: (type: 'templates' | 'items', id: string, value: boolean) => void;
  accountId: string;
  accountName?: string;
  selectedItems: string[];
  selectedTemplates: string[];
  refresh: () => void;
  reload: boolean;
  realm?: boolean;
}

const TemplatesList: React.FC<Props> = ({
  templatesArray,
  openItem,
  onSelect,
  accountId,
  accountName,
  selectedItems,
  selectedTemplates,
  refresh,
  reload,
  realm,
}) => {
  const [openned, setOpenned] = useState<string[]>([]);
  const [templates, setTemplates] = useState<any[]>(templatesArray);
  const [template, setTemplate] = useState('');

  const [MoveAfter] = useMutation(move_after);
  const [MoveBefore] = useMutation(move_before);

  const [CreateTemplate] = useMutation(create_template);

  const store = useStore();

  useLayoutEffect(() => {
    if (!templatesArray) return;

    setTemplates(templatesArray);
  }, [templatesArray]);

  const openOrCloseTemplates = useCallback(
    (key: string) => {
      const newArr = [...openned];
      const index = openned.indexOf(key);

      if (index === -1) {
        newArr.push(key);
      } else {
        newArr.splice(index, 1);
      }

      setOpenned(newArr);
      store.setShowItems(newArr.length > 0);
    },
    [openned, store],
  );

  const checkForSelected = useCallback(
    (id: string, template?: boolean) => {
      if (template) return selectedTemplates.indexOf(id) !== -1;

      return selectedItems.indexOf(id) !== -1;
    },
    [selectedItems, selectedTemplates],
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !templates) return;

    const from = result.source.index;
    const to = result.destination.index;

    if (from === to) return;

    const reference = from < to ? 'after' : 'before';
    const templateOrdId = templates[from].id;
    const templateRefId = templates[to].id;

    Order(reference, templateRefId, templateOrdId);

    const newTemplates = [...templates];

    const originItem = newTemplates.find((t: any) => t.id === templateOrdId);

    newTemplates.splice(from, 1);

    newTemplates.splice(to, 0, originItem);

    setTemplates(newTemplates);
  };

  async function Order(reference: 'before' | 'after', templateRefId: string, templateOrdId: string) {
    try {
      if (reference === 'after')
        await MoveAfter({ variables: { type: 'Template', id: templateOrdId, target: templateRefId } });
      if (reference === 'before')
        await MoveBefore({ variables: { type: 'Template', id: templateOrdId, target: templateRefId } });
    } catch (err) {
      console.error(err);
    } finally {
      refresh();
    }
  }
  async function doCreate() {
    try {
      await CreateTemplate({ variables: { account: accountId || null, title: template } });

      setTemplate('');

      refresh();
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTemplate(event?.target?.value);
  }

  return (
    <>
      {templates.length === 0 && (
        <div>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: 0, paddingTop: 8, paddingBottom: 8 }}
            description="lista vazia"
          />
        </div>
      )}

      {templates && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={accountId || 'realm'}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {templates.map((template, idx) => (
                  <Draggable
                    isDragDisabled={openned.indexOf(template.id) !== -1}
                    draggableId={template.id}
                    key={`${template.id}_${idx}`}
                    index={idx}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Pannel
                          openned={openned}
                          itemKey={template.id}
                          header={
                            <div
                              id="select_item"
                              style={{
                                padding: 0,
                                display: 'flex',
                                alignItems: 'center',
                                paddingBottom: 4,
                                paddingTop: 4,
                                flexDirection: 'row',
                                width: 'calc(100% - 12px)',
                              }}>
                              <Checkbox
                                id={template.id}
                                checked={checkForSelected(template.id, true)}
                                style={{ marginRight: 6, padding: 0 }}
                                onChange={({ target }) => onSelect('templates', template.id, target.checked)}
                                onClick={(e) => e.stopPropagation()}
                              />
                              <TemplatesListItem
                                openTemplate={() => openOrCloseTemplates(template.id)}
                                id={template.id}
                                templateTitle={template.title}
                                account={template.account?.name}
                                displayOnly={realm}
                              />
                            </div>
                          }>
                          <TemplateItemsList
                            accountId={accountId}
                            templateId={template.id}
                            checkForSelected={(id) => checkForSelected(id)}
                            setSelection={(id, value) => onSelect('items', id, value)}
                            refresh={refresh}
                            reload={reload}
                            openItem={openItem}
                            displayOnly={realm}
                          />
                        </Pannel>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!realm && (
        <div id="create_account_template" style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
          <Input
            value={template}
            onChange={handleChange}
            style={{ border: '1px solid #' }}
            title={
              !!accountName
                ? `Este template poderá ser usado apenas em ${accountName}`
                : 'Este template poderá ser usado em qualquer cliente'
            }
            placeholder={`Criar novo template ${!!accountName ? 'em ' + accountName : ''}`}
            onKeyPress={({ key }) => {
              if (key === 'Enter') {
                doCreate();
              }
            }}
          />
          <Button type="primary" onClick={() => doCreate()}>
            Salvar
          </Button>
        </div>
      )}
    </>
  );
};

export default TemplatesList;

interface PannelInterface {
  openned: string[];
  itemKey: string;
  header: any;
}

const Pannel: React.FC<PropsWithChildren<PannelInterface>> = ({ itemKey, openned, children, header }) => {
  return (
    <Collapse
      bordered={false}
      destroyInactivePanel={true}
      activeKey={openned}
      expandIconPosition="right"
      className={styles.collapse}>
      <Collapse.Panel key={itemKey} className={styles.panel} style={{ padding: 0 }} header={header}>
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};
