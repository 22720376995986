import React, { CSSProperties } from 'react';
import { Avatar } from 'antd';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { BiBuildings } from 'react-icons/bi';
import { MdPeopleOutline } from 'react-icons/md';

interface Props {
  pictureLink?: string;
  target: 'Account' | 'Cell' | 'User'; // "Cell" e "Account" são quadrados e "User" é redondo
  size: number;
  name?: string; // Para mostrar as iniciais quando não houver uma foto
  params?: number;
  upload?: boolean;
  style?: CSSProperties;
}

const AvatarPicture: React.FC<Props> = ({ pictureLink, target, size, name, params, upload, style }) => {
  return (
    <>
      {target === 'User' && upload && (
        <Avatar
          size={size}
          style={{ backgroundColor: intToRGB(hashCode(name)), border: 'none', ...(style ?? {}) }}
          icon={!!name ? null : <LoadingOutlined />}>
          {abbreviation(name)}
        </Avatar>
      )}

      {target === 'Cell' && upload && (
        <Avatar
          size={size}
          shape="square"
          style={{ backgroundColor: intToRGB(hashCode(name)), border: 'none', ...(style ?? {}) }}
          icon={!!name ? null : <LoadingOutlined />}>
          {abbreviation(name)}
        </Avatar>
      )}

      {target === 'Account' && upload && (
        <Avatar
          size={size}
          shape="square"
          style={{ backgroundColor: intToRGB(hashCode(name)), border: 'none', ...(style ?? {}) }}
          icon={!!name ? null : <LoadingOutlined />}>
          {abbreviation(name)}
        </Avatar>
      )}

      {target === 'User' && !upload && (
        <Avatar
          size={size}
          style={{ backgroundColor: intToRGB(hashCode(name)), border: 'none', ...(style ?? {}) }}
          icon={!!name ? null : <UserOutlined />}
          src={pictureLink}>
          {abbreviation(name)}
        </Avatar>
      )}

      {target === 'Account' && !upload && (
        <Avatar
          size={size}
          shape="square"
          style={{ backgroundColor: intToRGB(hashCode(name)), border: 'none', ...(style ?? {}) }}
          icon={!!name ? null : <BiBuildings style={{ fontSize: size, padding: Math.floor(size / 8) }} />}
          src={pictureLink}>
          {abbreviation(name)}
        </Avatar>
      )}

      {target === 'Cell' && !upload && (
        <Avatar
          size={size}
          shape="square"
          style={{ backgroundColor: intToRGB(hashCode(name)), border: 'none', ...(style ?? {}) }}
          icon={!!name ? null : <MdPeopleOutline style={{ fontSize: size - 2, padding: Math.floor(size / 8) }} />}
          src={pictureLink}>
          {abbreviation(name)}
        </Avatar>
      )}
    </>
  );
};

export default AvatarPicture;

export function abbreviation(text?: string) {
  if (!!text) {
    return text
      .split(' ')
      .map((w: string) => w[0])
      .join('')
      .replace(/[^A-Za-z0-9]/g, '');
  }

  return '';
}

function hashCode(str?: string) {
  if (!str) return 'mahoe';

  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: any) {
  // return '#d9d9d9'
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '#' + '00000'.substring(0, 6 - c.length) + c;
}
