import localForage from 'localforage';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ApolloClient, ApolloProvider, gql, InMemoryCache, useLazyQuery } from '@apollo/client';
import ErrorAndLoading from '@comp/ErrorAndLoading';
//import PusherLink from '@logic/PusherLink';
import { useStore } from '@logic/context';
import * as ws from '@logic/functions/socket';
import Login from '@pages/Login';
import RecoverPassword from '@pages/RecoverPassword';
import ResetPassword from '@pages/ResetPassword';
import Router from '@router';

import possibleTypes from './possibleTypes.json';

export const GraphURL = process.env.REACT_APP_GRAPH_HTTP_URL || 'http://localhost:5000';
export const GraphWS = process.env.REACT_APP_GRAPH_WS_URL || 'ws://localhost:5000';

const LoginSwitch: React.FC = () => {
  const store = useStore();

  const token = store.token;

  const setToken = useMemo(
    () => async (token?: string | null) => {
      await store.storeToken(!token ? null : `${token}`);
    },
    [store],
  );

  // Armazenando os dados do usuário no store
  const setProfile = useMemo(
    () => (data: any) => {
      store.setProfile(
        data
          ? {
              name: data.session?.user?.name,
              email: data.session?.user?.email,
              roles: data.session?.user?.roles,
              id: data.session?.user?.id,
              avatarUrl: data.session?.user?.avatarUrl,
              accounts: data.session?.user?.accounts.data,
              realmId: data.session?.user?.realm?.id,
            }
          : null,
      );
    },
    [store],
  );

  const client = useMemo(() => {
    if (token) ws.authenticate(token);

    return new ApolloClient({
      cache,
      connectToDevTools: process.env.NODE_ENV === 'development',
      uri: GraphURL,
      headers: { Authorization: token || '' },
    });
  }, [token]);

  const [renewToken, { data, error, loading }] = useLazyQuery(session, { client });

  //----------------------------------------------------------

  useEffect(() => {
    // @ts-ignore
    window.apolloClient = client;
    //  FIXME  .
  }, [client]);

  useEffect(() => {
    if (token === undefined) {
      localForage
        .getItem('token')
        .then((token: any) => {
          setToken(token ? token : null);
          setProfile(null);
        })
        .catch((err) => console.error(`Get localForage token failed`, err));
    }

    if (token) {
      renewToken();
    }
  }, [token, setToken, setProfile, renewToken]);

  // Se o token for válido retornará um novo token valido, caso contrário levará o usuário para a tela de login
  useEffect(() => {
    if (error) {
      setToken(null);
      setProfile(null);
    } else if (data) {
      setProfile(data);
      setToken(data.session.token);
    }
  }, [data, error, setToken, setProfile]);

  //-----------------------------------------------------------

  if (store.token === undefined || loading) return <ErrorAndLoading loading />;

  if (store.profile) {
    return (
      <ApolloProvider client={client}>
        <Router />
      </ApolloProvider>
    );
  }

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/reset_password/:token" element={<ResetPassword />} />
          <Route path="/recover_password" element={<RecoverPassword />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
};
export default observer(LoginSwitch);

//----------------------------------------------------------

const session = gql`
  query {
    session {
      user {
        id
        name
        email
        roles
        avatarUrl
        realm {
          id
        }
        accounts(pagination: { limit: -1, page: 1 }, status: active) {
          total
          data {
            id
            name
            status
            logoUrl
          }
        }
      }
      token
    }
  }
`;

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Cell: {
      fields: {
        members: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
        accounts: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    User: {
      fields: {
        accounts: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    AssignmentTask: {
      fields: {
        users: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
        notes: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    DefaultTask: {
      fields: {
        users: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
        notes: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    me: {
      fields: {
        accounts: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    Account: {
      merge(existing = [], incoming: any[]) {
        return incoming;
      },
      fields: {
        mailerlite_fields: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
      },
    },
  },
});
