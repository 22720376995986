import { NexusGenEnums, NexusGenFieldTypes } from 'schema';

import TaskEvent from './TaskEvent';
import FromTo from './FromTo';

interface Props {
  data: NexusGenFieldTypes['TaskEventStepChanged'];
}

const stepToLang = (step: NexusGenEnums['TaskStep'] | null) => {
  switch (step) {
    case 'execution':
      return 'execução';
    case 'check':
      return 'revisão';
    case 'approval':
      return 'aprovação';
    case 'finish':
      return 'publicação';
    case 'done':
      return 'finalizado';
    default:
      return null;
  }
};

const TaskEventStepChanged: React.FC<Props> = ({ data }) => {
  return (
    <TaskEvent data={data}>
      alterou a etapa <FromTo from={stepToLang(data.old_step)} to={stepToLang(data.new_step)} />
    </TaskEvent>
  );
};

export default TaskEventStepChanged;
