import { ConfigProvider } from 'antd';
import pt_BR from 'antd/es/locale/pt_BR';
import i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { setConfiguration } from 'react-grid-system';
import { initReactI18next } from 'react-i18next';

import en from './lang/en';
import pt from './lang/pt';
import LoginSwitch from './LoginSwitch';
import * as serviceWorker from './serviceWorker';

import 'moment/locale/pt-br';
import './index.less';

setConfiguration({
  containerWidths: [540, 800, 1200, 800],
  maxScreenClass: 'xl',
});

i18n.use(initReactI18next).init({
  resources: { en, pt },
  lng: navigator.language, //'en',
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
});

// TODO  change language according to location
moment.locale('pt-br'); // => Para datas e calendários

const container = document.getElementById('root');
if (!container) throw new Error('Container was not found');

const root = createRoot(container);

root.render(
  <ConfigProvider
    locale={pt_BR}
    theme={{ token: { colorPrimary: '#ff5333' }, components: { Popover: { colorBgElevated: '#fff!important' } } }}>
    <LoginSwitch />
  </ConfigProvider>,
);
//  FIXME  WARN  render site before or show loading

serviceWorker.unregister();
