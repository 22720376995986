import React from 'react';
import { useTranslation } from 'react-i18next';
import Tour from 'reactour';
import { observer } from 'mobx-react-lite';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

const TutorialRealmManagement: React.FC = () => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '024_RealmManagement' ? true : false;
  const hasGoogleAccounts = store.hasGoogleAccounts === true;
  const hasFacebookAccounts = store.hasFacebookAccounts === true;

  const { t } = useTranslation('tutorialRealmManagement');

  const steps: any[] = [
    {
      selector: '[id="realm_name"]',
      content: t('realm_name'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="save_realm_name"]',
      content: t('save_realm_name'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="add_google_account"]',
      content: t('add_google_account'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      hasGoogleAccounts
        ? {
            selector: '[id="reconnect_google_account_0"]',
            content: t('reconnect_google_account'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasGoogleAccounts
        ? {
            selector: '[id="remove_google_account_0"]',
            content: t('remove_google_account'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="add_facebook_account"]',
      content: t('add_facebook_account'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      hasFacebookAccounts
        ? {
            selector: '[id="reconnect_facebook_account_0"]',
            content: t('reconnect_facebook_account'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasFacebookAccounts
        ? {
            selector: '[id="remove_facebook_account_0"]',
            content: t('remove_facebook_account'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    'realm_name',
    'save_realm_name',
    'add_google_account',
    ...[hasGoogleAccounts ? 'reconnect_google_account' : undefined],
    ...[hasGoogleAccounts ? 'remove_google_account' : undefined],
    'add_facebook_account',
    ...[hasFacebookAccounts ? 'reconnect_facebook_account' : undefined],
    ...[hasFacebookAccounts ? 'remove_google_account' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialRealmManagement);
