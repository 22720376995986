import { NexusGenFieldTypes } from 'schema';
import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventNoteUpdated'];
}

const TaskEventNoteUpdated: React.FC<Props> = ({ data }) => {
  return <TaskEvent data={data}>editou uma nota</TaskEvent>;
};

export default TaskEventNoteUpdated;
