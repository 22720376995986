import React from 'react';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { useStore } from '@logic/context';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { QuestionOutlined } from '@ant-design/icons';

import styles from './styles.module.less';

interface Props {
  title?: string | string[];
  placement?: TooltipPlacement;
  tourId?: string | string[];
  iconSize?: number;
  circleSize?: SizeType;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

const TutorialButton: React.FC<Props> = ({
  title,
  placement,
  tourId,
  iconSize,
  circleSize,
  top,
  bottom,
  left,
  right,
}) => {
  const store = useStore();

  const positionTop = top ? top : undefined;
  const positionBottom = bottom ? bottom : undefined;
  const positionLeft = left ? left : undefined;
  const positionRight = right ? right : undefined;

  const tutorialDropdown = (
    <Menu mode="vertical" theme="light">
      {Array.isArray(tourId) &&
        tourId.map((items: any, index: number) => {
          return (
            <Menu.Item
              key={`${items}_${index}`}
              onClick={() => {
                store.setTour(items);
                //window.scrollTo({ top: 0, behavior: 'smooth' });
              }}>
              {!!title && title[index]}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  if (typeof tourId === 'string') {
    return (
      <div
        style={{
          position: 'fixed',
          bottom: positionBottom,
          left: positionLeft,
          top: positionTop,
          right: positionRight,
          display: 'inline-flex',
        }}>
        <div className={styles.buttons_view} id="drawerException">
          <Tooltip
            destroyTooltipOnHide={{ keepParent: false }}
            title={title}
            mouseEnterDelay={0.6}
            placement={placement ? placement : 'top'}>
            <Button
              shape="circle"
              size={circleSize ? circleSize : 'middle'}
              className={styles.button}
              icon={<QuestionOutlined style={{ fontSize: iconSize ? iconSize : 18, color: '#ffffff' }} />}
              onClick={() => store.setTour(tourId) /*window.scrollTo({ top: 0, behavior: 'smooth' });*/}
            />
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: positionBottom,
        left: positionLeft,
        top: positionTop,
        right: positionRight,
        display: 'inline-flex',
      }}>
      {Array.isArray(tourId) && (
        <Dropdown trigger={['click']} dropdownRender={() => tutorialDropdown}>
          <Button
            shape="circle"
            size={circleSize ? circleSize : 'middle'}
            className={styles.button}
            icon={<QuestionOutlined style={{ fontSize: iconSize ? iconSize : 18, color: '#ffffff' }} />}
          />
        </Dropdown>
      )}
    </div>
  );
};

export default TutorialButton;
