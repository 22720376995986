import { useEffect } from 'react';

export default function useCloseFn(closeDrawer: () => void, exeption: string) {
  useEffect(() => {
    function isInsideClass(e: any, id: string, restriction: string): boolean {
      if (e.id && typeof e.id === 'string' && e.id === restriction) {
        return true;
      }
      if (e.id && typeof e.id === 'string' && e.id === id) {
        return false;
      }
      if (e.parentNode) {
        return isInsideClass(e.parentNode, id, restriction);
      }
      return true;
    }

    function handleClick(e: MouseEvent) {
      var isInsideDrawerOrTask = isInsideClass(e.target, 'root', exeption);

      if (isInsideDrawerOrTask) return;
      closeDrawer();
    }

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [closeDrawer, exeption]);
  return '';
}
