import React, { PropsWithChildren } from 'react';
import { Badge } from 'antd';

interface Props {
  total: number;
  hideWeekend: boolean;
  title: string;
}

const WeekendActivities: React.FC<PropsWithChildren<Props>> = ({ hideWeekend, total, children, title }) => {
  return (
    <Badge count={hideWeekend && !!total ? total : 0} size="small" title={title}>
      {children}
    </Badge>
  );
};

export default WeekendActivities;
