import React, { useEffect, useState } from 'react';
import { Card, Result, Row, Typography } from 'antd';
import { gql, useQuery } from '@apollo/client';

import { BiUserVoice } from 'react-icons/bi';
import fireworks from '../../../img/fireworks.svg';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import Help from '@comp/Help';
import NewFunctionalities from '@comp/NewFunctionalities';

import Feedback from './Feedback';

import styles from './styles.module.less';

export const pending_feedbacks = gql`
  query Feedbacks {
    me {
      id
      myUnresolvedFeedbacks(pagination: { limit: 10, page: 1 }) {
        data {
          id
          feedback
          nps
          status
          user {
            id
            name
            avatarUrl
          }
          created_at
          task_id
          account_id
        }
      }
    }
  }
`;

const PendingFeedbacks: React.FC = () => {
  const [feedbacks, setFeedbacks] = useState<any[]>();

  const { data, loading, error, refetch } = useQuery(pending_feedbacks, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (!data) return;

    setFeedbacks(data.me?.myUnresolvedFeedbacks?.data);
  }, [data]);

  if (error) return <ErrorAndLoading error={error} />;

  return (
    <Card bodyStyle={cardBodyStyle} style={cardStyle} loading={loading} id="pending_feedbacks">
      <Row className={styles.title}>
        <Typography.Title className={styles.title_text}>
          <BiUserVoice className={styles.title_icon} size={18} />
          Feedbacks
        </Typography.Title>

        <div className={styles.help}>
          <NewFunctionalities
            title="Ajuda"
            description={helpText}
            configId="2021-02-04-teste.013"
            fixedTooltip={true}
            ribbon={false}
            placement="bottomRight"
            offset={[10, 4]}
            primaryColor={true}>
            <Help
              title="Feedbacks"
              description="São todos os feedbacks não aplicados das suas atividades"
              iconStyle={{ color: '#5c5c5c' }}
            />
          </NewFunctionalities>
        </div>
      </Row>

      {feedbacks?.length === 0 && (
        <Result
          style={{ height: 450, paddingTop: 150 }}
          icon={<img src={fireworks} alt="Parabéns!" className={styles.fireworks_image} style={{ height: 64 }} />}
          subTitle="Parabéns, você não possui atividades aguardando correção"
        />
      )}

      {!!feedbacks && feedbacks.length > 0 && (
        <div className={styles.feedbacks_list}>
          {feedbacks.map((e: any, idx: number) => {
            //  TODO  refetch quando mudar o status de dentro do drawer P.S.: query diferente das atividades
            if (e.status === 'resolved') return null;

            return (
              <Feedback
                taskId={e.task_id}
                accountId={e.account_id}
                key={e.id}
                id={e.id}
                nps={e.nps}
                feedback={e.feedback}
                status={e.status}
                createdAt={e.created_at}
                user={e.user.name}
                refetch={refetch}
                userID={e.user.id}
                avatarUrl={e.user.avatarUrl}
              />
            );
          })}
        </div>
      )}
    </Card>
  );
};

export default PendingFeedbacks;

const cardStyle = { borderRadius: 4, overflow: 'hidden', boxShadow: '0 1px 4px #00000040' };
const cardBodyStyle = { padding: 0 };

const helpText =
  'Os ícones de ajuda estão presentes para te ajudar a entender alguns conceitos. Coloque o mouse sobre eles e uma breve explicação.';
