import React, { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import { Card, Typography, Button, notification, Popconfirm, Tooltip, Rate } from 'antd';

import EmojiLinkInput from '@comp/EmojiLinkInput';
import AvatarPicture from '@comp/AvatarPicture';

import { useStore } from '@logic/context';
import { set_feedback_status } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';
import Upload from '@comp/Upload';
import OnPasteDiv from '@comp/OnPasteDiv';
import { UploadItem } from '@logic/stores/uploadManager';

interface Props {
  taskId: string;
  id: string;
  nps?: number;
  status: string;
  feedback?: string;
  user: string;
  createdAt: string;
  userID: string;
  avatarUrl: string;
  refetch: () => void;
}

const EditFeedback: React.FC<Props> = ({
  taskId,
  id,
  feedback: paramFeedback,
  status,
  nps: paramNps,
  createdAt,
  user,
  avatarUrl,
  userID,
  refetch,
}) => {
  const [{ feedback, nps }, setFeedback] = useState({ feedback: paramFeedback, nps: paramNps });
  const [edit, setEdit] = useState(false);

  const { profile, uploadManager } = useStore();

  const profileId = profile?.id;
  const isAdmin = profile?.roles.includes('admin');

  const canEdit = userID === profileId || isAdmin;

  //-------------------------- Queries and mutations ---------------------------

  const [SetFeedbackStatus] = useMutation(set_feedback_status);
  const [UpdateFeedback] = useMutation(edit_feedback);

  //------------------------- Check Feedback functions ------------------------------------

  const Edit = useCallback(
    () =>
      UpdateFeedback({ variables: { id, feedback, nps } }).then(() =>
        notification.open({
          placement: 'bottomRight',
          duration: 2,
          type: 'success',
          message: 'Feedback editado',
        }),
      ),
    [UpdateFeedback, id, feedback, nps],
  );

  async function setCheckFeedback(id: string, status: string) {
    try {
      await SetFeedbackStatus({ variables: { id, status } });

      if (status === 'resolved') {
        notification.open({
          placement: 'bottomRight',
          duration: 2,
          type: 'success',
          message: 'Problema concluído',
        });
      } else {
        notification.open({
          placement: 'bottomRight',
          duration: 2,
          type: 'success',
          message: 'Problema definido como pendente',
        });
      }

      refetch();
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  //------------------------------------------------------------------------------

  const isResolved = status === 'resolved';

  return (
    <OnPasteDiv
      onPaste={(data) => {
        for (const file of data) {
          const uploadItem: UploadItem = { targetId: id, targetType: 'Feedback', file };

          uploadManager.uploadFile(uploadItem);
        }
      }}>
      <Card
        id="feedbacks_task"
        data-tut="feedbacks_task"
        style={{ marginBottom: '16px', borderColor: '#ff5333' }}
        headStyle={{ paddingLeft: 12, paddingRight: 12 }}
        bodyStyle={{ padding: 0 }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
              //onClick={() => history.push(route(''))} ir para o perfil de quem criou a nota
            >
              <div className={styles.profilePhoto}>
                <AvatarPicture pictureLink={avatarUrl} target="User" size={40} name={user} />
              </div>

              <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography className={styles.name} style={{ margin: 0, padding: 0 }}>
                    {user}
                  </Typography>

                  {((!!nps && nps > 0) || edit) && (
                    <span style={{ marginLeft: 8, paddingBottom: 2 }}>
                      <Rate
                        disabled={!edit}
                        value={nps}
                        onChange={(value) => setFeedback({ feedback, nps: value })}
                        style={{ fontSize: 16 }}
                      />
                    </span>
                  )}
                </div>

                <Typography className={styles.date}>
                  {DateTime.fromFormat(createdAt, 'yyyy-LL-dd HH:mm:ss', { zone: 'utc' })
                    .toLocal()
                    .toFormat('HH:mm:ss - dd/LL/yyyy')}
                </Typography>
              </div>
            </div>

            <div>
              {canEdit && !edit && (
                <Button size="small" style={{ marginLeft: 6 }} onClick={() => setEdit(true)}>
                  Editar
                </Button>
              )}

              <Popconfirm
                destroyTooltipOnHide={{ keepParent: false }}
                title={
                  isResolved ? 'Confirma marcar feedback como pendente?' : 'Confirma marcar feedback como concluído?'
                }
                onConfirm={() => setCheckFeedback(id, isResolved ? 'unresolved' : 'resolved')}
                onCancel={() => {}}
                okText="Sim"
                cancelText="Não">
                <Tooltip
                  destroyTooltipOnHide={{ keepParent: false }}
                  placement="bottom"
                  title={isResolved ? 'Marcar como pendente?' : 'Marcar como concluído?'}>
                  <Button size="small" style={{ marginLeft: 6 }} type={isResolved ? 'default' : 'primary'}>
                    {isResolved ? 'Concluído' : 'Pendente'}
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
          </div>
        }>
        {(edit || feedback) && (
          <div style={{ marginLeft: 12, marginRight: 12, marginTop: 12 }}>
            <EmojiLinkInput
              border={edit}
              multiline
              emojiPicker={edit ? 'on' : 'off'}
              className={styles.body}
              viewOnly={!edit}
              value={feedback}
              onChange={(value) => {
                if (edit) setFeedback({ feedback: value.target.value, nps });
              }}
            />
          </div>
        )}

        <div style={{ width: '100%', marginTop: 1, paddingLeft: 12, paddingRight: 12 }}>
          <Upload targetId={id} viewOnly={!edit} parentId={taskId} targetType="Feedback" />
        </div>

        {edit && (
          <Button
            style={{ marginTop: 6, marginBottom: 12, marginLeft: 12 }}
            type="primary"
            size="small"
            onClick={() => {
              setEdit(false);
              Edit();
            }}>
            Salvar
          </Button>
        )}

        {edit && (
          <Button size="small" onClick={() => setEdit(false)} style={{ marginLeft: 6, marginBottom: 12 }}>
            Cancelar
          </Button>
        )}
      </Card>
    </OnPasteDiv>
  );
};

export default observer(EditFeedback);

const edit_feedback = gql`
  mutation EditFeedback($id: ID!, $feedback: String, $nps: Int) {
    updateFeedback(id: $id, feedback: $feedback, nps: $nps) {
      id
      feedback
      nps
      status
      created_at
      user {
        id
        name
      }
    }
  }
`;
