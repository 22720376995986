import { NexusGenFieldTypes } from 'schema';
import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventTypeChanged'];
}

const TaskEventTypeChanged: React.FC<Props> = ({ data }) => {
  return (
    <TaskEvent data={data}>
      alterou o tipo para <b>{data.type === 'default' ? 'tarefa padrão' : 'terefa pontual'}</b>
    </TaskEvent>
  );
};

export default TaskEventTypeChanged;
