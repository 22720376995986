import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  hasUser: boolean;
  hasAccount: boolean;
  addUserButton: boolean;
  addAccountButton: boolean;
}

const TutorialEditCell: React.FC<Props> = ({ hasUser, hasAccount, addAccountButton, addUserButton }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '018_EditCellManagement' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="avatar_cell"]',
      content: 'Para adicionar uma foto ou imagem para a célula, clique sobre o avatar.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="name_cell"]',
      content: 'Altere o nome da célula.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="save_cell_name"]',
      content: 'Após realizar as alterações no nome da célula, clique em "salvar".',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      addUserButton
        ? {
            selector: '[id="add_user_cell"]',
            content: 'Adicione um novo usuário na célula.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasUser
        ? {
            selector: '[id="open_user_cell_button"]',
            content: 'Você pode abrir o perfil do usuário em "Visualizar".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasUser
        ? {
            selector: '[id="open_user_cell"]',
            content: 'Ou clique sobre o usuário, que você também pode ver o perfil dele.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasUser
        ? {
            selector: '[id="delete_user_cell"]',
            content: 'Remova o usuário da célula, clicando em "Remover".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      addAccountButton
        ? {
            selector: '[id="add_account_cell"]',
            content: 'Adicione um novo cliente na célula.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasAccount
        ? {
            selector: '[id="open_account_cell_button"]',
            content: 'Você pode abrir o perfil do cliente em "Visualizar".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasAccount
        ? {
            selector: '[id="open_account_cell"]',
            content: 'Ou clique sobre o cliente, que você também pode ver o perfil dele.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasAccount
        ? {
            selector: '[id="delete_account_cell"]',
            content: 'Remova o cliente da célula, clicando em "Remover".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    'avatar_cell',
    'name_cell',
    'add_user_cell',
    ...[hasUser ? 'open_user_cell_button' : undefined],
    ...[hasUser ? 'open_user_cell' : undefined],
    ...[hasUser ? 'delete_user_cell' : undefined],
    'add_account_cell',
    ...[hasAccount ? 'open_account_cell_button' : undefined],
    ...[hasAccount ? 'open_account_cell' : undefined],
    ...[hasAccount ? 'delete_account_cell' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialEditCell);
