export const masks = {
  phone: (v: string) => {
    let i = 0;
    let pat = "####";

    v = v.replace(/\D/g, '');

    if (v.length <= 4) {
      pat = "####";
    } else if (v.length <= 8) {
      pat = "####-####";
    } else if (v.length === 9) {
      pat = "#####-####";
    } else if (v.length === 10) {
      pat = "(##) ####-####";
    } else {
      pat = "(##) #####-####";
    }

    return pat.replace(/#/g, () => v[i++] || '');
  },
};