import { Typography, Tooltip } from 'antd';
import React from 'react';

import { RightOutlined } from '@ant-design/icons';

import styles from './styles.module.less';

interface Props {
  end: boolean;
  value: number;
  icon: any;
  color: string;
  title: string;
  fontSize?: number;
}

const AccountIndicators: React.FC<Props> = ({ end, value, icon, color, title, fontSize }) => {
  return (
    <div className={styles.root} style={{ flex: end ? 1 : 2 }}>
      <Tooltip destroyTooltipOnHide={{ keepParent: false }} placement="top" title={title} align={{ offset: [0, 8] }}>
        <div className={styles.root}>
          <Typography.Text style={{ color }} className={styles.item}>
            {value}
          </Typography.Text>
          <div style={{ color, fontSize }} className={styles.icon}>
            {icon}
          </div>
        </div>
      </Tooltip>

      {!end && <RightOutlined className={styles.arrow} />}
    </div>
  );
};

export default AccountIndicators;
