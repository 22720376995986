import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Card } from 'antd';

import { route } from '@router';

import TutorialButton from '@comp/TutorialButton';
import InfosList from './InfosList';
import TutorialInfo from './TutorialInfo';

import styles from './styles.module.less';

const Info: React.FC = () => {
  const { id: accountID } = useParams<any>();
  const navigate = useNavigate();
  const location = useLocation();

  const infoId = location.hash.split('/')[1];

  return (
    <>
      <Card className={styles.root}>
        <div id="create_info" style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            id="infoListItem"
            onClick={() => navigate(route('id.home.infos', { id: accountID }) + '#acc_info/new')}>
            Adicionar informação
          </Button>
        </div>

        <InfosList
          infoId={infoId}
          accountId={accountID}
          setSelected={(id) => navigate(route('id.home.infos', { id: accountID }) + '#acc_info/' + id)}
        />
      </Card>

      <TutorialButton tourId="012_Info" top={80} right={20} placement="left" title="Tutorial de informações úteis" />

      <TutorialInfo />
    </>
  );
};

export default Info;
