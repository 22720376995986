import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { DateTime } from 'luxon';
import { Card, Button, notification, Form, Input } from 'antd';

import { create_timer } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';
import DatePicker from '@comp/antd/DatePicker';

interface Props {
  taskId: string;
  refresh: () => void;
}

function formatTime(time: string) {
  return DateTime.fromISO(time).toUTC().toFormat('yyyy-LL-dd HH:mm:ss');
}

const CreateTimer: React.FC<Props> = ({ taskId, refresh }) => {
  const [form] = Form.useForm();

  const { t } = useTranslation('userTimer');

  //--------------------------- MUTATIONS ---------------------------------

  const [CreateTimer] = useMutation(create_timer);

  //---------------------------- FUNCTIONS ----------------------------------
  async function Create(id: string, start_date: string, finish_date: string, body: string) {
    try {
      await CreateTimer({
        variables: { task: id, start_date, finish_date, body },
      });

      notification.open({
        type: 'success',
        message: t('create_message'),
      });

      form.resetFields();

      refresh();
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  //------------------------------------------------------------------------------
  return (
    <>
      <Card id="create_timer" style={{ marginBottom: '16px', borderColor: '#ff5333' }}>
        <Form
          form={form}
          layout="horizontal"
          name={`start_timer_manualy`}
          onFinish={({ dates, body }) =>
            Create(taskId, formatTime(moment(dates[0]).toISOString()), formatTime(moment(dates[1]).toISOString()), body)
          }>
          <Form.Item name="dates">
            <DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format="HH:mm:ss - DD/MM/YYYY" showSecond={false} />
          </Form.Item>

          <Form.Item name="body">
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 20 }} placeholder={t('body_placeholder')} />
          </Form.Item>

          <Button title={t('button_tooltip_create_timer')} type="primary" onClick={() => form.submit()}>
            {t('button_create_timer')}
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default CreateTimer;
