import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Col, notification, Row, Typography } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import EmojiLinkInput from '@comp/EmojiLinkInput';
import OnPasteDiv from '@comp/OnPasteDiv';
import Upload from '@comp/Upload';
import Help from '@comp/Help';

import errorMessage from '@logic/functions/errorHandeling';
import { account_info } from '@logic/queries';
import { account_info_update } from '@logic/mutations';
import { UploadItem } from '@logic/stores/uploadManager';
import { useUploadManager } from '@logic/context';

import styles from './styles.module.less';

interface Props {
  infoId: string;
  accountId: string;
}

const EditInfo: React.FC<Props> = ({ infoId, accountId }) => {
  const [info, setInfo] = useState<any>({ id: infoId, title: '', body: '' });

  const uploadManager = useUploadManager();

  //---------------------- QUERIES -----------------------------

  const { data, loading, error } = useQuery(account_info, { fetchPolicy: 'network-only', variables: { id: info.id } });

  useEffect(() => {
    if (!data) return;

    setInfo({ id: data.account_info.id, title: data.account_info.title, body: data.account_info.body });
  }, [data]);

  //---------------------- mutations ------------------------------

  const [UpdateAccountInfo] = useMutation(account_info_update);

  //----------------- Save, delete, edit functions -----------------------------

  const update = useCallback(
    async (key: string, value: any) => {
      try {
        await UpdateAccountInfo({ variables: { id: infoId, [key]: value } });
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [UpdateAccountInfo, infoId],
  );

  //------------- Form functions ------------------------------------------------

  const handleChange = ({ target: { name, value } }: any) => {
    setInfo((t: any) => ({ ...t, [name]: value }));
  };

  const handleBlur = ({ target }: any) => {
    if (data.account_info[target.name] !== target.value) {
      update(target.name, target.value);
    }
  };

  //------------------------------------------------------------------------------

  if (loading || error) return <ErrorAndLoading loading={loading} error={error} />;

  return (
    <OnPasteDiv
      style={{ paddingBottom: '16px' }}
      onPaste={(data) => {
        for (const file of data) {
          const uploadItem: UploadItem = { targetId: infoId, targetType: 'AccountInfo', file };

          uploadManager.uploadFile(uploadItem);
        }
      }}>
      {!!info && (
        <div className={styles.root} style={{ height: '100%' }}>
          <Row id="title_info" className={styles.title}>
            <Col span={3} className={styles.column}>
              <Typography.Text className={styles.label}>Título: </Typography.Text>
              <Help
                title="Título da informação"
                description="Edite o título clicando sobre o campo. Para adicionar emoji, clique no emoji abaixo que serão mostradas as opções disponíveis."
                placement="right"
                iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
              />
            </Col>
            <Col span={21}>
              <EmojiLinkInput
                name="title"
                placeholder="Título da informação útil"
                border
                multiline
                emojiPicker="focus"
                value={info.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row id="description_info" className={styles.body}>
            <Col span={3} className={styles.column}>
              <Typography.Text className={styles.label}>Descrição: </Typography.Text>
              <Help
                title="Descrição da informação"
                description="Escreva ou altere a informação clicando sobre o campo. Clique no emoji abaixo para adicionar emojis a informação."
                placement="right"
                iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
              />
            </Col>
            <Col span={21}>
              <EmojiLinkInput
                md
                name="body"
                placeholder="Descrição da informação útil"
                border
                multiline
                emojiPicker="focus"
                value={info.body}
                onChange={handleChange}
                onBlur={handleBlur}
                className={styles.md}
              />
            </Col>
          </Row>
        </div>
      )}

      <div id="upload_file_info">
        <Upload targetId={infoId} parentId={accountId} targetType="AccountInfo" />
      </div>
    </OnPasteDiv>
  );
};

export default EditInfo;
