import React, { useState, useEffect, useMemo } from 'react';
import { Typography } from 'antd';

import { DateTime } from 'luxon';

interface Props {
  time: string;
  notWhite?: boolean;
}

// Cronômetro do timer que atualiza a cada segundo

function getFormatedTime(initialTime: DateTime) {
  return DateTime.local().diff(initialTime, ['hours', 'minutes', 'seconds']).toFormat('hh:mm:ss');
}
const Clock: React.FC<Props> = ({ time, notWhite }) => {
  const initialTime = useMemo(
    () =>
      DateTime.fromFormat(time, 'yyyy-LL-dd HH:mm:ss', {
        zone: 'utc',
      }).toLocal(),
    [time],
  );

  const [clock, setClock] = useState(getFormatedTime(initialTime));

  useEffect(() => {
    function updateCounter() {
      setClock(getFormatedTime(initialTime));
    }

    const i = setInterval(updateCounter, 1000);

    updateCounter();
    return () => clearInterval(i);
  }, [initialTime]);

  return <Typography style={{ color: notWhite ? undefined : 'rgba(255,255,255,0.87)' }}>{clock}</Typography>;
};

export default Clock;
