import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { CarryOutOutlined, HeartOutlined, HourglassOutlined, RocketOutlined } from '@ant-design/icons';
import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

const TutorialHomePage: React.FC = () => {
  const store = useStore();

  const isTourOpen = !!store.tour && store.tour === '001_HomeTutorial' ? true : false;

  const steps = [
    {
      selector: '[id="performance"]',
      content: 'Veja sua performance média e indicadores de performance.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="accounts"]',
      content: 'Veja todos os seus clientes e seus indicadores.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="account_indicators"]',
      content: () => {
        return (
          <div style={{ marginLeft: 10 }}>
            <Typography className={styles.text}>Indicadores:</Typography>

            <Typography className={styles.item}>{<HeartOutlined className={styles.icon} />} NPS do cliente</Typography>
            <Typography className={styles.item}>
              {<CarryOutOutlined className={styles.icon} />}% das atividades do mês que foram concluídas
            </Typography>
            <Typography className={styles.item}>
              {<HourglassOutlined className={styles.icon} />}Tempo total usado no mês
            </Typography>
            <Typography className={styles.item}>
              {<RocketOutlined className={styles.icon} />}% da meta alcançada
            </Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="recent_updates"]',
      content:
        'Aqui são exibidas as atualizações das suas atividades. Veja a mudança da etapa da atividade e o usuário que realizou a alteração.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="next_activities"]',
      content: 'Você pode ver suas próximas atividades que não foram concluídas ainda.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="activity_item_open"]',
      content: 'Clicando aqui você pode abrir a atividade.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="pending_feedbacks"]',
      content: 'Você pode ver os feedbacks das atividades que você está envolvido.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="pending_feedbacks_task"]',
      content: 'Veja a atividade deste feedback.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
  ];

  const ids: any[] = [
    'performance',
    'accounts',
    'account_indicators',
    'recent_updates',
    'next_activities',
    'activity_item_open',
    'pending_feedbacks',
    'pending_feedbacks_task',
  ];

  return (
    <>
      <Tour
        startAt={0}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => store.setTour(undefined)}
        accentColor="#ff5333"
        maskSpace={10}
        maskClassName={styles.mask}
        rounded={4}
        getCurrentStep={(curr) => changeStep(curr, ids)}
        onAfterOpen={() => disableScroll('main', 'tag_name')}
        onBeforeClose={() => enableScroll('main', 'tag_name')}
      />
    </>
  );
};

export default observer(TutorialHomePage);
