import React from 'react';
import { List } from 'antd';
import { useQuery } from '@apollo/client';

import ErrorAndLoading from '@comp/ErrorAndLoading';

import { task_events } from '@logic/queries';
import TaskEvent from '@comp/TaskEvent';

interface Props {
  taskId: string;
}

const EventsTab: React.FC<Props> = ({ taskId }) => {
  //------------------------- Queries and mutations ----------------------------

  const { data, loading, error } = useQuery(task_events, {
    variables: { id: taskId },
    fetchPolicy: 'cache-and-network',
  });

  const events = data?.task?.events?.data;

  //------------------------------------------------------------------------------

  return (
    <div>
      {loading && <ErrorAndLoading loading={loading} />}

      {error && <ErrorAndLoading error={error} />}

      {!loading && events && (
        <List
          dataSource={events}
          rowKey={({ id }: any) => id}
          renderItem={({ action, ...data }) => (
            <List.Item>
              <TaskEvent action={action} data={data} />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default EventsTab;
