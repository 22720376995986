import React from 'react';

import Navigate from '../../Navigate';

import logo from '../../../img/id_lab_logo.png';

import styles from './styles.module.less';

// Ir para a tela inicial

const HomeButton: React.FC = () => {
  return (
    <Navigate to="/">
      <div className={styles.logoView}>
        <img src={logo} alt="logo da id action" />
      </div>
    </Navigate>
  );
};

export default HomeButton;
