import React from 'react';
import { Typography, Card, Divider } from 'antd';
import {
  CheckCircleOutlined,
  ReloadOutlined,
  UserOutlined,
  SoundOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';

import styles from './styles.module.less';

interface Props {
  pending: number;
  done: number;
  delayed: number;
  step: string;
}

const CheckingItem: React.FC<Props> = ({ pending, done, delayed, step }) => {
  const lineStyle: React.CSSProperties = {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  };

  const headStyle: React.CSSProperties = {
    marginLeft: 8,
    marginRight: 4,
    fontSize: 20,
    textAlign: 'center',
    flex: 1,
    paddingTop: 16,
  };

  const statValueStyle: React.CSSProperties = {
    marginLeft: 8,
    marginRight: 4,
    fontSize: 20,
    flex: 1,
    textAlign: 'right',
    fontWeight: 600,
  };

  const statHeadStyle: React.CSSProperties = {
    marginLeft: 8,
    marginRight: 4,
    fontSize: 14,
    flex: 2,
  };

  return (
    <Card style={{ flex: 1 }} bodyStyle={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
      {step === 'execution' && (
        <div style={lineStyle}>
          <Typography.Text style={{ ...headStyle, color: '#3385FF' }}>
            {<ReloadOutlined className={styles.icon} />} Execução
          </Typography.Text>
        </div>
      )}

      {step === 'check' && (
        <div style={lineStyle}>
          <Typography.Text style={{ ...headStyle, color: '#FF5333' }}>
            {<FileSearchOutlined className={styles.icon} />} Revisão
          </Typography.Text>
        </div>
      )}

      {step === 'approval' && (
        <div style={lineStyle}>
          <Typography.Text style={{ ...headStyle, color: '#B03591' }}>
            {<UserOutlined className={styles.icon} />} Aprovação
          </Typography.Text>
        </div>
      )}

      {step === 'finish' && (
        <div style={lineStyle}>
          <Typography.Text style={{ ...headStyle, color: '#ff5333' }}>
            {<SoundOutlined className={styles.icon} />} Publicação
          </Typography.Text>
        </div>
      )}

      {step === 'done' && (
        <div style={lineStyle}>
          <Typography.Text style={{ ...headStyle, color: '#55A630' }}>
            {<CheckCircleOutlined className={styles.icon} />} Finalizado
          </Typography.Text>
        </div>
      )}

      <Divider plain style={{ margin: '16px 0' }} />
      <div style={lineStyle}>
        <Typography.Text style={statValueStyle}>{delayed}</Typography.Text>
        <Typography.Text style={statHeadStyle}>atrasadas</Typography.Text>
      </div>

      <div style={lineStyle}>
        <Typography.Text style={statValueStyle}>{pending}</Typography.Text>
        <Typography.Text style={statHeadStyle}>em andamento</Typography.Text>
      </div>

      <div style={lineStyle}>
        <Typography.Text style={statValueStyle}>{done}</Typography.Text>
        <Typography.Text style={statHeadStyle}>concluídas</Typography.Text>
      </div>

      <Divider plain style={{ margin: '16px 0 10px' }} />
      <div style={{ ...lineStyle, padding: '0 0 10px' }}>
        <Typography.Text style={statValueStyle}>{pending + done + delayed}</Typography.Text>
        <Typography.Text style={statHeadStyle}>atividades</Typography.Text>
      </div>
    </Card>
  );
};

export default CheckingItem;
