import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import AvatarPicture from '@comp/AvatarPicture';

import styles from './styles.module.less';

interface Props {
  accountId: string;
  accountChange: (id: string) => void;
}

const accounts_list = gql`
  query AccountsSelect {
    me {
      id
      accounts(pagination: { page: 1, limit: -1 }) {
        data {
          id
          name
          logoUrl
          status
        }
      }
    }
  }
`;

// Seletor de conta da atividade

const AccountSelector: React.FC<Props> = ({ accountId, accountChange }) => {
  const [accounts, setAccounts] = useState([]);

  //---------------------- Queries -----------------------

  const [getAccounts, { data, loading }] = useLazyQuery(accounts_list);

  useLayoutEffect(() => {
    getAccounts();
  }, [getAccounts]);

  useEffect(() => {
    if (data) setAccounts(data?.me?.accounts?.data);
  }, [data]);

  const onSelect = useCallback((value: string) => accountChange(value), [accountChange]);

  return <SelectComponent value={accountId} loading={loading} accountsSelect={accounts} onSelect={onSelect} />;
};

export default AccountSelector;

interface SelectComponentProps {
  loading: boolean;
  onSelect: (value: any) => void;
  accountsSelect: any[];
  value: string;
}

const SelectComponent: React.FC<SelectComponentProps> = ({ loading, onSelect, accountsSelect, value }) => {
  const [searchText, setSearchText] = useState<string>();

  const handleSearch = useCallback((value?: any) => setSearchText(value), []);

  const { t } = useTranslation('accountSelector');

  const accountsSelectItems = useMemo(() => {
    let accounts = accountsSelect.filter(({ status }) => status === 'active');

    if (searchText) {
      accounts = accounts.filter((account) => account.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    }

    if (accounts && accounts.length > 0) {
      return (
        <>
          {accounts.map((account: any) => (
            <Select.Option key={account?.id} value={account?.id}>
              <div className={styles.users_list_item}>
                <AvatarPicture pictureLink={account?.logoUrl} target="Cell" size={20} name={account?.name} />
                <Typography.Text className={styles.user_name}>{account?.name}</Typography.Text>
              </div>
            </Select.Option>
          ))}
        </>
      );
    }

    return null;
  }, [accountsSelect, searchText]);

  return (
    <Select
      style={{ width: '100%' }}
      allowClear
      loading={loading}
      placeholder={t('select_account')}
      value={value}
      onSearch={handleSearch}
      showSearch
      filterOption={false}
      dropdownMatchSelectWidth={false}
      onSelect={onSelect}>
      {accountsSelectItems}
    </Select>
  );
};
