import React from 'react';
import { useQuery } from '@apollo/client';
import { Typography } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import CheckingItem from './CheckingItem';

import { checking_tasks } from '@logic/queries';
import { weekDays } from '@logic/functions/date';

interface Props {
  title?: string;
  user?: string;
  cell?: string;
  account?: string;
  period?: { begin: string | undefined; end: string | undefined };
  summary?: any;
}

const Checking: React.FC<Props> = ({ title, user, cell, account, period, summary }) => {
  const { data, loading, error } = useQuery(checking_tasks, {
    variables: { user, cell, account, begin: period?.begin, end: period?.end },
    fetchPolicy: 'network-only',
  });

  const tasksChecking = data?.tasksChecking;

  if (loading || error) return <ErrorAndLoading loading={loading} error={error} />;

  let performance = 0;

  const days = weekDays();
  if (summary && summary.tasks_total > 0) {
    const tasksPerDay = summary.tasks_total / (days.next.length + days.prev.length || 1);
    const tasksDidPerDay = (summary.tasks_done - summary.tasks_delayed * 1.5) / (days.prev.length || 1);
    performance = tasksDidPerDay / tasksPerDay || 1;
  }

  return (
    <>
      {!!title && (
        <div style={{ padding: '20px 0 8px' }}>
          <Typography.Text style={{ fontSize: 18 }}>
            {title}&nbsp;
            <span style={{ fontSize: 11, fontWeight: 300 }}>- Performance: {(performance * 100).toFixed(1)}%</span>
          </Typography.Text>
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {Object.keys(tasksChecking)
          .filter((key) => key !== '__typename')
          .map((key) => {
            const { pending, done, delayed } = tasksChecking[key];
            return <CheckingItem key={key} pending={pending} done={done} delayed={delayed} step={key} />;
          })}
      </div>
    </>
  );
};

export default Checking;
