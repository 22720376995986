import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { Card, Typography, Button, Form, Input, notification, List, Popover, Select } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import ListWithAddTo from '@comp/ListWithAddTo';
import AvatarPicture from '@comp/AvatarPicture';
import UploadAvatar from '@comp/UploadAvatar';
import TutorialButton from '@comp/TutorialButton';
import TutorialEditUser from './TutorialEditUser';

import {
  users_edit,
  add_user_to_account,
  remove_user_from_account,
  add_user_to_cell,
  remove_user_from_cell,
} from '@logic/mutations';
import { users_info, cells_select, accounts_select } from '@logic/queries';
import errorMessage from '@logic/functions/errorHandeling';
import { route } from '@router';

import styles from './styles.module.less';

type UserRole = 'admin' | 'customer_success' | 'media_tester' | 'creator' | 'customer';

const EditUser: React.FC = () => {
  const [confirmAccount, setConfirmAccount] = useState<number | undefined>();
  const [confirmCell, setConfirmCell] = useState<number | undefined>();
  const [accountIdList, setAccountIdList] = useState<string[]>([]);
  const [cellIdList, setCellIdList] = useState<string[]>([]);
  const [userInfo, setUserInfo] = useState<{
    name: string;
    email: string;
    avatarUrl: string;
    roles: string[];
    status: string;
  }>();
  const [addCell, setAddCell] = useState(false);
  const [addAccount, setAddAccount] = useState(false);

  const [form] = Form.useForm();
  const { user } = useParams<any>();
  const navigate = useNavigate();

  //---------------------- QUERIES & MUTATIONS -----------------------------

  //----------------------- INFO ------------------------

  const [UpdateUser] = useMutation(users_edit);
  const { data, loading, error, refetch } = useQuery(users_info, {
    variables: { id: user },
    //fetchPolicy: 'network-only',
  });

  //--------------------------- ACCOUNT ----------------------------

  const [AttachUserToAccount] = useMutation(add_user_to_account);
  const [DetachUserFromAccount] = useMutation(remove_user_from_account);

  //------------------------------ CELL ----------------------------

  const [AttachUserToCell] = useMutation(add_user_to_cell);
  const [DetachUserFromCell] = useMutation(remove_user_from_cell);

  //--------- cria o array para o select de adicionar usuários -------------

  useEffect(() => {
    if (!data) return;

    const newUserInfo = data.user;

    setUserInfo({
      status: newUserInfo.status,
      roles: newUserInfo.roles,
      name: newUserInfo.name,
      email: newUserInfo.email,
      avatarUrl: newUserInfo.avatarUrl,
    });

    const idListAccount = data.user?.accounts?.data?.map(({ id }: { id: string }) => id);
    setAccountIdList(idListAccount);

    const idListCell = data.user?.cells?.data?.map(({ id }: { id: string }) => id);
    setCellIdList(idListCell);
  }, [data]);

  useLayoutEffect(() => {
    if (!userInfo) return;

    form.setFieldsValue({
      status: userInfo.status,
      roles: userInfo.roles,
      name: userInfo.name,
      email: userInfo.email,
      avatarUrl: userInfo.avatarUrl,
    });
  }, [userInfo, form]);

  //----------------- Save, delete, edit functions -----------------------------

  async function doSave(
    id: number | string,
    name: string,
    email: string,
    password: string,
    roles: UserRole[],
    status: string,
  ) {
    try {
      await UpdateUser({ variables: { id, name, email, password, roles, status } });

      notification.open({ type: 'success', message: 'Alterações salvas.' });
      refetch({ id: user });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then((values) => {
        doSave(user ?? '', values.name, values.email, values.password, values.roles, values.status);

        form.resetFields();
      })
      .catch((info) => {
        notification.open({ type: 'error', ...errorMessage('form_validation', info) });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('', errorInfo) });
  };

  async function Delete(id: string, type: 'cell' | 'account') {
    try {
      if (type === 'cell') await DetachUserFromCell({ variables: { cell: id, user, type: 'Cell' } });
      if (type === 'account') await DetachUserFromAccount({ variables: { user, account: id, type: 'Account' } });
      notification.open({ type: 'success', message: 'Removido' });
      refetch({ id: user });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  async function AttachTo(id: string, type: 'cell' | 'account') {
    try {
      if (type === 'cell') await AttachUserToCell({ variables: { cell: id, user, type: 'Cell' } });
      if (type === 'account') await AttachUserToAccount({ variables: { user, account: id, type: 'Account' } });
      refetch({ id: user });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  //---------------------------------------------------------------------

  if (loading) return <ErrorAndLoading loading={loading} />;
  if (error) return <ErrorAndLoading error={error} />;

  const accounts = data?.user?.accounts.data;
  const cells = data?.user?.cells.data;
  const roles = data?.user?.roles;

  return (
    <>
      <Card className={styles.root} style={{ marginBottom: '16px' }}>
        <div className={styles.div_avatar}>
          <div id="avatar_user" className={styles.avatar}>
            <UploadAvatar
              id={user ?? ''}
              query={users_info}
              params={{ variables: { id: user } }}
              extractData={(data) => data?.user?.avatarUrl}
              radius="50%"
              target="User"
            />
          </div>

          <div className={styles.form}>
            <Typography.Title style={{ fontSize: '20px' }}>Informações do usuário</Typography.Title>

            <Form
              form={form}
              labelCol={{
                span: 3,
                style: {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  minWidth: 160,
                },
              }}
              wrapperCol={{ style: { display: 'flex', flex: 1 } }}
              layout="horizontal"
              name="user_cadaster"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{
                roles: userInfo?.roles || [],
                status: userInfo?.status || '',
                name: userInfo?.name || '',
                email: userInfo?.email || '',
              }}>
              <div id="role_user">
                <Form.Item name="roles" label="Tipo de usuário">
                  <Select mode="multiple">
                    <Select.Option value="admin">Administrador</Select.Option>

                    <Select.OptGroup label="Equipe">
                      <Select.Option value="customer_success">Sucesso de cliente</Select.Option>
                      <Select.Option value="media_tester">Media tester</Select.Option>
                      <Select.Option value="creator">Criação</Select.Option>
                    </Select.OptGroup>

                    <Select.OptGroup label="Externo">
                      <Select.Option value="customer">Cliente</Select.Option>
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              </div>

              <div id="status_user">
                <Form.Item name="status" label="Status">
                  <Select
                    value={form.getFieldValue('status')}
                    options={[
                      { value: 'active', label: 'Usuário ativo' },
                      { value: 'inactive', label: 'Usuário inativo' },
                    ]}
                  />
                </Form.Item>
              </div>

              <div id="name_user">
                <Form.Item name="name" label="Nome">
                  <Input type="text" />
                </Form.Item>
              </div>

              <div id="email_user">
                <Form.Item name="email" label="E-mail">
                  <Input type="email" />
                </Form.Item>
              </div>

              <div id="password_user">
                <Form.Item
                  name="password"
                  label="Senha"
                  hasFeedback
                  rules={[
                    { message: 'Informe a nova senha' },
                    { min: 8, message: 'A senha precisa conter ao menos 8 caracteres' },
                  ]}>
                  <Input.Password type="text" />
                </Form.Item>
              </div>

              <div id="confirm_password_user">
                <Form.Item
                  name="confirm_password"
                  hasFeedback
                  dependencies={['password']}
                  rules={[
                    { message: 'Confirme a nova senha' },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Senhas não coincidem');
                      },
                    }),
                  ]}
                  label="Confirme a senha">
                  <Input.Password type="text" />
                </Form.Item>
              </div>

              <Form.Item noStyle>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="primary" htmlType="reset" onClick={() => navigate(-1)}>
                    Voltar
                  </Button>

                  <Button id="save_user" type="primary" htmlType="submit" loading={loading} style={{ marginLeft: 6 }}>
                    Salvar
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Card>

      {(roles.includes('customer_success') || roles.includes('media_tester') || roles.includes('creator')) && (
        <ListWithAddTo
          title="Células"
          button="Adicionar a uma célula"
          buttonId="add_cell_user"
          helpTitle="Células"
          helpDescription="São células que o usuário faz parte. Assim o usuário tem atividades referente a célula que pertence."
          idList={cellIdList}
          query={cells_select}
          extractData={(data) => {
            const selectListInitial = data.me.cells.data.map(({ id, name }: { id: string; name: string }) => {
              if (cellIdList.indexOf(id) === -1) return { value: id, label: name };
              return null;
            });

            setAddCell(selectListInitial.filter((e: any) => e !== null).length > 0);
            return data.me.cells.data;
          }}
          AttachAtoB={(a, b) => {
            AttachTo(a || '', 'cell');
          }}
          items={cells}
          renderItem={(item, idx) => (
            <List.Item
              id="open_cell_user"
              key={item.id}
              onClick={() => navigate(route('admin.cells.edit', { cell: item.id }))}
              className={styles.item}
              actions={[
                userInfo?.roles.includes('admin') ? (
                  <></>
                ) : (
                  <Popover
                    destroyTooltipOnHide={{ keepParent: false }}
                    content={
                      <>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setConfirmCell(undefined);
                          }}>
                          Não
                        </Button>
                        <Button
                          style={{ marginLeft: 6 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setConfirmCell(undefined);
                            Delete(item.id, 'cell');
                          }}>
                          Sim
                        </Button>
                      </>
                    }
                    title="Remover desta célula?"
                    trigger="click"
                    visible={confirmCell === idx}
                    onVisibleChange={() => setConfirmCell(idx)}>
                    <Button id="delete_user_cell" onClick={(e) => e.stopPropagation()}>
                      Remover
                    </Button>
                  </Popover>
                ),
                <Button
                  id="open_cell_user_button"
                  onClick={() => navigate(route('admin.cells.edit', { cell: item.id }))}>
                  Visualizar
                </Button>,
              ]}>
              <List.Item.Meta
                avatar={<AvatarPicture pictureLink={item.logoUrl} target="Cell" size={40} name={item.name} />}
                title={item.name}
                description={`${item.accounts.total} clientes`}
              />
            </List.Item>
          )}
        />
      )}

      {roles.includes('customer') && (
        <ListWithAddTo
          title="Clientes"
          button="Adicionar cliente"
          buttonId="add_account_user"
          helpTitle="Cliente"
          helpDescription="São clientes que o usuário tem acesso."
          idList={accountIdList}
          query={accounts_select}
          extractData={(data) => {
            const selectListInitial = data.me.accounts.data.map(({ id, name }: { id: string; name: string }) => {
              if (accountIdList.indexOf(id) === -1) return { value: id, label: name };
              return null;
            });

            setAddAccount(selectListInitial.filter((e: any) => e !== null).length > 0);
            return data.me.accounts.data;
          }}
          AttachAtoB={(a, b) => {
            AttachTo(a || '', 'account');
          }}
          items={accounts}
          renderItem={(item, idx) => (
            <List.Item
              id="open_account_user"
              key={item.id}
              onClick={() => navigate(route('admin.accounts.edit', { account: item.id }))}
              className={styles.item}
              actions={[
                userInfo?.roles.includes('admin') ? (
                  <></>
                ) : (
                  <Popover
                    destroyTooltipOnHide={{ keepParent: false }}
                    content={
                      <>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setConfirmAccount(undefined);
                          }}>
                          Não
                        </Button>
                        <Button
                          style={{ marginLeft: 6 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setConfirmAccount(undefined);
                            Delete(item.id, 'account');
                          }}>
                          Sim
                        </Button>
                      </>
                    }
                    title="Remover deste cliente?"
                    trigger="click"
                    visible={confirmAccount === idx}
                    onVisibleChange={() => setConfirmAccount(idx)}>
                    <Button id="delete_account_user" onClick={(e) => e.stopPropagation()}>
                      Remover
                    </Button>
                  </Popover>
                ),
                <Button
                  id="open_account_user_button"
                  onClick={() => navigate(route('admin.accounts.edit', { account: item.id }))}>
                  Visualizar
                </Button>,
              ]}>
              <List.Item.Meta
                avatar={<AvatarPicture pictureLink={item.logoUrl} target="Account" size={40} name={item.name} />}
                title={item.name}
                description={
                  item.status === 'inactive'
                    ? 'Cliente inativo'
                    : item.status === 'active'
                    ? 'Cliente ativo'
                    : item.status === 'suspended'
                    ? 'Cliente suspenso'
                    : ''
                }
              />
            </List.Item>
          )}
        />
      )}
      <TutorialButton
        tourId="021_EditUserManagement"
        top={80}
        right={20}
        placement="left"
        title="Tutorial do usuário"
      />

      <TutorialEditUser
        hasAccount={accounts.length > 0}
        hasCell={cells.length > 0}
        role={roles}
        addAccountButton={addAccount}
        addCellButton={addCell}
      />
    </>
  );
};

export default EditUser;
