import React, { useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Drawer, notification, Popconfirm, Button, Typography } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';

import { DeleteOutlined, SyncOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { route } from '@router';
import { delete_lead, lead_info } from '@logic/queries';
import errorMessage from '@logic/functions/errorHandeling';
import useCloseFn from '@logic/functions/closeDrawer';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import TutorialButton from '@comp/TutorialButton';
import LeadInfo from './LeadInfo';
import TutorialInfoDrawer from './TutorialInfoDrawer';
import { sources } from '../constants';
import styles from './styles.module.less';
import moment from 'moment';

interface Props {
  leadId: string;
}

interface LeadTitleProps {
  leadName: string;
  leadSource: string;
  leadSubscribedAt: string;
  onDelete: () => void;
}

const LeadTitle: React.FC<LeadTitleProps> = ({ leadName, leadSource, leadSubscribedAt, onDelete }) => {
  const source = sources[leadSource];

  return (
    <div className={styles.leadtitlecontainer}>
      <div className={styles.text}>
        {!!source && leadSource !== 'none' ? (
          <div id="lead_title">
            <Typography.Text ellipsis className={styles.leadtitle}>
              {React.cloneElement(source.icon, { color: 'inherit' })} <span>{leadName}</span>
            </Typography.Text>
            <Typography.Text ellipsis className={styles.leaddescription}>
              <span>
                Lead via {source.label} cadastrado em&nbsp;
                {moment.utc(leadSubscribedAt, 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/YYYY [às] HH:mm')}
              </span>
            </Typography.Text>
          </div>
        ) : (
          <Typography.Text ellipsis className={styles.leadtitle} id="lead_title">
            <span>{leadName}</span>
          </Typography.Text>
        )}
      </div>
      <div className={styles.deletecontainer}>
        <Popconfirm
          overlayStyle={{ width: 300 }}
          title="Tem certeza que deseja excluir a Lead deste cliente?"
          onConfirm={() => onDelete()}
          okText="Sim"
          cancelText="Não">
          <Button style={{ marginLeft: 8, padding: 0 }} type="text" icon={<DeleteOutlined id="delete_info" />} />
        </Popconfirm>
      </div>
    </div>
  );
};

const LeadDrawer: React.FC<Props> = ({ leadId }) => {
  const { id: paramAccountId } = useParams<any>();
  const navigate = useNavigate();

  const accountId = paramAccountId;

  //----------------- Read lead ------------------------------

  const [fetchLead, { data, loading, error }] = useLazyQuery(lead_info, { fetchPolicy: 'cache-and-network' });

  //----------------- Delete lead ---------------------------

  const [DeleteLead] = useMutation(delete_lead, { variables: { id: leadId } });

  const Delete = useCallback(() => {
    (async () => {
      try {
        await DeleteLead();

        notification.open({
          placement: 'bottomRight',
          duration: 2,
          type: 'success',
          message: 'Lead do cliente excluída',
        });

        navigate(route('id.home.leads', { id: accountId }));
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    })();
  }, [DeleteLead, navigate, accountId]);

  const doCloseDrawer = useCallback(() => navigate(route('id.home.leads', { id: accountId })), [accountId, navigate]);

  useEffect(() => {
    fetchLead({ variables: { accountId, leadId } });
  }, [leadId, accountId, fetchLead]);

  useCloseFn(doCloseDrawer, 'leadListItem');

  //------------------------------------------------------------------------------

  return (
    <Drawer
      visible
      forceRender
      destroyOnClose
      mask={false}
      width={'800px'}
      onClose={doCloseDrawer}
      closeIcon={loading ? <SyncOutlined spin style={{ color: '#ff5333' }} /> : <CloseCircleOutlined />}
      title={
        <LeadTitle
          leadName={data?.lead?.name}
          leadSource={data?.lead?.source}
          leadSubscribedAt={data?.lead?.subscribed_at}
          onDelete={Delete}
        />
      }
      bodyStyle={{ height: '100vh', paddingBottom: 0, paddingTop: 0 }}>
      {error && <ErrorAndLoading error={error} />}
      {data && <LeadInfo leadId={leadId} accountId={accountId ?? ''} />}

      <TutorialButton
        tourId="013_InfoDrawer"
        circleSize="small"
        iconSize={14}
        bottom={20}
        right={40}
        placement="left"
        title="Tutorial para editar informações úteis"
      />

      <TutorialInfoDrawer isNew={false} />
    </Drawer>
  );
};

export default LeadDrawer;
