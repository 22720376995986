import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { Input, notification, Button, Card } from 'antd';

import TutorialButton from '@comp/TutorialButton';
import GoogleAnalyticsAccountsList from './GoogleAnalyticsAccounts';
import FacebookAccountsList from './FacebookAccounts';
import TutorialRealmManagement from './TutorialRealmManagement';

import { update_realm } from '@logic/mutations';
import { realm } from '@logic/queries';
import errorMessage from '@logic/functions/errorHandeling';

const RealmPage: React.FC = () => {
  const [realmName, setRealmName] = useState('');
  const { t } = useTranslation('realmPage');

  const { data, loading, refetch } = useQuery(realm, { fetchPolicy: 'network-only' });

  const [UpdateTemplate] = useMutation(update_realm, { variables: { name: realmName } });

  useEffect(() => {
    if (!data) return;

    setRealmName(data.me?.realm?.name);
  }, [data]);

  //-------------- create -----------------------

  async function doUpdate() {
    try {
      await UpdateTemplate({ variables: { name: realmName } });

      notification.open({ type: 'success', message: t('message_success') });

      refetch();
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRealmName(event?.target?.value);
  }

  return (
    <>
      <Card loading={loading}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
          <Input
            id="realm_name"
            value={realmName}
            onChange={handleChange}
            placeholder={t('placeholder')}
            onKeyPress={({ key }) => {
              if (key === 'Enter') {
                doUpdate();
              }
            }}
          />
          <Button id="save_realm_name" style={{ marginLeft: 6 }} type="primary" onClick={() => doUpdate()}>
            {t('save_button')}
          </Button>
        </div>
      </Card>

      <GoogleAnalyticsAccountsList />

      <FacebookAccountsList />

      <TutorialButton tourId={t('tour_id')} top={80} right={20} placement="left" title={t('tour_title')} />

      <TutorialRealmManagement />
    </>
  );
};

export default RealmPage;
