import React, { useMemo } from 'react';
import { Button } from 'antd';
import { DateTime } from 'luxon';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

//import NewFunctionalities from '@comp/NewFunctionalities';
import { parseDateTime } from '@logic/functions/date';

import { DownloadOutlined } from '@ant-design/icons';

interface Props {
  data: any[];
  view: string;
  period: { begin: string; end: string };
  author: string;
}

const ExportTimers: React.FC<Props> = ({ data, view, period: { begin, end }, author }) => {
  const filename = `${formatFileNameTime(begin)}_${formatFileNameTime(end)} ${DateTime.local().toFormat(
    'dd.LL.yyyy HHmmss',
  )}.xlsx`;

  const timersObject = useMemo(() => getTimersObject(data, view), [data, view]);

  async function saveAsExcel() {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = author;

    // Cria uma worksheet para cada key do objeto com os dados respectivos
    for (const key of Object.keys(timersObject)) {
      const worksheet = workbook.addWorksheet(timersObject[key].label);
      worksheet.columns = columns;

      worksheet.addRows(createExcelRows(timersObject[key].data));
    }

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), filename);
  }

  return (
    <>
      <Button
        id="export_button"
        type="primary"
        title="Exportar relatório de tempos"
        icon={<DownloadOutlined />}
        size="small"
        onClick={saveAsExcel}>
        Exportar
      </Button>

      {/* <div style={{ display: 'inline', alignItems: 'center', paddingTop: 4, paddingRight: 4, paddingLeft: 10 }}>
        <NewFunctionalities
          title="Exportar Timers"
          description={exportTimersDescription}
          configId="20.12.28.95188"
          ribbon={false}
          fixedTooltip={false}
          placement="leftTop"
          primaryColor={false}
        />
      </div> */}
    </>
  );
};

export default ExportTimers;

// Formata o horário para o nome do arquivo
function formatFileNameTime(time: any) {
  return DateTime.fromFormat(time, 'yyyy-LL-dd').toFormat('dd.LL.yyyy');
}

// Formata o horário para a planilha
function formatTime(time: any) {
  return parseDateTime(time).toFormat('dd/LL/yyyy - HH:mm:ss');
}

// Traduz o tipo do usuário
function userTypes(type: 'admin' | 'customer_success' | 'creator' | 'media_tester' | 'customer') {
  switch (type) {
    case 'admin':
      return 'Administrador';
    case 'customer_success':
      return 'Sucesso de cliente';
    case 'creator':
      return 'Criação';
    case 'media_tester':
      return 'Media tester';
    case 'customer':
      return 'Cliente';
  }
}

// Traduz a etapa da atividade
function taskSteps(step: string) {
  switch (step) {
    case 'execution':
      return 'Execução';
    case 'check':
      return 'Revisão';
    case 'approval':
      return 'Aprovação';
    case 'finish':
      return 'Publicação';
    case 'done':
      return 'Concluída';

    default:
      return ' - ';
  }
}

// Cria o objeto com as keys da view atual
function getTimersObject(data: any[], view: string) {
  return data.reduce((acc, curr, idx) => {
    const start = parseDateTime(curr.start);

    let key = '';
    let label = '';
    let photo = '';

    switch (view) {
      case 'date':
        key = start.toFormat('yyyy_LL_dd');
        label = start.toFormat("dd 'de' LLL");
        break;
      case 'user':
        key = curr.userId;
        label = curr.userName;
        photo = curr.userAvatar;
        break;
      case 'user_type':
        key = curr.userType;
        label = userTypes(curr.userType);
        break;
      case 'cell':
        key = curr.cellId;
        label = curr.cellName;
        photo = curr.cellLogo;
        break;
      case 'account':
        key = curr.clientId;
        label = curr.clientName;
        photo = curr.clientLogo;
        break;
    }

    if (!acc[key] || acc[key].data?.length === 0) {
      acc[key] = { data: [], label, photo };
    }

    const accKey = acc[key];

    return { ...acc, [key]: { ...accKey, data: [...accKey.data, curr] } };
  }, {});
}

// Cria um array com as linhas de cada página/folha (sheet)
function createExcelRows(data: any[]) {
  return data.reduce(
    (
      acc,
      {
        id,
        taskId,
        activity,
        step,
        start,
        end,
        diff,
        userId,
        userName,
        userType,
        userAvatar,
        cellId,
        cellName,
        cellLogo,
        clientId,
        clientName,
        clientLogo,
      }: any,
      idx,
    ) => {
      const item = {
        id,
        task_link: `https://lab.idaction.com.br/timers#task/${taskId}/clock_tab`,
        activity,
        step: taskSteps(step),
        end: formatTime(end),
        start: formatTime(start),
        diff,
        user_id: userId,
        user_name: userName,
        user_type: userTypes(userType),
        // userAvatar,
        cell_id: cellId,
        cell_name: cellName,
        // cellLogo,
        client_id: clientId,
        client_name: clientName,
        // clientLogo,
      };

      acc.push(item);

      return acc;
    },
    [],
  );
}

// Nomes, keys e largura de exibição das colunas do arquivo
const columns: any = [
  { header: 'Id do timer', key: 'id', width: 25, hidden: true },
  { header: 'Link da atividade', key: 'task_link', width: 70 },
  { header: 'Título da atividade', key: 'activity', width: 40 },
  { header: 'Etapa da atividade', key: 'step', width: 17 },
  { header: 'Início', key: 'start', width: 20 },
  { header: 'Término', key: 'end', width: 20 },
  { header: 'Tempo', key: 'diff', width: 10 },
  { header: 'Id do usuário', key: 'user_id', width: 25, hidden: true },
  { header: 'Nome do usuário', key: 'user_name', width: 20 },
  { header: 'Tipo de usuário', key: 'user_type', width: 15 },
  //{ header: 'Avatar do usuário', key: 'userAvatar', width: 10 },
  { header: 'Id da célula', key: 'cell_id', width: 25, hidden: true },
  { header: 'Nome da célula', key: 'cell_name', width: 30 },
  //{ header: 'Logo da célula', key: 'cellLogo', width: 10 },
  { header: 'Id do cliente', key: 'client_id', width: 25, hidden: true },
  { header: 'Nome do cliente', key: 'client_name', width: 30 },
  //{ header: 'Logo do cliente', key: 'clientLogo', width: 10 },
];

// const exportTimersDescription =
//   'Aqui você pode exportar os timer para excel. Os timers são exportados de acordo com os filtros aplicados.';
