import React from 'react';
import Icon from '@ant-design/icons';

function icon(props: any) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <g>
        <g>
          <path
            d="M390.979,211c-5.852,0-11.446,1.123-16.579,3.164C369.19,195.075,351.697,181,330.979,181
			c-11.515,0-22.033,4.347-30,11.486c-7.967-7.139-18.485-11.486-30-11.486c-5.257,0-10.307,0.906-15,2.57V135
			c0-24.813-20.187-45-45-45s-45,20.187-45,45v132.365c-7.241-7.241-13.183-13.182-13.183-13.182
			c-16.635-16.636-43.566-17.587-61.276-2.197c-17.797,15.378-20.667,42.181-6.534,61.02c0.16,0.214,0.326,0.423,0.498,0.627
			l66.717,79.681c7.245,8.652,17.699,14.014,26.922,18.744c6.712,3.442,16.856,8.645,16.856,11.946V467c0,24.813,20.187,45,45,45
			h120c24.813,0,45-20.187,45-45v-31c0-5.934,5.698-13.083,11.73-20.651c8.563-10.744,18.27-22.922,18.27-39.349V256
			C435.979,231.187,415.792,211,390.979,211z M394.249,396.651c-8.563,10.744-18.27,22.922-18.27,39.349v31c0,8.271-6.729,15-15,15
			h-120c-8.271,0-15-6.729-15-15v-42.997c0-21.631-19.169-31.462-33.167-38.641c-7.015-3.598-14.268-7.317-17.61-11.309
			l-66.415-79.321c-4.487-6.263-3.486-15.007,2.379-20.075c5.903-5.131,14.872-4.807,20.417,0.738c0,0,36.344,36.345,38.798,38.794
			c9.41,9.392,25.597,2.733,25.597-10.616V135c0-8.271,6.729-15,15-15s15,6.729,15,15v121c0,8.284,6.716,15,15,15s15-6.716,15-15
			v-30c0-8.271,6.729-15,15-15c8.271,0,15,6.729,15,15v30c0,8.284,6.716,15,15,15s15-6.716,15-15v-30c0-8.271,6.729-15,15-15
			s15,6.729,15,15v30c0,8.284,6.716,15,15,15s15-6.716,15-15c0-8.271,6.729-15,15-15s15,6.729,15,15v120h0.001
			C405.979,381.934,400.281,389.083,394.249,396.651z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M210.979,0c-8.284,0-15,6.716-15,15v30c0,8.284,6.716,15,15,15s15-6.716,15-15V15C225.979,6.716,219.263,0,210.979,0z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M292.192,23.787c-5.857-5.858-15.355-5.858-21.213,0L249.767,45c-5.858,5.858-5.858,15.355,0,21.213
			c5.856,5.858,15.355,5.858,21.212,0L292.192,45C298.05,39.142,298.05,29.645,292.192,23.787z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M172.192,45l-21.213-21.213c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213l21.213,21.213
			c5.857,5.858,15.356,5.858,21.213,0C178.05,60.355,178.05,50.858,172.192,45z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M315.979,90h-30c-8.284,0-15,6.716-15,15s6.716,15,15,15h30c8.284,0,15-6.716,15-15S324.263,90,315.979,90z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M135.979,90h-30c-8.284,0-15,6.716-15,15s6.716,15,15,15h30c8.284,0,15-6.716,15-15S144.263,90,135.979,90z" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

function TapIcon(props: any) {
  return <Icon component={icon} {...props} />;
}

export default TapIcon;
