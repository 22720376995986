import { NexusGenEnums, NexusGenFieldTypes } from 'schema';

import TaskEventTaskCreated from './TaskEventTaskCreated';
import TaskEventTypeChanged from './TaskEventTypeChanged';
import TaskEventTitleChanged from './TaskEventTitleChanged';
import TaskEventCellChanged from './TaskEventCellChanged';
import TaskEventBodyChanged from './TaskEventBodyChanged';
import TaskEventPlannedSecondsChanged from './TaskEventPlannedSecondsChanged';
import TaskEventExecutionDateChanged from './TaskEventExecutionDateChanged';
import TaskEventFinishDateChanged from './TaskEventFinishDateChanged';
import TaskEventStepChanged from './TaskEventStepChanged';
import TaskEventAttachUser from './TaskEventAttachUser';
import TaskEventDetachUser from './TaskEventDetachUser';
import TaskEventSubTaskCreated from './TaskEventSubTaskCreated';
import TaskEventSubTaskDeleted from './TaskEventSubTaskDeleted';
import TaskEventNoteCreated from './TaskEventNoteCreated';
import TaskEventNoteUpdated from './TaskEventNoteUpdated';
import TaskEventNoteDeleted from './TaskEventNoteDeleted';
import TaskEventApproveTask from './TaskEventApproveTask';
import TaskEventRejectTask from './TaskEventRejectTask';
import TaskEventUpdateFeedbackStatus from './TaskEventUpdateFeedbackStatus';
import TaskEventUpdateFeedback from './TaskEventUpdateFeedback';
import TaskEventDeleteFeedback from './TaskEventDeleteFeedback';
import TaskEventFileUploaded from './TaskEventFileUploaded';
import TaskEventFileDeleted from './TaskEventFileDeleted';

interface Props {
  action: NexusGenEnums['TaskEventAction'];
  data: NexusGenFieldTypes['TaskEvent'];
}

const TaskEvent: React.FC<Props> = ({ action, data }) => {
  switch (action) {
    case 'create_task':
      return <TaskEventTaskCreated data={data as any} />;
    case 'change_type':
      return <TaskEventTypeChanged data={data as any} />;
    case 'change_title':
      return <TaskEventTitleChanged data={data as any} />;
    case 'change_cell':
      return <TaskEventCellChanged data={data as any} />;
    case 'change_body':
      return <TaskEventBodyChanged data={data as any} />;
    case 'change_planned_seconds':
      return <TaskEventPlannedSecondsChanged data={data as any} />;
    case 'change_execution_date':
      return <TaskEventExecutionDateChanged data={data as any} />;
    case 'change_finish_date':
      return <TaskEventFinishDateChanged data={data as any} />;
    case 'change_step':
      return <TaskEventStepChanged data={data as any} />;
    case 'attach_user':
      return <TaskEventAttachUser data={data as any} />;
    case 'detach_user':
      return <TaskEventDetachUser data={data as any} />;
    case 'create_subtask':
      return <TaskEventSubTaskCreated data={data as any} />;
    case 'delete_subtask':
      return <TaskEventSubTaskDeleted data={data as any} />;
    case 'create_note':
      return <TaskEventNoteCreated data={data as any} />;
    case 'update_note':
      return <TaskEventNoteUpdated data={data as any} />;
    case 'delete_note':
      return <TaskEventNoteDeleted data={data as any} />;
    case 'approve':
      return <TaskEventApproveTask data={data as any} />;
    case 'reject':
      return <TaskEventRejectTask data={data as any} />;
    case 'update_feedback_status':
      return <TaskEventUpdateFeedbackStatus data={data as any} />;
    case 'update_feedback':
      return <TaskEventUpdateFeedback data={data as any} />;
    case 'delete_feedback':
      return <TaskEventDeleteFeedback data={data as any} />;
    case 'upload_file':
      return <TaskEventFileUploaded data={data as any} />;
    case 'delete_file':
      return <TaskEventFileDeleted data={data as any} />;
    default:
      return (
        <div>
          Evento não reconhecido: <b>{action}</b>
        </div>
      );
  }
};

export default TaskEvent;
