import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Card, Button, Popconfirm, notification, Typography, Input } from 'antd';
import moment from 'moment';

import DatePicker from '@comp/antd/DatePicker';
import AvatarPicture from '@comp/AvatarPicture';

import { update_timer, delete_timer } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  id: string;
  taskId: string;
  start_date: string;
  finish_date: string;
  body: string;
  user: any;
  refresh: () => void;
  canEdit: boolean;
}

function strToMoment(date: string) {
  return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local();
}

function momentToStr(time: moment.Moment) {
  return moment(time).utc().format('YYYY-MM-DD HH:mm:ss');
}

function fmtMomentDiff(from: moment.Moment, to: moment.Moment) {
  const diff = from.diff(to);
  const d = moment.duration(diff);

  return Math.floor(d.asHours()).toString().padStart(2, '0') + moment.utc(diff).format(':mm:ss');
}

const EditTimer: React.FC<Props> = (props) => {
  const { refresh, id, finish_date, start_date, body, user, canEdit } = props;

  const start = useMemo(() => strToMoment(start_date), [start_date]);
  const end = useMemo(() => strToMoment(finish_date), [finish_date]);

  const [editing, setEditing] = useState(false);
  const [timer, setTimer] = useState({ start_date: start, finish_date: end, body });

  const { t } = useTranslation('editTimer');

  //--------------------------- MUTATIONS ---------------------------------

  const [UpdateTimer] = useMutation(update_timer);
  const [DeleteTimer] = useMutation(delete_timer, { variables: { id } });

  //---------------------------- FUNCTIONS ----------------------------------

  const save = useMemo(
    () => async (memo_start_date: string, memo_body: string, memo_finish_date: string) => {
      if (!end) {
        notification.open({ type: 'error', message: t('warning_message') });
        return;
      }

      if (end?.isSame(strToMoment(memo_finish_date)) && start?.isSame(strToMoment(memo_start_date))) return;

      try {
        await UpdateTimer({
          variables: {
            id,
            start_date: memo_start_date,
            finish_date: memo_finish_date,
            body: memo_body,
          },
        });

        notification.open({ type: 'success', message: t('success_message') });

        refresh();
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [id, refresh, UpdateTimer, end, start, t],
  );

  function handleChange(key: string, value: moment.Moment | string, onBlur?: boolean) {
    const newTimer = { ...timer, [key]: value };
    setTimer(newTimer);

    if (onBlur) {
      const startDate = momentToStr(newTimer.start_date);
      const finishDate = momentToStr(newTimer.finish_date);

      save(startDate, newTimer.body, finishDate);
    }
  }

  async function Delete(id: string) {
    try {
      await DeleteTimer({ variables: { id } });

      notification.open({ type: 'success', message: t('delete_success') });

      refresh();
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  useEffect(() => {
    setTimer({ start_date: start, finish_date: end, body });
  }, [start, end, body]);

  const onBlurSave = () => {
    if (canEdit) handleChange('', '', true);
  };

  //------------------------------------------------------------------------------

  if (!end) return null;

  return (
    <Card
      id="edit_timer"
      bordered
      hoverable={true}
      className={styles.root}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div
            style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
            //onClick={() => history.push(route(''))} ir para o perfil de quem criou a nota
          >
            <div className={styles.profilePhoto}>
              <AvatarPicture pictureLink={user.avatarUrl} target="User" size={40} name={user.name} />
            </div>
            <div>
              <Typography className={styles.name}>{user.name}</Typography>
              <Typography className={styles.email}>{user.email}</Typography>
            </div>
          </div>

          <div>
            {!body && canEdit && (
              <Button title={t('edit_title')} onClick={() => setEditing(!editing)} style={{ marginRight: '6px' }}>
                {t('edit_button')}
              </Button>
            )}

            {canEdit && (
              <Popconfirm
                title={t('delete_question')}
                onConfirm={() => Delete(id)}
                onCancel={() => {}}
                okText={t('ok_text')}
                cancelText={t('cancel_text')}>
                <Button title={t('delete_title')}>{t('delete_button')}</Button>
              </Popconfirm>
            )}
          </div>
        </div>
      }>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <DatePicker
          bordered={false}
          showSecond={false}
          allowClear={false}
          showTime={{ format: 'HH:mm' }}
          format="HH:mm:ss - DD/MM/YYYY"
          value={timer.start_date}
          onChange={(value: any) => {
            if (canEdit) handleChange('start_date', value);
          }}
          onBlur={onBlurSave}
        />

        {!!finish_date && (
          <DatePicker
            bordered={false}
            showSecond={false}
            allowClear={false}
            showTime={{ format: 'HH:mm' }}
            format="HH:mm:ss - DD/MM/YYYY"
            value={timer.finish_date}
            onChange={(value: any) => {
              if (canEdit) handleChange('finish_date', value);
            }}
            onBlur={onBlurSave}
          />
        )}
        {!!finish_date && <Typography.Text>{fmtMomentDiff(end, start)}</Typography.Text>}
      </div>

      {(editing || body) && (
        <Input.TextArea
          placeholder={t('description_placeholder')}
          autoSize={{ minRows: 1, maxRows: 20 }}
          readOnly={!canEdit || !editing}
          style={{ border: 'none' }}
          value={timer.body}
          onChange={({ target }) => handleChange('body', target.value)}
          onBlur={onBlurSave}
        />
      )}
    </Card>
  );
};

export default EditTimer;

const bodyStyle: React.CSSProperties = {
  flex: 1,
  paddingBottom: '12px',
  paddingLeft: '12px',
  paddingRight: '12px',
};

const headStyle: React.CSSProperties = {
  padding: 16,
};
