import React, { useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, List, notification, Button, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import moment from 'moment';

import { ArrowRightOutlined, ClockCircleOutlined } from '@ant-design/icons';

import Clock from '@comp/UserTimer/Clock';
import AvatarPicture from '@comp/AvatarPicture';
import DatePicker from '@comp/antd/DatePicker';

import { update_timer } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  id: string;
  task: string;
  activity: string;
  userName: string;
  userAvatar: string;
  showUserAvatar: boolean;
  showAccountLogo: boolean;
  client: string;
  clientId: string; // Unused
  clientLogo: string;
  start: string;
  end?: string;
  showDate?: boolean;
  refresh: () => void;
}

function strToMoment(date: string) {
  return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local();
}

function momentToStr(time: moment.Moment) {
  return moment(time).utc().format('YYYY-MM-DD HH:mm:ss');
}

function fmtMomentDiff(from: moment.Moment, to: moment.Moment) {
  const diff = from.diff(to);
  const d = moment.duration(diff);

  return Math.floor(d.asHours()).toString().padStart(2, '0') + moment.utc(diff).format(':mm:ss');
}

const TimerListItem: React.FC<Props> = (props) => {
  const {
    id,
    activity,
    task,
    userName,
    userAvatar,
    showUserAvatar,
    client,
    clientLogo,
    showAccountLogo,
    start,
    end,
    showDate,
    refresh,
  } = props;
  const startDate = useMemo(() => strToMoment(start), [start]);
  const endDate = useMemo(() => (end ? strToMoment(end) : undefined), [end]);

  const [dateTime, setDateTime] = useState<any>({ start: startDate, end: endDate });

  const navigate = useNavigate();
  const location = useLocation();

  //----------------------- MUTATIONS -----------------------------

  const [updateTimer] = useMutation(update_timer);

  //---------------------------------------------------------------

  const save = useMemo(
    () => async (start_date: string, finish_date?: string) => {
      try {
        await updateTimer({ variables: { id, start_date, finish_date } });

        notification.open({ type: 'success', message: 'Editado com sucesso.' });

        refresh();
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [id, refresh, updateTimer],
  );

  function handleChange(key: string, value: moment.Moment) {
    const newDateTime = { ...dateTime, [key]: value };
    setDateTime(newDateTime);

    const startDate = momentToStr(newDateTime.start);
    const finishDate = newDateTime.end ? momentToStr(newDateTime.end) : undefined;

    save(startDate, finishDate);
  }

  return (
    <div id="item_timers">
      <List.Item
        id="drawerException"
        onClick={() => {
          if (!!task) navigate(location.pathname + '#task/' + task + '/clock_tab');
        }}
        className={styles.item}
        style={{
          padding: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          cursor: 'pointer',
          alignItems: 'center',
        }}>
        {showUserAvatar && (
          <Tooltip destroyTooltipOnHide={{ keepParent: false }} placement="left" mouseEnterDelay={0.3} title={userName}>
            <div style={{ cursor: 'pointer', marginLeft: 6 }}>
              <AvatarPicture pictureLink={userAvatar} target="User" size={24} name={userName} />
            </div>
          </Tooltip>
        )}

        {task ? (
          <Button
            style={{ marginLeft: 6 }}
            type="primary"
            shape="circle"
            size="small"
            onClick={() => navigate(location.pathname + '#task/' + task + '/clock_tab')}>
            <ArrowRightOutlined />
          </Button>
        ) : (
          <Button className={styles.blue_button} type="primary" shape="circle" size="small">
            <ClockCircleOutlined />
          </Button>
        )}

        {showAccountLogo && (
          <Tooltip destroyTooltipOnHide={{ keepParent: false }} placement="left" mouseEnterDelay={0.3} title={client}>
            <div style={{ cursor: 'pointer', marginLeft: 8 }}>
              <AvatarPicture pictureLink={clientLogo} target="Account" size={24} name={client} />
            </div>
          </Tooltip>
        )}

        <Typography.Text ellipsis style={{ flex: 1, marginLeft: 8 }}>
          {activity}
        </Typography.Text>

        {showDate && (
          <Typography.Text ellipsis style={{ width: 100, marginLeft: 8, marginRight: 8 }}>
            {startDate.format('DD/MM/yyyy')}
          </Typography.Text>
        )}

        <div onClick={(event) => event.stopPropagation()}>
          <DatePicker
            style={{ width: 120 }}
            bordered={false}
            showTime
            allowClear={false}
            suffixIcon={<ClockCircleOutlined />}
            format="HH:mm:ss"
            value={dateTime.start}
            onClick={(event) => event.stopPropagation()}
            onChange={(value: any) => handleChange('start', value)}
          />
        </div>

        {end ? (
          <div onClick={(event) => event.stopPropagation()}>
            <DatePicker
              style={{ width: 120 }}
              bordered={false}
              showTime
              allowClear={false}
              suffixIcon={<ClockCircleOutlined />}
              format="HH:mm:ss"
              value={dateTime.end}
              onClick={(event) => event.stopPropagation()}
              onChange={(value: any) => handleChange('end', value)}
            />
          </div>
        ) : (
          <div style={{ width: 100 }} />
        )}

        <Typography.Text style={{ width: 100, fontWeight: 600 }}>
          {!endDate ? <Clock time={momentToStr(startDate)} notWhite /> : fmtMomentDiff(endDate, startDate)}
        </Typography.Text>
      </List.Item>
    </div>
  );
};

export default TimerListItem;
