import { NexusGenFieldTypes } from 'schema';

import TaskEvent from './TaskEvent';
import FromTo from './FromTo';

interface Props {
  data: NexusGenFieldTypes['TaskEventTitleChanged'];
}

const TaskEventTitleChanged: React.FC<Props> = ({ data }) => {
  return (
    <TaskEvent data={data}>
      alterou o título de{' '}
      <FromTo from={data.old_title ? `"${data.old_title}"` : null} to={data.new_title ? `"${data.new_title}"` : null} />
    </TaskEvent>
  );
};

export default TaskEventTitleChanged;
