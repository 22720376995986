import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Input, Button, notification } from 'antd';

import { create_task } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';
import useConfig from '@logic/config';

interface Props {
  accountId: string;
  step: string;
  type: string;
  refetch: () => void;
}

// Input no final da lista para criar uma nova atividade

const NewActivity: React.FC<Props> = ({ accountId, step, type, refetch }) => {
  const [newTask, setNewTask] = useState('');

  const config = useConfig();

  const { t } = useTranslation('newActivity');

  //------------------------Mutations---------------------------------------

  const [CreateTask] = useMutation(create_task);

  //------------------------------------------------------------------------

  const cleanPeriodFilter = () => {
    config.setConfig('tasks_filter_begin', undefined);
    config.setConfig('tasks_filter_end', undefined);
  };

  async function doCreate() {
    try {
      await CreateTask({ variables: { account: accountId, title: newTask, step, type } });

      setNewTask('');

      cleanPeriodFilter();

      //refetch();
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNewTask(event?.target?.value);
  }

  return (
    <>
      <Input
        style={{ border: 'none', backgroundColor: 'transparent' }}
        placeholder={t('placeholder')}
        value={newTask}
        onChange={handleChange}
        onKeyPress={({ key }) => {
          if (key === 'Enter') {
            doCreate();
          }
        }}
      />
      <Button onClick={() => doCreate()}>{t('button_create')}</Button>
    </>
  );
};

export default NewActivity;
