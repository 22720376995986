import { gql } from '@apollo/client';

export const my_tasks_list = gql`
  query Tasks(
    $begin: Date
    $end: Date
    $limit: Int
    $page: Int
    $skip: Int
    $types: [TaskType!]
    $steps: [TaskStep!]
    $templates: [ID!]
    $periodSelector: PeriodSelector
    $excludeSubtasks: Boolean
    $excludeAllExceptTasksWithPendingFeedbacks: Boolean
  ) {
    me {
      id
      tasks(
        pagination: { limit: $limit, page: $page, skip: $skip }
        filter: {
          period: { begin: $begin, end: $end }
          periodSelector: $periodSelector
          types: $types
          steps: $steps
          templates: $templates
          excludeSubtasks: $excludeSubtasks
          excludeAllExceptTasksWithPendingFeedbacks: $excludeAllExceptTasksWithPendingFeedbacks
        }
      ) {
        total
        data {
          id
          title
          step
          type
          finish_date

          ... on DefaultTask {
            execution_date
            has_problem
          }
          cell_id
          users {
            id
            name
            avatarUrl
          }
          account_id
        }
      }
    }
  }
`;
export const my_current_task_list = gql`
  query Tasks(
    $begin: Date
    $end: Date
    $limit: Int
    $page: Int
    $steps: [TaskStep!]
    $periodSelector: PeriodSelector
    $excludeSubtasks: Boolean
    $excludeAllExceptTasksWithPendingFeedbacks: Boolean
  ) {
    me {
      id
      tasks(
        pagination: { limit: $limit, page: $page }
        filter: {
          period: { begin: $begin, end: $end }
          periodSelector: $periodSelector
          steps: $steps
          excludeSubtasks: $excludeSubtasks
          excludeAllExceptTasksWithPendingFeedbacks: $excludeAllExceptTasksWithPendingFeedbacks
        }
      ) {
        total
        data {
          id
          title
          step
          type
          finish_date

          ... on DefaultTask {
            execution_date
            has_problem
          }
          cell_id
          users {
            id
            name
            avatarUrl
          }
          account_id
        }
      }
    }
  }
`;
export const realm_tasks_list = gql`
  query Tasks(
    $begin: Date
    $end: Date
    $limit: Int
    $page: Int
    $skip: Int
    $types: [TaskType!]
    $steps: [TaskStep!]
    $templates: [ID!]
    $users: [ID!]
    $cells: [ID!]
    $periodSelector: PeriodSelector
    $excludeSubtasks: Boolean
    $excludeAllExceptTasksWithPendingFeedbacks: Boolean
  ) {
    me {
      realm {
        id
        tasks(
          pagination: { limit: $limit, page: $page, skip: $skip }
          filter: {
            period: { begin: $begin, end: $end }
            periodSelector: $periodSelector
            types: $types
            steps: $steps
            users: $users
            cells: $cells
            templates: $templates
            excludeSubtasks: $excludeSubtasks
            excludeAllExceptTasksWithPendingFeedbacks: $excludeAllExceptTasksWithPendingFeedbacks
          }
        ) {
          total
          data {
            id
            title
            step
            type
            finish_date

            ... on DefaultTask {
              execution_date
              has_problem
            }
            cell_id
            users {
              id
              name
              avatarUrl
            }
            account_id
          }
        }
      }
    }
  }
`;
export const selected_account_tasks_list = gql`
  query Tasks(
    $id: ID!
    $begin: Date
    $end: Date
    $limit: Int
    $page: Int
    $skip: Int
    $types: [TaskType!]
    $steps: [TaskStep!]
    $templates: [ID!]
    $users: [ID!]
    $periodSelector: PeriodSelector
    $excludeSubtasks: Boolean
    $excludeAllExceptTasksWithPendingFeedbacks: Boolean
  ) {
    account(id: $id) {
      id
      name
      tasks(
        pagination: { limit: $limit, page: $page, skip: $skip }
        filter: {
          period: { begin: $begin, end: $end }
          periodSelector: $periodSelector
          types: $types
          steps: $steps
          users: $users
          templates: $templates
          excludeSubtasks: $excludeSubtasks
          excludeAllExceptTasksWithPendingFeedbacks: $excludeAllExceptTasksWithPendingFeedbacks
        }
      ) {
        total
        data {
          id
          title
          step
          type
          finish_date

          ... on DefaultTask {
            execution_date
            has_problem
          }
          cell_id
          users {
            id
            name
            avatarUrl
          }
          account_id
        }
      }
    }
  }
`;

export const fetch_account_name_and_logo = gql`
  query Account($id: ID!) {
    account(id: $id) {
      id
      name
      logoUrl
      cells(pagination: { page: 1, limit: -1 }) {
        data {
          id
          members(pagination: { page: 1, limit: -1 }) {
            total
            data {
              id
              name
              avatarUrl
            }
          }
        }
      }
    }
  }
`;

export const realm_cells_and_users = gql`
  query Realm {
    me {
      id
      realm {
        id
        cells(pagination: { page: 1, limit: -1 }) {
          data {
            id
            name
            logoUrl
          }
        }
        users(pagination: { page: 1, limit: -1 }) {
          data {
            id
            name
            avatarUrl
            status
          }
        }
      }
    }
  }
`;

export const task_info = gql`
  query Task($id: ID!, $account_timers_total_seconds_begin: Date, $account_timers_total_seconds_end: Date) {
    task(id: $id) {
      id
      title
      body
      step
      type
      planned_seconds
      timers_total_seconds
      parent {
        id
        title
        parent {
          id
          title
        }
      }
      notes {
        total
      }
      finish_date
      ... on DefaultTask {
        execution_date
      }
      cell_id
      users {
        id
      }
      account {
        id
        name
        monthly_planned_seconds
        timers_total_seconds(
          period: { begin: $account_timers_total_seconds_begin, end: $account_timers_total_seconds_end }
        )
      }
    }
  }
`;
