import { NexusGenFieldTypes } from 'schema';

import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventBodyChanged'];
}

const TaskEventBodyChanged: React.FC<Props> = ({ data }) => {
  return <TaskEvent data={data}>alterou a descrição</TaskEvent>;
};

export default TaskEventBodyChanged;
