import React from 'react';
import { Col, Row } from 'antd';

import styles from "./styles.module.less";
import { classifications } from '@pages/Leads/constants';

interface LeadClassificationProps {
  selected?: 'none' | 'no_contact' | 'has_profile' | 'no_profile' | 'closed';
  onSelect: (selectedSelect: string) => void;
};

const LeadClassification: React.FC<LeadClassificationProps> = ({ selected, onSelect }) => {
  const classificationsList = Object.entries(classifications).filter(([c]) => c !== 'none');

  const handleSelect = (classification: string) => {
    if (selected === classification) return onSelect('');
    onSelect(classification);
  };

  return (
    <Row gutter={[8, 16]} className={styles.classificationcontainer}>
      {classificationsList.map(([key, classification]: [any, any]) => {
        const isSelected = selected === key;

        return (
          <Col span={6} key={key}>
            <div 
              className={styles.classificationcontent} 
              role="button"
              style={{ backgroundColor: isSelected ? classification.color : '#ffffff' }}
              onClick={() => handleSelect(key)}
            >
              {React.cloneElement(
                classification.icon, 
                { color: !isSelected ? classification.color : '#ffffff', size: 24 }
              )}
              <span style={{ color: !isSelected ? 'inherit' : '#ffffff' }}>{classification.name}</span>
            </div>
          </Col>
        )}
      )}
    </Row>
  )
};

export default LeadClassification;
