import { Progress } from 'antd';
import React from 'react';

import styles from './styles.module.less';

interface Props {
  value: number;
}

const MeanPerformance: React.FC<Props> = ({ value }) => {
  return (
    <div className={styles.root}>
      <Progress
        type="circle"
        width={120}
        percent={value}
        strokeColor="#33ff6c"
        trailColor="rgba(0, 0, 0, 0.5)"
        format={() => {
          return <span className={styles.percent_value}>{value}%</span>;
        }}
      />

      <div className={styles.text_explanation}>
        Nota definida a partir do tempo usado, metas batidas e atividades concluídas
      </div>
    </div>
  );
};

export default MeanPerformance;
