import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Typography } from 'antd';
import { Duration, DateTime } from 'luxon';

import { RightOutlined } from '@ant-design/icons';

import ErrorAndLoading from '@comp/ErrorAndLoading';

import useConfig from '@logic/config';

interface ItemRenderProps {
  id: string;
  level: number;
}

// COMPONENTE RECURSIVO

const ItemRender: React.FC<ItemRenderProps> = ({ id, level }) => {
  const [collapsed, setCollapsed] = useState(true);

  const config = useConfig();

  const { end, begin } = {
    begin: config.reports_timers_filter_begin || DateTime.local().startOf('month').toFormat('yyyy-LL-dd'),
    end: config.reports_timers_filter_end || DateTime.local().endOf('month').toFormat('yyyy-LL-dd'),
  };
  const { data: items, loading, error } = useQuery(template_item, { variables: { id, begin, end } });

  const formattedItem = {
    id: items?.templateItem?.id,
    title: items?.templateItem?.title,

    tasks: items?.templateItem?.timers_report?.tasks,
    total_seconds: items?.templateItem?.timers_report?.total_seconds,
    min_seconds: items?.templateItem?.timers_report?.min_seconds,
    max_seconds: items?.templateItem?.timers_report?.max_seconds,
    average_seconds: items?.templateItem?.timers_report?.average_seconds,
    planned_seconds: items?.templateItem?.planned_seconds,

    children: (items?.templateItem?.subitems?.data || []).map(({ id }: any) => id),
  };

  const handleCollapse = (e: any) => {
    e.stopPropagation();
    if (formattedItem.children.length > 0) setCollapsed((c: boolean) => !c);
  };

  if (loading)
    return (
      <tr
        style={{ marginLeft: level * 12, backgroundColor: collapsed ? undefined : '#e9e9e9' }}
        onClick={handleCollapse}>
        <td colSpan={6} style={{ borderRight: 'none' }} onClick={(e: any) => e.stopPropagation()}>
          <ErrorAndLoading loading borderless />
        </td>
      </tr>
    );
  if (error)
    return (
      <tr
        style={{ marginLeft: level * 12, backgroundColor: collapsed ? undefined : '#e9e9e9' }}
        onClick={handleCollapse}>
        <td colSpan={6} style={{ borderRight: 'none' }} onClick={(e: any) => e.stopPropagation()}>
          <ErrorAndLoading error={error} borderless />
        </td>
      </tr>
    );

  return (
    <>
      <tr
        style={{ marginLeft: level * 12, backgroundColor: collapsed ? undefined : '#e9e9e9' }}
        onClick={handleCollapse}>
        <td style={{ textAlign: 'left' }}>
          {formattedItem.children.length > 0 && (
            <RightOutlined rotate={collapsed ? 0 : 90} style={{ marginLeft: 6, fontSize: 12 }} />
          )}
          <Typography.Text style={{ flex: 2, marginLeft: 6, fontSize: 16 }}>{formattedItem.title}</Typography.Text>
        </td>
        <td width={80}>
          <Typography.Text>
            {!!formattedItem.min_seconds
              ? Duration.fromMillis(formattedItem.min_seconds * 1000).toFormat('hh:mm:ss')
              : '--:--:--'}
          </Typography.Text>
        </td>
        <td width={80}>
          <Typography.Text>
            {!!formattedItem.max_seconds
              ? Duration.fromMillis(formattedItem.max_seconds * 1000).toFormat('hh:mm:ss')
              : '--:--:--'}
          </Typography.Text>
        </td>
        <td width={80}>
          <Typography.Text>
            {!!formattedItem.average_seconds
              ? Duration.fromMillis(formattedItem.average_seconds * 1000).toFormat('hh:mm:ss')
              : '--:--:--'}
          </Typography.Text>
        </td>
        <td width={80}>
          <Typography.Text>
            {!!formattedItem.planned_seconds
              ? Duration.fromMillis(formattedItem.planned_seconds * 1000).toFormat('hh:mm:ss')
              : '--:--:--'}
          </Typography.Text>
        </td>
        <td width={80}>
          <Typography.Text>{formattedItem.tasks}</Typography.Text>
        </td>
      </tr>
      {!collapsed && formattedItem.children.length > 0 && (
        <tr onClick={(e: any) => e.stopPropagation()}>
          <td
            colSpan={6}
            style={{ borderRight: 'none', padding: '4px 0 4px 20px' }}
            onClick={(e: any) => e.stopPropagation()}>
            <table style={{ width: '100%', margin: 0, borderRight: 'none' }}>
              <tr onClick={(e: any) => e.stopPropagation()}>
                <td>
                  <Typography.Text style={{ fontWeight: 600 }}>Atividade</Typography.Text>
                </td>
                <td width={80}>
                  <Typography.Text style={{ fontWeight: 600 }}>Mínimo</Typography.Text>
                </td>
                <td width={80}>
                  <Typography.Text style={{ fontWeight: 600 }}>Máximo</Typography.Text>
                </td>
                <td width={80}>
                  <Typography.Text style={{ fontWeight: 600 }}>Médio</Typography.Text>
                </td>
                <td width={80}>
                  <Typography.Text style={{ fontWeight: 600 }}>Planejado</Typography.Text>
                </td>
                <td width={80} style={{ borderRight: 'none' }}>
                  <Typography.Text style={{ fontWeight: 600 }}>Atividades</Typography.Text>
                </td>
              </tr>
              {formattedItem.children.map((item: string) => (
                <ItemRender key={`item_${item}`} id={item} level={level + 1} />
              ))}
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default ItemRender;

const template_item = gql`
  query TemplateItem($id: ID!, $begin: Date, $end: Date) {
    templateItem(id: $id) {
      id
      title
      planned_seconds
      timers_report(period: { begin: $begin, end: $end }) {
        tasks
        total_seconds
        min_seconds
        max_seconds
        average_seconds
      }
      subitems(pagination: { limit: -1, page: 1 }, filter: { excludeSubitems: true }) {
        data {
          id
        }
      }
    }
  }
`;
