import React, { useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Typography } from 'antd';
import moment from 'moment';

import { CloseCircleOutlined } from '@ant-design/icons';

import ActivityAccountLogo from '../../ActivitiesLists/ActivitiesList/ActivityListItem/ActivityAccountLogo';
import ActivityStep from '@pages/Activities/ActivitiesLists/ActivitiesList/ActivityListItem/ActivityStep';
import ActivityCheckbox from '@pages/Activities/ActivitiesLists/ActivitiesList/ActivityListItem/ActivityCheckbox';
import { stepsKeys } from '../../';

import { useStore } from '@logic/context';

import styles from './styles.module.less';

interface DateCellInterface {
  accountIdTutorial: string;
  data: any[];
  date: moment.Moment;
  setSelected: (id: string, param?: string) => void;
  showAccountLogo: boolean;
  canCreateTask: boolean;
}

// Conteúdo dentro da data no calendário, lista de atividades expansível

export const DateCell: React.FC<DateCellInterface> = observer(
  ({ accountIdTutorial, data, date, setSelected, showAccountLogo, canCreateTask }) => {
    const store = useStore();
    const isTourOpen = !!store.tour && store.tour === '005_ActivitiesCalendar' ? true : false;

    const { t } = useTranslation('activitiesCalendar');

    // Verifica se a data pertence a semana do dia que está sendo visualizado na forma expandida
    const viewAll = useMemo(
      () => (!!store.calendarDate ? date.isSame(store.calendarDate, 'week') : false),
      [date, store.calendarDate],
    );

    const hasMoreItems = data.length > 5;

    const newTaskWithDate = () => {
      if (canCreateTask) setSelected('new', `${date.format('YYYY-MM-DD')}`);
    };

    // Organiza as atividades por etapa priorizando as atividades com problema
    const sortedData = useMemo(() => mapOrder(data, referenceStep), [data]);

    return (
      <div
        id={date.isoWeekday() === 2 ? 'item_activities_calendar' : undefined}
        onDoubleClick={newTaskWithDate}
        className={styles.date_div}
        style={{ height: '100%' }}>
        <Droppable droppableId={date.format('YYYY-MM-DD')}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={{ height: '100%' }}>
              {isTourOpen &&
                date.isSame(moment(), 'day') &&
                tutorialData.map((item: any, idx) => {
                  return (
                    <Draggable draggableId={`tutorial_${idx}`} key={`tutorial_${idx}`} index={0}>
                      {(provided) => (
                        <div
                          onClick={(e) => e.stopPropagation()}
                          id="drawerException"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <DateCellItem
                            key={item.key}
                            taskId={item.taskId}
                            taskStep={item.taskStep}
                            taskType={item.taskType}
                            taskTitle={item.taskTitle}
                            hasProblem={false}
                            accountId={accountIdTutorial}
                            setSelected={setSelected}
                            showAccountLogo={showAccountLogo}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {!isTourOpen &&
                (hasMoreItems && !viewAll ? sortedData.slice(0, 4) : sortedData).map((item: any, idx) => {
                  return (
                    <Draggable draggableId={item.id} key={`${item.id}_${idx}`} index={idx}>
                      {(provided) => (
                        <div
                          onClick={(e) => e.stopPropagation()}
                          id="drawerException"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <DateCellItem
                            key={item.id}
                            taskId={item.id}
                            taskStep={item.step}
                            taskType={item.type}
                            taskTitle={item.content}
                            hasProblem={item.problem}
                            accountId={item.account}
                            setSelected={setSelected}
                            showAccountLogo={showAccountLogo}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}

              {provided.placeholder}

              {hasMoreItems && !isTourOpen && (
                <div
                  id="see_more_activities_calendar"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 6,
                    paddingBottom: 6,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    store.setCalendarDate(viewAll ? undefined : date);
                  }}>
                  <Typography.Text underline style={{ fontSize: 12, color: 'rgba(0,0,0,0.60)', textAlign: 'center' }}>
                    {t('view')} {viewAll ? t('less') : t('more')}
                    {!viewAll && ` ${data.length - 4}`}
                  </Typography.Text>
                </div>
              )}
            </div>
          )}
        </Droppable>
      </div>
    );
  },
);

interface DateCellItemProps {
  taskId: string;
  taskStep: string;
  taskType: 'default' | 'assignment';
  taskTitle: string;
  hasProblem: boolean;
  accountId: string;
  setSelected: (id: string) => void;
  showAccountLogo: boolean;
}

export const DateCellItem: React.FC<DateCellItemProps> = ({
  taskId,
  taskStep,
  taskType,
  taskTitle,
  hasProblem,
  accountId,
  setSelected,
  showAccountLogo,
}: DateCellItemProps) => {
  const index = stepsKeys.findIndex((s) => s.key === taskStep);
  const accountLogo = showAccountLogo && !!accountId;

  return (
    <div
      id="drag_drop_activities_calendar"
      onClick={() => {
        if (['tutorial01', 'tutorial02', 'tutorial03', 'tutorial04', 'tutorial05'].includes(taskId)) {
          return;
        }
        setSelected(taskId);
      }}
      className={styles.activity}
      style={{
        //backgroundColor: hasProblem ? '#B9375E' : stepsKeys[index].color,
        //backgroundColor: hasProblem ? '#E05780' : stepsKeys[index].color,
        //backgroundColor: hasProblem ? '#f07167' : stepsKeys[index].color,
        backgroundColor: hasProblem ? '#FF2362' : stepsKeys[index].color,
        paddingLeft: accountLogo ? undefined : 4,
      }}>
      <div className={styles.activity_checkbox}>
        <ActivityCheckbox taskId={taskId} style={{ marginLeft: 4 }} />
      </div>

      {accountLogo && (
        <div className={styles.activity_client_logo}>
          <ActivityAccountLogo accountId={accountId} />
        </div>
      )}

      <div id="step_activities_calendar" className={styles.icon} style={{ marginLeft: accountLogo ? -4 : -2 }}>
        {hasProblem ? (
          <CloseCircleOutlined style={{ marginLeft: 8, marginRight: 10 }} />
        ) : (
          <ActivityStep taskId={taskId} taskStep={taskStep} taskType={taskType} whiteText />
        )}
      </div>
      <div id="title_activities_calendar" className={styles.task_title}>
        {taskTitle}
      </div>
    </div>
  );
};

const referenceStep = ['execution', 'check', 'approval', 'finish', 'done'];

function mapOrder(array: any[], order: any[]) {
  const sortedTitles = array.sort((a, b) => {
    if (a.content > b.content) return 1;
    if (a.content < b.content) return -1;
    return 0;
  });

  const sortedSteps = sortedTitles.sort((a, b) => {
    if (a.problem || b.problem) {
      if (a.problem && !b.problem) return -1;
      if (!a.problem && b.problem) return 1;
    } else {
      if (order.indexOf(a.step) > order.indexOf(b.step)) return 1;
      if (order.indexOf(a.step) < order.indexOf(b.step)) return -1;
    }
    return 0;
  });

  // const sortedProblem = sortedSteps.sort((a: any, b: any) => {
  //   if (a.problem && !b.problem) return -1;
  //   if (!a.problem && b.problem) return 1;
  //   return 0;
  // });

  return sortedSteps;
}

const tutorialData = [
  {
    key: 'tutorial01',
    taskId: 'tutorial01',
    taskStep: 'execution',
    taskType: 'default',
    taskTitle: 'Título da atividade 01',
  },
  {
    key: 'tutorial02',
    taskId: 'tutorial02',
    taskStep: 'check',
    taskType: 'default',
    taskTitle: 'Título da atividade 02',
  },
  {
    key: 'tutorial03',
    taskId: 'tutorial03',
    taskStep: 'approval',
    taskType: 'default',
    taskTitle: 'Título da atividade 03',
  },
  {
    key: 'tutorial04',
    taskId: 'tutorial04',
    taskStep: 'finish',
    taskType: 'default',
    taskTitle: 'Título da atividade 04',
  },
  {
    key: 'tutorial05',
    taskId: 'tutorial05',
    taskStep: 'done',
    taskType: 'default',
    taskTitle: 'Título da atividade 05',
  },
];
