import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';
import { useTranslation } from 'react-i18next';

import { AlertOutlined, DeleteOutlined, ReloadOutlined, SoundOutlined, SwapOutlined } from '@ant-design/icons';
import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  hasAssignment: boolean;
  hasDefault: boolean;
}

const TutorialActivitiesList: React.FC<Props> = ({ hasAssignment, hasDefault }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '004_ActivitiesList' ? true : false;

  const { t } = useTranslation('tutorialActivitiesList');

  const steps: any[] = [
    {
      selector: '[id="change_step_icon_0"]',
      content: t('change_step_icon'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="change_item_task_title_0"]',
      content: t('change_item_task_title'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="change_item_responsibles_0"]',
      content: t('change_item_responsibles'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="change_item_date_0"]',
      content: () => {
        return (
          <div style={{ marginLeft: 10 }}>
            <Typography className={styles.text}>{t('change_item_date')}</Typography>

            <Typography className={styles.item}>
              {<ReloadOutlined className={styles.item_orange} />} {<SoundOutlined className={styles.item_orange} />}
              {t('date_orange')}
            </Typography>
            <Typography className={styles.item}>
              {<ReloadOutlined className={styles.gray} />} {<SoundOutlined className={styles.gray} />}
              {t('date_gray')}
            </Typography>
            <Typography className={styles.item}>
              {<ReloadOutlined className={styles.black} />} {<SoundOutlined className={styles.black} />}
              {t('date_black')}
            </Typography>

            <Typography className={styles.text}>{t('change_date_action')}</Typography>

            <Typography className={styles.orange}>
              {<AlertOutlined />}
              {t('warning')}
            </Typography>

            <Typography className={styles.attention_text}>{t('change_date_warning')}</Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="change_item_options_0"]',
      content: () => {
        return (
          <div>
            <Typography className={styles.text}>{t('change_item_options')}</Typography>

            <Typography className={styles.item}>
              {<SwapOutlined className={styles.icon} />} {t('options_convert')}
            </Typography>

            <Typography className={styles.item}>
              {<DeleteOutlined className={styles.item_orange} />}
              {t('options_delete')}
            </Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
  ].filter((step) => !!step);

  const ids: any[] = [
    'change_step_icon_0',
    'change_item_task_title_0',
    'change_item_responsibles_0',
    'change_item_date_0',
    'change_item_options_0',
  ];

  return (
    <Tour
      startAt={0}
      onRequestClose={() => store.setTour(undefined)}
      steps={steps}
      isOpen={isTourOpen}
      disableFocusLock
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialActivitiesList);
