import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { FaFacebook } from 'react-icons/fa';

import styles from './styles.module.less';
import useWindowSize from '@logic/functions/windowSize';
import StatsBlock from '@comp/StatsBlock';

//Exibe o header da tela de relatórios do Facebook
//em clientes > estratégico > relatórios > facebook

interface Props {
  insights: any;
  previous: any;
}

const Header: React.FC<Props> = ({ insights, previous }) => {
  const { width } = useWindowSize();

  const data = {
    leads: {
      goal: 0,
      enrollment: 0,
    },
    leads_before: {
      goal: 0,
      enrollment: 0,
    },
    costs: {
      per_registration: 0,
      per_deal: 0,
      earned_customers: 0,
    },
    costs_before: {
      per_registration: 0,
      per_deal: 0,
      earned_customers: 0,
    },
    investment: {
      value: insights.reduce((acc: number, i: any) => acc + i['spend'], 0),
    },
    investment_before: {
      value: previous.reduce((acc: number, i: any) => acc + i['spend'], 0),
    },
  };

  //Cálculo de aumento percentual e se teve aumento ou não
  //Custo por registro
  const registrationHigherValue = data.costs.per_registration > data.costs_before.per_registration;
  const registrationlPercent = parseFloat(
    (
      ((data.costs.per_registration - data.costs_before.per_registration) * 100) /
      data.costs_before.per_registration
    ).toFixed(2),
  );

  //Custo por fechamento
  const dealHigherValue = data.costs.per_deal > data.costs_before.per_deal;
  const dealPercent = parseFloat(
    (((data.costs.per_deal - data.costs_before.per_deal) * 100) / data.costs_before.per_deal).toFixed(2),
  );

  //Clientes fechados
  const customersHigherValue = data.costs.earned_customers > data.costs_before.earned_customers;
  const customersPercent = parseFloat(
    (
      ((data.costs.earned_customers - data.costs_before.earned_customers) * 100) /
      data.costs_before.earned_customers
    ).toFixed(2),
  );

  //Valor total investido
  const investmentHigherValue = data.investment.value > data.investment_before.value;
  const investmentPercent = parseFloat(
    (((data.investment.value - data.investment_before.value) * 100) / data.investment_before.value).toFixed(2),
  );

  //Leads: objetivo
  const goalHigherValue = data.leads.goal > data.leads_before.goal;
  const goalPercent = parseFloat(
    (((data.leads.goal - data.leads_before.goal) * 100) / data.leads_before.goal).toFixed(2),
  );

  //Cadastro
  const enrollmentHigherValue = data.leads.enrollment > data.leads_before.enrollment;
  const enrollmentPercent = parseFloat(
    (((data.leads.enrollment - data.leads_before.enrollment) * 100) / data.leads_before.enrollment).toFixed(2),
  );

  const higherValueText = 'maior que o período anterior';
  const lowerValueText = 'menor que o período anterior';

  return (
    <Card style={cardStyle} bodyStyle={cardBodyStyle}>
      <Row style={{ margin: 0, display: 'flex', width: '100%' }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={3}
          className={styles.fb_collumn}
          style={{
            borderBottom: width < 1600 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined,
            borderRight: width > 1600 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined,
          }}>
          <div className={styles.facebook}>
            <FaFacebook className={styles.facebook_logo} />
            <Typography.Text className={styles.facebook_title}>Facebook</Typography.Text>
          </div>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={4}
          className={styles.new_collumn}
          style={{
            borderRight: '1px solid rgba(106, 107, 108, 0.2)',
            borderBottom: width < 1600 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined,
          }}>
          <StatsBlock
            title="CUSTO POR CADASTRO"
            value={data.costs.per_registration}
            format="currency"
            up={registrationHigherValue}
            text={
              registrationHigherValue
                ? `${registrationlPercent}% ` + higherValueText
                : `${registrationlPercent * -1}% ` + lowerValueText
            }
          />
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={4}
          className={styles.new_collumn}
          style={{
            borderRight: width < 1200 ? undefined : '1px solid rgba(106, 107, 108, 0.2)',
            borderBottom: width < 1600 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined,
          }}>
          <StatsBlock
            title="CUSTO POR FECHAMENTO"
            value={data.costs.per_deal}
            format="currency"
            up={dealHigherValue}
            text={dealHigherValue ? `${dealPercent}% ` + higherValueText : `${dealPercent * -1}% ` + lowerValueText}
          />
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={3}
          className={styles.new_collumn}
          style={{
            borderRight: width < 1600 && width > 1200 ? undefined : '1px solid rgba(106, 107, 108, 0.2)',
            borderBottom: width < 1600 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined,
          }}>
          <StatsBlock
            title="CLIENTES FECHADOS"
            value={data.costs.earned_customers}
            up={customersHigherValue}
            text={
              customersHigherValue
                ? `${customersPercent}% ` + higherValueText
                : `${customersPercent * -1}% ` + lowerValueText
            }
          />
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={4}
          className={styles.new_collumn}
          style={{
            borderRight: width < 1200 ? undefined : '1px solid rgba(106, 107, 108, 0.2)',
            borderBottom: width < 1200 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined,
          }}>
          <StatsBlock
            title="VALOR TOTAL INVESTIDO"
            value={data.investment.value}
            format="currency"
            up={investmentHigherValue}
            text={
              investmentHigherValue
                ? `${investmentPercent}% ` + higherValueText
                : `${investmentPercent * -1}%` + lowerValueText
            }
          />
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={3}
          className={styles.new_collumn}
          style={{
            borderRight: '1px solid rgba(106, 107, 108, 0.2)',
          }}>
          <StatsBlock
            title="OBJETIVO"
            value={data.leads.goal}
            up={goalHigherValue}
            text={goalHigherValue ? `${goalPercent}% ` + higherValueText : `${goalPercent * -1}% ` + lowerValueText}
          />
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={3} className={styles.new_collumn}>
          <StatsBlock
            title="CADASTRO"
            value={data.leads.enrollment}
            up={enrollmentHigherValue}
            text={
              enrollmentHigherValue
                ? `${enrollmentPercent}% ` + higherValueText
                : `${enrollmentPercent * -1}% ` + lowerValueText
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Header;

const cardStyle = { borderRadius: 4, overflow: 'hidden', boxShadow: '0 1px 4px #00000040' };
const cardBodyStyle = { display: 'flex', alignItems: 'center', padding: 0, minHeight: 140 };
