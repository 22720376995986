import { NexusGenFieldTypes } from 'schema';
import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventNoteCreated'];
}

const TaskEventNoteCreated: React.FC<Props> = ({ data }) => {
  return <TaskEvent data={data}>criou uma nota</TaskEvent>;
};

export default TaskEventNoteCreated;
