import { NexusGenFieldTypes } from 'schema';
import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventDeleteFeedback'];
}

const TaskEventDeleteFeedback: React.FC<Props> = ({ data }) => {
  return (
    <TaskEvent data={data}>
      deletou o feedback criado por <b>{data.from_user?.name ?? data.name}</b>
    </TaskEvent>
  );
};

export default TaskEventDeleteFeedback;
