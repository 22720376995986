import { NexusGenFieldTypes } from 'schema';

import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventSubTaskDeleted'];
}

const TaskEventSubTaskDeleted: React.FC<Props> = ({ data }) => {
  return (
    <TaskEvent data={data}>
      deletou uma subtarefa chamada <b>"{data.title}"</b>
    </TaskEvent>
  );
};

export default TaskEventSubTaskDeleted;
