import { NexusGenFieldTypes } from 'schema';

import TaskEvent from './TaskEvent';
import FromTo from './FromTo';

import { parseDate } from '@logic/functions/date';

interface Props {
  data: NexusGenFieldTypes['TaskEventExecutionDateChanged'];
}

const TaskEventExecutionDateChanged: React.FC<Props> = ({ data }) => {
  const from = data.old_execution_date ? parseDate(data.old_execution_date).toFormat('dd/LL/yyyy') : null;
  const to = data.new_execution_date ? parseDate(data.new_execution_date).toFormat('dd/LL/yyyy') : 'em branco';

  return (
    <TaskEvent data={data}>
      alterou a data de execução <FromTo from={from} to={to} />
    </TaskEvent>
  );
};

export default TaskEventExecutionDateChanged;
