import { gql } from '@apollo/client';
import { notification } from 'antd';
import { action, makeObservable, observable } from 'mobx';
import axios, { Canceler } from 'axios';

import Store from '../store';
import { uploadFile, uploadFileAndAttach } from '@logic/upload';

const applyFile = gql`
  mutation AttachFile($target: FileTarget!, $id: ID!, $key: String!, $name: String!) {
    attachFile(target: $target, id: $id, file: { key: $key, name: $name })
  }
`;

export interface UploadItem {
  fileId?: string;
  fileKey?: string;
  fileUrl?: string;
  parentId?: string; //  TODO   FIXME  <<
  targetType: 'Task' | 'Note' | 'AccountInfo' | 'Feedback';
  targetId?: string;
  file: File;
}

export class UploadManager {
  store: Store;

  isUploading: boolean = false;

  uploadList: UploadItem[] = [];

  temporaryFiles: UploadItem[] = [];

  progress: number = 0;

  totalProgress: number = 0;

  totalBytes: number = 0;
  totalLoadedBytes: number = 0;
  canceler: Canceler | null = null;

  constructor(store: Store) {
    makeObservable(this, {
      isUploading: observable,
      uploadList: observable,
      temporaryFiles: observable,
      progress: observable,
      totalProgress: observable,
      uploadFile: action,
      setIsUploading: action,
      setProgress: action,
      setTotalProgress: action,
      setUploadList: action,
      dropUploadItem: action,
      cancelUpload: action,
      addTemporaryFile: action,
      removeTemporaryFile: action,
      removeTemporaryFiles: action,
    });
    this.store = store;
  }

  uploadFile(item: UploadItem) {
    this.totalBytes += item.file.size;

    this.uploadList.push({
      ...item,
      fileId: (Math.random() * Number.MAX_VALUE).toString(36),
    });

    if (this.uploadList.length === 1) this.processUpload();
  }

  setIsUploading(isUploading: boolean = true) {
    this.isUploading = isUploading;
  }

  setProgress(progress: number) {
    this.progress = progress;
  }

  setTotalProgress(progress: number) {
    this.totalProgress = progress;
  }

  setUploadList(list: UploadItem[]) {
    this.uploadList = list;
  }

  getFileIdx(fileId?: string): number {
    if (!fileId) return -1;

    const idx = this.uploadList.findIndex((e) => e.fileId === fileId);
    if (idx === -1) return -1;

    return idx;
  }

  dropUploadItem(fileId?: string) {
    const idx = this.getFileIdx(fileId);
    if (idx === -1) return;

    const newList = [...this.uploadList];
    newList.splice(idx, 1);
    this.uploadList = newList;
  }

  cancelUpload(fileId?: string) {
    const idx = this.getFileIdx(fileId);
    if (idx === -1) return;

    // Se está cancelando o item atual, cancela o axios e reseta as variáveis do upload
    if (idx === 0 && this.canceler) {
      return this.canceler('Upload cancelado.');
    }

    // Se está cancelando um item posterior, remove do total bytes pois não será enviado
    if (idx !== 0) {
      this.totalBytes -= this.uploadList[0].file.size;
    }

    this.dropUploadItem(fileId);
  }

  addTemporaryFile(key: string, url: string, item: UploadItem) {
    this.temporaryFiles.push({ ...item, fileKey: key, fileUrl: url });
  }

  removeTemporaryFile(key: string) {
    const idx = this.temporaryFiles.findIndex((item) => item.fileKey === key);
    if (idx === -1) return;

    this.temporaryFiles.splice(idx, 1);
  }

  removeTemporaryFiles(parent: string) {
    this.temporaryFiles = this.temporaryFiles.filter((file) => file.parentId !== parent);
  }

  async processUpload() {
    // verifica se possui algo para fazer upload e retorna caso não tenha
    if (this.uploadList.length === 0) {
      this.totalLoadedBytes = 0;
      this.totalBytes = 0;
      this.setIsUploading(false);
      notification.open({
        placement: 'bottomRight',
        duration: 2,
        type: 'success',
        message: 'Upload concluído.',
      });
      return;
    }

    this.setProgress(0);
    this.setIsUploading();

    const uploadItem = this.uploadList[0];

    try {
      const cancelToken = new axios.CancelToken((c) => (this.canceler = c));

      const onUploadProgress = (e: any) => {
        if (!e.lengthComputable) return this.setProgress(-1);

        this.setProgress(e.loaded / (e.total || 1));
        this.setTotalProgress((this.totalLoadedBytes + e.loaded) / (this.totalBytes || 1));
      };

      // We have a target, attach it
      if (uploadItem.targetId) {
        await uploadFileAndAttach({
          file: uploadItem.file,
          applyMutation: applyFile,
          applyVariables: { target: uploadItem.targetType, id: uploadItem.targetId, name: uploadItem.file.name },
          onUploadProgress,
          cancelToken,
        });
      } else {
        const { key, url } = await uploadFile({
          file: uploadItem.file,
          onUploadProgress,
          cancelToken,
        });

        this.addTemporaryFile(key, url, uploadItem);
      }
    } catch (err) {
      notification.open({
        placement: 'bottomRight',
        duration: null,
        type: 'error',
        description: (err as Error).message,
        message: `Ocorreu um erro no envio do arquivo ${uploadItem.file.name}`,
      });
    } finally {
      this.canceler = null;
      this.totalLoadedBytes += uploadItem.file.size;

      this.setProgress(0);
      this.dropUploadItem(uploadItem.fileId);
      this.processUpload();
    }
  }
}
