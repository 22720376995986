import React from 'react';

import styles from './styles.module.less';
import { Image, Typography } from 'antd';

interface Props {
  data: any;
}

//Tabela de dados da campanha
//inclui dados a cada linha (a cada campanha)
const AdsTable: React.FC<Props> = ({ data }) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.first_line}>
          <th colSpan={2} className={styles.first_title}>
            Anúncios
          </th>
          <th colSpan={2} className={styles.second_title}>
            Nome do Anúncio
          </th>
          <th className={styles.titles}>Clique (todos)</th>
          <th className={styles.titles}>Alcance</th>
          <th className={styles.titles}>Impressões</th>
          <th className={styles.titles}>Valor gasto</th>
        </tr>
      </thead>

      <tbody>
        {data.map((item: any, index: number) => {
          return (
            <tr className={styles.line} key={`ads_${index}`}>
              <td key={`img_${index}`} className={styles.img} colSpan={2}>
                <Image src={item.img} width={140} alt={`example_${index}`} className={styles.image_picture} />
              </td>

              <td key={`ad_${index}`} className={styles.name} colSpan={2}>
                <Typography.Text
                  className={styles.text_name}
                  style={{ color: 'rgba(118, 131, 143, 1)', fontWeight: 400, width: '100%' }}>
                  {item.name}
                </Typography.Text>
              </td>

              <td key={`clicks_${index}`} className={styles.collumn}>
                {item.clicks}
              </td>

              <td key={`reach_${index}`} className={styles.collumn}>
                {item.reach}
              </td>

              <td key={`impressions_${index}`} className={styles.collumn}>
                {item.impressions}
              </td>

              <td key={`spent_amount_${index}`} className={styles.collumn}>
                {item.spent_amount}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AdsTable;
