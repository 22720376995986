import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  isNew: boolean;
  isWhatsApp: boolean;
}

const TutorialLink: React.FC<Props> = ({ isNew, isWhatsApp }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '015_Link' ? true : false;

  const steps: any[] = [
    ...[
      isNew
        ? {
            selector: '[id="link_whatsapp_default"]',
            content: 'Defina o tipo de link: link padrão ou whatsapp.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
            position: 'left',
          }
        : undefined,
    ],
    ...[
      !isNew
        ? {
            selector: '[id="link_delete"]',
            content: 'Clique para deletar o link.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="link_subdomain"]',
      content: isNew
        ? 'Escreva o subdomínio, normalmente é o nome da empresa. Este é um campo obrigatório.'
        : 'Edite o subdomínio, normalmente é o nome da empresa.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="link_path"]',
      content: isNew
        ? 'Escreva o caminho, ele é um identificador que precisa ser único, ou seja não pode haver outro igual. Este é um campo obrigatório..'
        : 'Edite o caminho, ele é um identificador que precisa ser único, ou seja não pode haver outro igual.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="link_dest"]',
      content: isNew
        ? 'Escreva o destino, ele é link usado para redirecionamento. Este é um campo obrigatório.'
        : 'Edite o destino, ele é link usado para redirecionamento.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      isWhatsApp
        ? {
            selector: '[id="link_title"]',
            content: isNew
              ? 'Escreva o título da conversa de WhatsApp. Este é um campo obrigatório.'
              : 'Edite o título da conversa de WhatsApp.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      isWhatsApp
        ? {
            selector: '[id="link_body"]',
            content: isNew
              ? 'Escreva o texto da conversa de WhatsApp. Este é um campo obrigatório.'
              : 'Edite o texto da conversa de WhatsApp.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      isNew
        ? {
            selector: '[id="link_save_button"]',
            content: 'Após preencher os campos todos os campos obrigatórios, clique em "Salvar"',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    ...[isNew ? 'link_whatsapp_default' : undefined],
    ...[!isNew ? 'link_delete' : undefined],
    'link_subdomain',
    'link_path',
    'link_dest',
    ...[isWhatsApp ? 'link_title' : undefined],
    ...[isWhatsApp ? 'link_body' : undefined],
    ...[isNew ? 'link_save_button' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('ant-tabs-content-holder', 'class_name')}
      onBeforeClose={() => enableScroll('ant-tabs-content-holder', 'class_name')}
    />
  );
};

export default observer(TutorialLink);
