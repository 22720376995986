import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  accountId: boolean;
  accountTemplates: boolean;
  accountTemplateList: boolean;
  globalTemplateList: boolean;
  selectedItems: boolean;
  selectedTemplates: boolean;
}

const TutorialTemplates: React.FC<Props> = ({
  accountId,
  accountTemplates,
  accountTemplateList,
  globalTemplateList,
  selectedItems,
  selectedTemplates,
}) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '011_TemplatePage' ? true : false;
  const showItems = store.showItems;

  const steps: any[] = [
    ...[
      accountId
        ? {
            selector: '[id="account_templates"]',
            content: 'Templates do cliente podem ser utilizados somente nos próprios clientes.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      !accountId
        ? {
            selector: '[id="global_templates"]',
            content:
              'Templates globais podem ser utilizados para todos os clientes. Esses templates e items também podem ser selecionados para serem excluídos, ou criar uma atividade a partir dele.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="create_account_template"]',
      content: !accountTemplates
        ? 'Templates globais podem ser utilizados para todos os clientes. Digite o nome do template global e salve.'
        : 'Você pode criar um novo templante para o cliente. Digite o nome do novo template e salve.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      (accountTemplates && accountTemplateList) || globalTemplateList
        ? {
            selector: '[id="create_template_item"]',
            content: 'Clicando em um template criado, exibe a opção de ver e criar os subitems do template.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      (accountTemplateList || globalTemplateList) && showItems
        ? {
            selector: '[id="show_item-template"]',
            content:
              'Os items dentro do template funcionam como um template. Você pode criar, editar e excluir items também.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      (accountTemplates && accountTemplateList) || globalTemplateList
        ? {
            selector: '[id="select_item"]',
            content: accountTemplates
              ? 'Selecione um item ou template, e os botões de excluir e criar atividade serão exibidos.'
              : 'Selecione um item ou template, e os botões de excluir será exibido.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      (accountTemplateList || globalTemplateList) && (selectedItems || selectedTemplates)
        ? {
            selector: '[id="delete_item"]',
            content: 'Exclua um item ou template .',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      (accountTemplateList || globalTemplateList) && (selectedItems || selectedTemplates)
        ? {
            selector: '[id="delete_item_bottom"]',
            content: 'Neste botão também é possível excluir o template ou item.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      accountId && (accountTemplateList || globalTemplateList) && selectedItems && selectedTemplates
        ? {
            selector: '[id="create_task_template"]',
            content: 'Crie um item ou template.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      accountId && (accountTemplateList || globalTemplateList) && selectedItems && selectedTemplates
        ? {
            selector: '[id="create_task_template_bottom"]',
            content: 'Neste botão também é possível criar uma atividade com esse template.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      accountId
        ? {
            selector: '[id="global_templates"]',
            content:
              'Templates globais podem ser utilizados para todos os clientes. Esses templates e items também podem ser selecionados para serem excluídos, ou criar uma atividade a partir dele.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    ...[accountId ? 'account_templates' : undefined],
    ...[!accountId ? 'global_templates' : undefined],
    'create_account_template',
    ...[(accountTemplates && accountTemplateList) || globalTemplateList ? 'create_template_item' : undefined],
    ...[(accountTemplateList || globalTemplateList) && showItems ? 'show_item-template' : undefined],
    ...[(accountTemplates && accountTemplateList) || globalTemplateList ? 'select_item' : undefined],
    ...[
      (accountTemplateList || globalTemplateList) && (selectedItems || selectedTemplates) ? 'delete_item' : undefined,
    ],
    ...[
      (accountTemplateList || globalTemplateList) && (selectedItems || selectedTemplates)
        ? 'delete_item_bottom'
        : undefined,
    ],
    ...[
      accountId && (accountTemplateList || globalTemplateList) && selectedItems && selectedTemplates
        ? 'create_task_template'
        : undefined,
    ],
    ...[
      accountId && (accountTemplateList || globalTemplateList) && selectedItems && selectedTemplates
        ? 'create_task_template_bottom'
        : undefined,
    ],
    ...[accountId ? 'global_templates' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialTemplates);
