import React from 'react';
import { useParams } from 'react-router-dom';

import EditUser from './EditUser';
import NewUser from './NewUser';

const User: React.FC = () => {
  const { user }= useParams<any>();

  const newUser = user === 'new';

  if (newUser) return <NewUser />;
  else return <EditUser />;
};

export default User;
