import React from 'react';
import { Progress, Typography, Tooltip } from 'antd';

import styles from './styles.module.less';

interface Props {
  type: string;
  value: number;
  text: string;
  color?: string;
  tooltipText?: string;
}
const ProgressBar: React.FC<Props> = ({ type, value, text, color, tooltipText }) => {
  return (
    <>
      {type === 'percent' && (
        <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={tooltipText}>
          <div className={styles.root}>
            <div className={styles.progress}>
              <Typography.Text className={styles.text}>{text}</Typography.Text>

              <Progress
                percent={value}
                strokeColor={color}
                trailColor="rgba(0, 0, 0, 0.35)"
                showInfo={false}
                size="default"
              />
            </div>

            <div className={styles.div_value}>
              <Typography.Text className={styles.value}>{value}%</Typography.Text>
            </div>
          </div>
        </Tooltip>
      )}

      {type === 'late' && (
        <div className={styles.root}>
          <div className={styles.progress}>
            <Typography.Text className={styles.text}>{text}</Typography.Text>
            <Progress
              percent={value}
              strokeColor={color}
              trailColor="rgba(0, 0, 0, 0.5)"
              showInfo={false}
              size="small"
            />
          </div>

          <div className={styles.div_value}>
            <Typography.Text className={styles.value}>{value}</Typography.Text>
          </div>
        </div>
      )}

      {type === 'meanTime' && (
        <div className={styles.root_time}>
          <Typography.Text className={styles.time_text}>{text}</Typography.Text>

          <div className={styles.time_value}>
            <Typography.Text className={styles.value}>{value}</Typography.Text>
            <Typography.Text className={styles.time_unit}> min</Typography.Text>
          </div>
        </div>
      )}
    </>
  );
};

export default ProgressBar;
