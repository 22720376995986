import React from 'react';
import { Card, List } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import Notification from '@comp/Notifications/Notification';

import { notifications_query, total_notifications_query } from '@logic/queries';
import { delete_notification, mark_as_viewed } from '@logic/mutations';

const NotificationsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading, refetch } = useQuery(notifications_query, {
    variables: { page: 1, limit: -1 },
    fetchPolicy: 'network-only',
  });

  useQuery(total_notifications_query, { fetchPolicy: 'network-only' });

  // Marcar notificação como lida
  const [MarAsViewed] = useMutation(mark_as_viewed);
  const handleMarkAsSeen = (id: string) => MarAsViewed({ variables: { id } }).then(() => refetch());

  // Excluir notificação
  const [Delete] = useMutation(delete_notification);
  const handleDelete = (id: string) => Delete({ variables: { id } }).then(() => refetch());

  return (
    <Card>
      <List loading={loading} style={{ backgroundColor: '#fff', width: '100%' }}>
        <List.Item key="header" style={{ padding: '6px 10px', fontSize: 18, fontWeight: 600 }}>
          Últimas notificações
        </List.Item>

        {data?.notifications?.data?.map(
          ({
            id,
            type,
            initiator: { name, avatarUrl },
            step,
            viewed,
            notified_at,
            task: { title, id: taskId },
          }: any) => (
            <List.Item style={{ padding: 0 }} key={`notification_${id}`}>
              <Notification
                id={id}
                initiatorAvatar={avatarUrl}
                initiatorName={name}
                step={step}
                taskId={taskId}
                taskTitle={title}
                notified_at={notified_at}
                type={type}
                viewed={viewed}
                handleMarkAsSeen={handleMarkAsSeen}
                handleDelete={handleDelete}
                handleOpenTask={(id, tab) => navigate(location.pathname + '#task/' + id + tab)}
              />
            </List.Item>
          ),
        )}
      </List>
    </Card>
  );
};

export default NotificationsPage;
