import { Image, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { AlertOutlined } from '@ant-design/icons';
import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import rolesGif from '../../../../../img/roles_user.gif';
import styles from './styles.module.less';

const TutorialNewUser: React.FC = () => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '022_NewUserManagement' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="role_new_user"]',
      content: () => {
        return (
          <div className={styles.avatar_menu}>
            <div className={styles.image_div}>
              <Image
                alt="Roles Dropdown Menu"
                width={300}
                src={rolesGif}
                preview={false}
                className={styles.avatar_menu_gif}></Image>
            </div>

            <Typography>Você pode adicionar ou remover um novo tipo de usuário, clique e veja as opções</Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="status_new_user"]',
      content:
        'O status do usuário pode ser ativo ou inativo. Quando ativo o usuário consegue acessar a IDLab, caso contrário, ele não consegue.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="name_new_user"]',
      content: 'Para adicionar o nome do usuário, digite no campo "Nome".',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="email_new_user"]',
      content: () => {
        return (
          <div style={{ marginLeft: 10 }}>
            <Typography className={styles.text}>No campo "E-mail" insira o e-mail do usuário.</Typography>

            <Typography className={styles.orange}>{<AlertOutlined />} Atenção!</Typography>
            <Typography className={styles.attention_text}>
              O e-mail deve ser único, ou seja, os usuários não podem ter e-mails que já estão sendo utilizados por
              outros usuários na IDLab.
            </Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="password_new_user"]',
      content: 'Adicione uma senha, ela deve ter no mínimo 8 caracteres.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="confirm_password_new_user"]',
      content: 'Confirme a senha, deve ser a mesma digitada no campo senha.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="save_new_user"]',
      content: 'Ao terminar de inserir as informações do novo usuário, clique em "Salvar".',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
  ];

  const ids: any[] = [
    'role_new_user',
    'status_new_user',
    'name_new_user',
    'email_new_user',
    'password_new_user',
    'confirm_password_new_user',
    'save_new_user',
  ];

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialNewUser);
