import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Modal, notification, Popover } from 'antd';

import { delete_link, convert_link } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import { DeleteOutlined, MoreOutlined, SwapOutlined } from '@ant-design/icons';

interface Props {
  linkId: string;
  type: 'default' | 'whatsapp';
  refresh: () => void;
}

const LinkActions: React.FC<Props> = ({ linkId, type, refresh }) => {
  const [DeleteLink] = useMutation(delete_link, { variables: { id: linkId } });

  const [ConvertLink] = useMutation(convert_link, {
    variables: { id: linkId, type: type === 'default' ? 'whatsapp' : 'default' },
  });

  const handleDelete = useCallback(() => {
    Modal.confirm({
      title: 'Excluir tarefa',
      icon: <DeleteOutlined />,
      content: 'Tem certeza que deseja excluir este link?',
      onOk: async () => {
        try {
          await DeleteLink();

          notification.open({
            placement: 'bottomRight',
            duration: 2,
            type: 'success',
            message: 'Link excluído',
          });

          refresh();
        } catch (err) {
          notification.open({ type: 'error', ...errorMessage('graph_err', err) });
        }
      },
      okType: 'danger',
    });
  }, [DeleteLink, refresh]);

  const handleConvert = useCallback(() => {
    Modal.confirm({
      title: 'Converter atividade',
      icon: <SwapOutlined />,
      content:
        type === 'default'
          ? 'Tem certeza que deseja converter este link?'
          : 'Tem certeza que deseja converter este link? O título e a descrição serão removidos.',
      onOk: async () => {
        try {
          await ConvertLink();

          notification.open({
            placement: 'topRight',
            duration: 2,
            type: 'success',
            message: 'Link convertido',
          });

          refresh();
        } catch (err) {
          notification.open({ type: 'error', ...errorMessage('graph_err', err) });
        }
      },
      okType: 'danger',
    });
  }, [ConvertLink, refresh, type]);

  const convertMenu = (
    <div style={{ display: 'flex', flexDirection: 'row', margin: '-6px -10px' }}>
      <Button
        type="default"
        size="small"
        title={`Converter ${type === 'default' ? 'link whatsapp' : 'link padrão'}`}
        icon={<SwapOutlined />}
        onClick={handleConvert}
      />
      <Button
        type="primary"
        size="small"
        title="Excluir"
        style={{ marginLeft: 4 }}
        icon={<DeleteOutlined />}
        onClick={handleDelete}
      />
    </div>
  );

  const stopPropagation = (e: any) => e.stopPropagation();

  return (
    <div style={{ marginRight: 8, cursor: 'pointer' }} onClick={stopPropagation}>
      <Popover destroyTooltipOnHide={{ keepParent: false }} placement="right" trigger={['click']} content={convertMenu}>
        <MoreOutlined id="options_link" />
      </Popover>
    </div>
  );
};

export default LinkActions;
