import { notification } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DatePicker from '@comp/antd/DatePicker';

import { ReloadOutlined, SoundOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

// Claro => Atividade concluiu a etapa respectiva
// Bold => Atividade ainda não concluiu a etapa mas está em dia
// Laranja => Atividade ainda não concluiu a etapa e está atrasada

interface Props {
  taskId: string;
  taskType: 'default' | 'assignment';
  execution?: moment.Moment;
  finish?: moment.Moment;
  step: string;
  index?: number;
}

const update_task_dates = gql`
  mutation UpdateTaskDate($id: ID!, $execution_date: Date, $finish_date: Date) {
    updateTask(id: $id, execution_date: $execution_date, finish_date: $finish_date) {
      id
      finish_date
      ... on DefaultTask {
        execution_date
      }
    }
  }
`;

const ActivityDates: React.FC<Props> = ({ taskId, taskType, finish, execution, step, index }) => {
  const { t } = useTranslation('activityDates');

  //----------------------------------Mutations--------------------------------------------------

  const [UpdateTask] = useMutation(update_task_dates);

  //---------------------------------------------------------------------------------------------

  const save = useCallback(
    async (date: 'finish_date' | 'execution_date', value: string | null) => {
      if ((date === 'finish_date' ? finish : execution)?.isSame(strToMoment(value))) return;

      try {
        await UpdateTask({
          variables: {
            id: taskId,
            [date]: value,
          },
        });
      } catch (err) {
        notification.open({
          type: 'error',
          ...errorMessage('graph_err', err),
        });
      }
    },
    [UpdateTask, taskId, execution, finish],
  );

  return (
    <div id={index === 0 ? `change_item_date_${index}` : undefined} style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        onClick={(event) => event.stopPropagation()}
        className={getDateStyle(step, 'execution', execution)}
        style={{ width: 126, minWidth: 126 }}>
        {taskType === 'default' && (
          <DatePicker
            name="execution"
            format="DD/MM/YYYY"
            placeholder={t('placeholder')}
            bordered={false}
            value={execution || undefined}
            onChange={(value) => save('execution_date', momentToStr(value))}
            suffixIcon={<ReloadOutlined style={{ color: getDateIconColor(step, 'execution', execution) }} />}
          />
        )}
      </div>

      <div
        onClick={(event) => event.stopPropagation()}
        style={{ width: 126, minWidth: 126 }}
        className={getDateStyle(step, 'finish', finish)}>
        <DatePicker
          name="finish"
          format="DD/MM/YYYY"
          placeholder={t('placeholder')}
          bordered={false}
          value={finish || undefined}
          onChange={(value) => save('finish_date', momentToStr(value))}
          suffixIcon={<SoundOutlined style={{ color: getDateIconColor(step, 'finish', finish) }} />}
        />
      </div>
    </div>
  );
};

export default ActivityDates;

const steps = ['execution', 'check', 'approval', 'finish', 'done'];

function strToMoment(date?: string | null) {
  if (!date) return undefined;
  return moment(date, 'YYYY-MM-DD').local();
}

function momentToStr(time?: moment.Moment | null) {
  if (!time) return null;
  return moment(time).format('YYYY-MM-DD');
}

function getDateStyle(status: string, type: 'execution' | 'finish', time?: moment.Moment) {
  if (!time) return styles.disabled;
  let style: any = styles.disabled;

  const indexOfType = steps.indexOf(type);
  const indexOfStatus = steps.indexOf(status);

  if (indexOfType >= indexOfStatus) {
    const isDelayed = moment(time).isBefore(moment());
    style = isDelayed ? styles.delayed_style : undefined;
  }

  return style;
}
function getDateIconColor(status: string, type: 'execution' | 'finish', time?: moment.Moment) {
  if (!time) return '#c1c1c1';
  let color: string = '#c1c1c1';

  const indexOfType = steps.indexOf(type);
  const indexOfStatus = steps.indexOf(status);

  if (indexOfType >= indexOfStatus) {
    const isDelayed = moment(time).isBefore(moment());
    color = isDelayed ? '#ff5333' : 'rgba(0,0,0,0.64)';
  }
  return color;
}
