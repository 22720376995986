import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import { BiBook, BiBookOpen, BiLineChart } from 'react-icons/bi';
import { ClockCircleOutlined, MenuOutlined } from '@ant-design/icons';

import HomeButton from '../HomeButton';
import RightMenu from '../RigthMenu';
import Navigate from '../../Navigate';
import NewVersionButton from '@comp/NewVersionButton';

import { route } from '@router';
import useConfig from '@logic/config';

import styles from './styles.module.less';

// Menu superior para as telas de relatórios

const ReportsAppBar: React.FC = () => {
  const location = useLocation();

  const { t } = useTranslation('reportsAppBar');

  const config = useConfig();
  const collapsed = config.collapsed_menu;

  const checkRoute = useMemo(
    () => (key: string, routeName: string, params?: object) =>
      location.pathname.indexOf(route(routeName, params)) !== -1 ? key : '',
    [location],
  );

  // Itens do menu que estão selecionados
  const selectedKeys: any = [
    checkRoute('time_per_template', 'reports.templates'),
    checkRoute('time_reports', 'reports.time'),
    checkRoute('checking', 'reports.general'),
  ].filter((e) => !!e);

  return (
    <>
      <Menu mode="horizontal" theme="light" selectedKeys={selectedKeys}>
        <Menu.Item key="admin_home" className={styles.item_logo}>
          <NewVersionButton elseRender={<HomeButton />} />
        </Menu.Item>

        <Menu.Item
          id="button_collapsed_menu"
          title={!collapsed ? t('tooltip_collapsed_menu') : t('tooltip_expanded_menu')}
          key="side_menu"
          icon={<MenuOutlined />}
          style={{ padding: 0, marginRight: 16 }}
          onClick={() => {
            config.setConfig('collapsed_menu', !collapsed);
          }}
        />

        <Menu.Item key="checking">
          <Navigate to={route('reports.general')}>
            <span className={styles.menuItem}>
              <BiLineChart style={{ fontSize: '20px', marginRight: '6px' }} />
              <span>{t('checking')}</span>
            </span>
          </Navigate>
        </Menu.Item>

        <Menu.Item key="time_reports">
          <Navigate to={route('reports.time')}>
            <span className={styles.menuItem}>
              <ClockCircleOutlined style={{ fontSize: '18px', marginRight: '6px' }} />
              <span>{t('time_reports')}</span>
            </span>
          </Navigate>
        </Menu.Item>

        <Menu.Item key="time_per_template">
          <Navigate to={route('reports.templates')}>
            <span className={styles.menuItem}>
              {selectedKeys.includes('time_per_template') ? (
                <BiBookOpen style={{ fontSize: '20px', marginRight: '6px' }} />
              ) : (
                <BiBook style={{ fontSize: '20px', marginRight: '6px' }} />
              )}
              <span>{t('time_per_template')}</span>
            </span>
          </Navigate>
        </Menu.Item>
      </Menu>

      <RightMenu />
    </>
  );
};

export default ReportsAppBar;
