import { PropsWithChildren } from 'react';

import { parseDateTime } from '@logic/functions/date';

import AvatarPicture from '@comp/AvatarPicture';
import { Typography } from 'antd';
import { NexusGenFieldTypes } from 'schema';

interface Props {
  data: NexusGenFieldTypes['TaskEvent'];
}

const TaskEvent: React.FC<PropsWithChildren<Props>> = ({ data, children }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div>
        <AvatarPicture
          pictureLink={(data.initiator as any)?.avatarUrl}
          target="User"
          size={40}
          name={data.initiator?.name ?? 'Usuário removido'}
        />
      </div>

      <div style={{ paddingLeft: 12 }}>
        <div style={{ display: 'flex' }}>
          <Typography.Text strong>{data.initiator?.name ?? 'Usuário removido'}</Typography.Text>

          <Typography.Text type="secondary">
            &nbsp;em {parseDateTime(data.created_at).toFormat("dd/LL/yyyy 'às' HH:mm:ss")}
          </Typography.Text>
        </div>

        <div>{children}</div>
      </div>
    </div>
  );
};

export default TaskEvent;
