import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, notification, Popconfirm, Row } from 'antd';
import { useMutation } from '@apollo/client';
//import moment from 'moment';

//import { SoundOutlined, ReloadOutlined } from '@ant-design/icons';

import { useStore } from '@logic/context';
import errorMessage from '@logic/functions/errorHandeling';
import { delete_task } from '@logic/mutations';

interface Props {
  cancelEdit: () => void;
  closeModal: () => void;
}

const EditMultiple: React.FC<Props> = ({ cancelEdit, closeModal }) => {
  //const [{ execution, finish }, setForm] = useState<any>({ finish: '', execution: '' });

  const store = useStore();

  const { t } = useTranslation('editMultiple');

  //--------------------------Mutation----------------------------------

  const [DeleteTask] = useMutation(delete_task);

  //--------------------------------------------------------------------

  // const handleChange = (key: string, value: any) => {
  //   setForm((f: any) => ({ ...f, [key]: value }));
  // };

  // Exclui atividades individualmente =>  TODO  mutation que recebe os IDs para excluir todas juntas
  const handleDelete = async () => {
    for (const task of store.editMultipleList) {
      await DeleteTask({ variables: { id: task } })
        .then(() => store.removeTaskFromMultipleEdit(task))
        .catch((err) => notification.open({ type: 'error', ...errorMessage('graph_err', err) }));
    }
    closeModal();
  };

  return (
    <Modal
      visible
      closable={false}
      bodyStyle={bodyStyle}
      title={t('modal_title')}
      footer={
        <>
          <Button onClick={cancelEdit} type="primary">
            {t('cancel')} {store.editMultipleList.length > 0 ? t('clear_selection') : t('edit')}
          </Button>
          <Button onClick={closeModal}>{t('button_close')}</Button>
        </>
      }>
      <Row style={{ width: '100%' }}>
        <Popconfirm
          title={t('delete_question_part_1') + ` ${store.editMultipleList.length} ` + t('delete_question_part_2')}
          onConfirm={handleDelete}>
          <Button danger block type="primary">
            {t('button_delete_part_1')} {store.editMultipleList.length} {t('button_delete_part_2')}
          </Button>
        </Popconfirm>
      </Row>

      {/*<Row style={{ width: '100%', marginTop: 12 }}>
        <Col span={8}>
          <Typography.Text>Data de execução:</Typography.Text>
        </Col>
        <Col span={16}>
          <DatePicker
            name="execution"
            format="DD/MM/YYYY"
            placeholder="Selecionar"
            value={strToMoment(execution) || undefined}
            onChange={(value) => handleChange('execution_date', momentToStr(value))}
            suffixIcon={<ReloadOutlined />}
          />
        </Col>
      </Row>*/}

      {/*<Row style={{ width: '100%', marginTop: 12 }}>
        <Col span={8}>
          <Typography.Text>Data de publicação:</Typography.Text>
        </Col>
        <Col span={16}>
          <DatePicker
            name="finish"
            format="DD/MM/YYYY"
            placeholder="Selecionar"
            value={strToMoment(finish) || undefined}
            onChange={(value) => handleChange('finish_date', momentToStr(value))}
            suffixIcon={<SoundOutlined />}
          />
        </Col>
      </Row>*/}

      {/*<Row style={{ width: '100%', marginTop: 12, justifyContent: 'flex-end' }}>
        <Button>Salvar</Button>
      </Row>*/}
    </Modal>
  );
};

export default EditMultiple;

const bodyStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

// function strToMoment(date?: string | null) {
//   if (!date) return undefined;
//   return moment(date, 'YYYY-MM-DD').local();
// }

// function momentToStr(time?: moment.Moment | null) {
//   if (!time) return null;
//   return moment(time).format('YYYY-MM-DD');
// }
