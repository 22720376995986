import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, notification, Popconfirm, Button } from 'antd';
import { useMutation } from '@apollo/client';

import { DeleteOutlined, SwapOutlined } from '@ant-design/icons';

import { route } from '@router';
import { convert_link, delete_link } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import NewWhatsappLink from './NewWhatsappLink/index';
import EditWhatsappLink from './EditWhatsappLink/index';

interface Props {
  account?: string;
  link?: any;
  id: string;
  refetch: () => void;
}

const WhatsappLink: React.FC<Props> = ({ account, link, id, refetch }) => {
  const { id: paramAccountId, url: paramLinkId } = useParams<any>();
  const navigate = useNavigate();

  const accountId = account || paramAccountId;
  const linkId = id || paramLinkId;

  const newUrl = linkId === 'new';

  const [DeleteLink] = useMutation(delete_link, { variables: { id: linkId } });

  const [ConvertLink] = useMutation(convert_link, { variables: { id: linkId, type: 'default' } });

  const convertToDefault = useCallback(async () => {
    try {
      await ConvertLink();

      notification.open({
        placement: 'bottomRight',
        duration: 2,
        type: 'success',
        message: 'Link convertido para padrão',
      });

      refetch();
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }, [ConvertLink, refetch]);

  async function Delete() {
    try {
      await DeleteLink();

      notification.open({
        placement: 'bottomRight',
        duration: 2,
        type: 'success',
        message: 'Link excluído',
      });

      navigate(route('id.home.links', { id: accountId }));
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  //------------------------------------------------------------------------------

  return (
    <Tabs
      destroyInactiveTabPane={true}
      tabPosition="top"
      style={{ flexDirection: 'column', width: '100%', height: '100%' }}>
      <Tabs.TabPane tab="Link Whatsapp" key="whatsapp_tab">
        {newUrl && <NewWhatsappLink />}

        {!newUrl && link && <EditWhatsappLink accountId={accountId ?? ''} link={link} />}
      </Tabs.TabPane>

      {!newUrl && (
        <Tabs.TabPane
          active={false}
          disabled
          key="convert_whatsapp_link"
          tab={
            <Popconfirm
              overlayStyle={{ width: 300 }}
              title="Tem certeza que deseja converter este link? O título e a descrição serão removidos."
              onConfirm={convertToDefault}
              okText="Sim"
              cancelText="Não">
              <Button
                style={{ marginLeft: 8, padding: 0 }}
                type="text"
                title="Converter para link padrão"
                icon={<SwapOutlined style={{ marginRight: 8 }} />}
              />
            </Popconfirm>
          }
        />
      )}

      {!newUrl && (
        <Tabs.TabPane
          active={false}
          disabled
          key="delete_whatsapp_link"
          tab={
            <Popconfirm
              overlayStyle={{ width: 300 }}
              title="Tem certeza que deseja excluír este link?"
              onConfirm={() => Delete()}
              okText="Sim"
              cancelText="Não">
              <Button
                id="link_delete"
                title="Excluir link"
                style={{ marginLeft: 8, padding: 0 }}
                type="text"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          }
        />
      )}
    </Tabs>
  );
};

export default WhatsappLink;
