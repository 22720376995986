import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Modal, notification, Popover } from 'antd';

import { account_info_delete } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';

interface Props {
  infoId: string;
  refresh: () => void;
}

const InfoActions: React.FC<Props> = ({ infoId, refresh }) => {
  const [DeleteInfo] = useMutation(account_info_delete, { variables: { id: infoId } });

  const handleDelete = useCallback(() => {
    Modal.confirm({
      title: 'Excluir informação',
      icon: <DeleteOutlined />,
      content: 'Tem certeza que deseja excluir este link?',
      onOk: async () => {
        try {
          await DeleteInfo();

          notification.open({
            placement: 'bottomRight',
            duration: 2,
            type: 'success',
            message: 'Informação excluída',
          });

          refresh();
        } catch (err) {
          notification.open({ type: 'error', ...errorMessage('graph_err', err) });
        }
      },
      okType: 'danger',
    });
  }, [DeleteInfo, refresh]);

  const convertMenu = (
    <div style={{ display: 'flex', flexDirection: 'row', margin: '-6px -10px' }}>
      <Button
        type="primary"
        size="small"
        title="Excluir"
        style={{ marginLeft: 4 }}
        icon={<DeleteOutlined />}
        onClick={handleDelete}
      />
    </div>
  );

  const stopPropagation = (e: any) => e.stopPropagation();

  return (
    <div style={{ marginRight: 8, cursor: 'pointer' }} onClick={stopPropagation}>
      <Popover destroyTooltipOnHide={{ keepParent: false }} placement="right" trigger={['click']} content={convertMenu}>
        <MoreOutlined id="options_info" />
      </Popover>
    </div>
  );
};

export default InfoActions;
