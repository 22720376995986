import React, { useState, useRef, useCallback, useLayoutEffect, useMemo } from 'react';
import { Input, Typography } from 'antd';
import Linkify from 'react-linkify';
import { SmileOutlined, SwapOutlined } from '@ant-design/icons';
import Picker from '@emoji-mart/react';
import EmojiData from '@emoji-mart/data';
import ReactMarkdown from 'react-markdown';

import Help from '@comp/Help';

import styles from './styles.module.less';

interface Props {
  emojiPicker: 'on' | 'off' | 'focus'; // tells if the emoji button will be showing, not showing or showed when the input is on focus
  value?: string;
  id?: string;
  name?: string;
  className?: string;
  multiline?: boolean;
  border?: boolean; // bordered from antd input
  placeholder?: string;
  onChange?: (value: any) => void; // Needs {target: {name: [input_name], value: [input_value], id?: [input_id]}}
  onBlur?: (e: any) => void; // Needs {target: {name: [input_name], value: [input_value]}}
  extraProps?: any; // to pass extra input properties
  disabled?: boolean;
  viewOnly?: boolean;
  md?: boolean;
}

const EmojiLinkInput: React.FC<Props> = ({
  multiline,
  emojiPicker,
  value,
  id,
  name,
  className,
  placeholder,
  onChange,
  onBlur,
  border,
  extraProps,
  disabled,
  viewOnly,
  md,
}) => {
  const ref = useRef<any>();

  const [focus, setFocus] = useState(false);
  const [picker, setPicker] = useState(false);
  const [sel, setSel] = useState(0);

  const handleChange = useCallback(
    ({ target }: any) => (onChange ? onChange({ target: { value: target.value, name, id } }) : {}),
    [onChange, name, id],
  );

  const handleEmojiSelect = useCallback(
    ({ native }: any) => {
      const currRef = ref.current;
      if (!currRef) return;

      const el = multiline ? currRef.resizableTextArea.textArea : currRef.input;
      const selection: number = el.selectionStart || 0;

      const newValue = el.value.slice(0, selection) + native + el.value.slice(selection);
      handleChange({ target: { value: newValue } });

      const newCursorPosition = selection + native.length;
      setTimeout(() => setSel(newCursorPosition), 10);
    },
    [multiline, handleChange],
  );

  const handleClickOutside = useCallback((e: PointerEvent) => {
    if (e.target && (e.target as HTMLElement).getAttribute('data-icon') === 'smile') return;
    setPicker(false);
  }, []);

  useLayoutEffect(() => {
    if (!focus || viewOnly) return;
    const currRef = ref.current;
    if (!currRef) return;

    const el = multiline ? currRef.resizableTextArea.textArea : currRef.input;
    el.setSelectionRange(sel, sel);
    el.focus();
  }, [sel, multiline, focus, viewOnly]);

  const on = emojiPicker === 'on';
  const off = emojiPicker === 'off';
  const isMd = value?.startsWith('md\n');

  const comp = useMemo(
    () =>
      !focus ? null : multiline ? (
        <Input.TextArea
          {...extraProps}
          ref={ref}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          className={className}
          onFocus={() => setFocus(true)}
          style={{
            border: border || on || focus || picker ? undefined : 0,
            width: '100%',
            borderRadius: 4,
            padding: '2px 4px',
            whiteSpace: 'break-spaces',
            cursor: 'text',
          }}
          onChange={(e) =>
            handleChange({ ...e, target: { ...e.target, value: md ? `md\n${e.target.value}` : e.target.value } })
          }
          value={(value || '').replace('md\n', '')}
          autoSize={{ minRows: 1, maxRows: 36 }}
        />
      ) : (
        <Input
          {...extraProps}
          ref={ref}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          className={className}
          onFocus={() => setFocus(true)}
          style={{
            border: on || focus || picker ? undefined : 0,
            width: '100%',
            borderRadius: 4,
            cursor: 'text',
          }}
          onChange={handleChange}
          value={!value ? undefined : value || ''}
        />
      ),
    [focus, multiline, extraProps, name, disabled, placeholder, className, border, on, picker, value, handleChange, md],
  );

  const textComp = useMemo(
    () =>
      isMd ? (
        <div
          className={className}
          style={{
            minHeight: '30px',
            border: border ? '1px solid #c7c7c7' : 0,
            borderRadius: 4,
            padding: '0 6px',
          }}
          onClick={() => setFocus(true)}>
          <ReactMarkdown className={styles.markdown} children={(value || '').replace('md\n', '')} linkTarget="_blank" />
        </div>
      ) : (
        <div
          className={className}
          style={{
            minHeight: '30px',
            border: border ? '1px solid #c7c7c7' : 0,
            borderRadius: 4,
            padding: '0 6px',
            alignItems: 'center',
            whiteSpace: 'break-spaces',
            display: 'flex',
          }}
          onClick={() => setFocus(true)}>
          <Typography.Text
            disabled={!!placeholder && !value}
            style={{ margin: 0, padding: 0, width: '100%', cursor: 'text' }}>
            <Linkify
              ref={ref}
              componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
                <a
                  key={key}
                  href={decoratedHref}
                  style={{ textDecoration: 'underline' }}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  rel="noopener noreferrer">
                  {decoratedText}
                </a>
              )}>
              {!value ? placeholder : value || ''}
            </Linkify>
          </Typography.Text>
        </div>
      ),
    [isMd, className, border, value, placeholder],
  );

  return (
    <div
      onBlur={(e) => {
        if (!picker && onBlur) onBlur({ target: { name, value } });
      }}>
      <div>
        {focus && !viewOnly ? comp : textComp}
        {!off && (focus || on) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '6px',
              padding: '0 2px',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}>
            <SmileOutlined
              id="emoji-icon"
              style={{
                cursor: 'pointer',
                fontSize: '18px',
                marginRight: '8px',
              }}
              onClick={() => setPicker((visible) => !visible)}
            />
            <SwapOutlined
              style={{
                cursor: 'pointer',
                fontSize: '18px',
                marginRight: '8px',
              }}
              onClick={() => setFocus((focus) => !focus)}
            />

            <div style={{ flex: 1 }} />

            {md && (
              <a href="https://commonmark.org/help/" target="_blank" rel="noreferrer">
                <Help
                  title="Ajuda com Markdown"
                  description="Veja como você pode formatar o texto clicando aqui."
                  iconStyle={{ color: '#5c5c5c', fontSize: '18px' }}
                  placement="left"
                />
              </a>
            )}
          </div>
        )}
      </div>

      {picker && (
        <Picker
          data={EmojiData}
          perLine={9}
          defaultSkin={undefined}
          showPreview={false}
          showSkinTones={false}
          onEmojiSelect={handleEmojiSelect}
          onClickOutside={handleClickOutside}
        />
      )}
    </div>
  );
};

export default EmojiLinkInput;
