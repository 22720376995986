function checkPage(target: string, select: 'tag_name' | 'class_name'): Element {
  let page: any = undefined;

  switch (select) {
    case 'tag_name':
      page = document.getElementsByTagName(target)[0];
      break;
    case 'class_name':
      page = document.getElementsByClassName(target)[0];
      break;
  }

  return page;
}

function preventDefault(e: any) {
  if (e.preventDefault) e.preventDefault();
  e.returnValue = false;
}

export function disableScroll(target: string, select: 'tag_name' | 'class_name'): void {
  const page = checkPage(target, select);
  if (page) {
    page.scrollTop = 0;
    //@ts-ignore
    page.addEventListener('wheel', preventDefault, { passive: false });
  }
}

export function enableScroll(target: string, select: 'tag_name' | 'class_name'): void {
  const page = checkPage(target, select);
  if (page) {
    //@ts-ignore
    page.removeEventListener('wheel', preventDefault, { passive: false });
  }
}

export function changeStep(curr: number, ids: any[]): void {
  document.getElementById(ids[curr])?.scrollIntoView({ block: 'center' });
  //if (scrollIntoView(id[curr])) disableScroll(target, select);
}
