import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  hasTask?: boolean;
}

const TutorialNewTask: React.FC<Props> = ({ hasTask }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '009_NewTask' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="type_task"]',
      content: 'Selecione o tipo de atividade.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      !hasTask
        ? {
            selector: '[id="account_task"]',
            content: 'A atividade deve estar vinculada a um cliente. Selecione o cliente.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="any_field_task"]',
      content:
        'O primeiro campo que for preenchido, irá automaticamente criar uma atividade. Por isso é uma opção sua o campo que deseja preencher agora. Após criada a atividade, em seguida é possível realizar a edição e preencher os campos restantes.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
  ].filter((step) => !!step);

  const ids: any[] = ['type_task', ...[!hasTask ? 'account_task' : undefined], 'any_field_task'].filter((id) => !!id);

  return (
    <>
      <Tour
        startAt={0}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => store.setTour(undefined)}
        accentColor="#ff5333"
        maskSpace={4}
        maskClassName={styles.mask}
        rounded={4}
        getCurrentStep={(curr) => changeStep(curr, ids)}
        onAfterOpen={() => disableScroll('ant-tabs-content-holder', 'class_name')}
        onBeforeClose={() => enableScroll('ant-tabs-content-holder', 'class_name')}
      />
    </>
  );
};

export default observer(TutorialNewTask);
