import React from 'react';
import { Divider, Typography } from 'antd';

import SubTemplatesList from './SubTemplatesList';

interface Props {
  templateItemId: string;
  setSelected: (id: string) => void;
  displayOnly?: boolean;
}

const SubTemplates: React.FC<Props> = ({ templateItemId, setSelected, displayOnly }) => {
  return (
    <>
      <Divider />
      <Typography.Text style={{ marginBottom: '16px', fontSize: '16px', fontWeight: 600 }}>Subtarefas</Typography.Text>
      <div style={{ height: 16 }} />

      <SubTemplatesList templateItemId={templateItemId} setSelected={setSelected} displayOnly={displayOnly} />
    </>
  );
};

export default SubTemplates;
