import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  hasUser: boolean;
}

const TutorialUserList: React.FC<Props> = ({ hasUser }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '020_UserListManagement' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="add_new_user"]',
      content: 'Clique aqui para adicionar um novo usuário na sua organização.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      hasUser
        ? {
            selector: '[id="open_user_button"]',
            content: 'Para ver e editar o usuário, clique em "Visualizar".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasUser
        ? {
            selector: '[id="open_user"]',
            content: 'Ou clique sobre o usuário.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasUser
        ? {
            selector: '[id="delete_user"]',
            content: 'Exclua o usuário, clicando em "Excluir".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    'add_new_user',
    ...[hasUser ? 'open_user_button' : undefined],
    ...[hasUser ? 'open_user' : undefined],
    ...[hasUser ? 'delete_user' : undefined],
  ].filter((step) => !!step);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialUserList);
