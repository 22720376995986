import React, { useState, useMemo, useRef, useLayoutEffect } from 'react';
import { useMutation } from '@apollo/client';
import { List, notification, Input, Checkbox, Typography } from 'antd';
import moment from 'moment';

import Clickable from '@comp/Clickable';

import { update_template_item } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  templateItemId: string;
  accountId: string;
  openItem: (id: string) => void;
  setSelection: (value: boolean) => void;
  selected: boolean;
  templateTitle: string;
  displayOnly?: boolean;
}

const TemplateItemListItem: React.FC<Props> = ({
  templateItemId,
  openItem,
  templateTitle,
  setSelection,
  selected,
  displayOnly,
}) => {
  const [title, setTitle] = useState(templateTitle);
  const [focused, setFocused] = useState(false);

  const ref = useRef<any>();

  const id = templateItemId;

  //---------------------- mutations ------------------------------

  const [UpdateTask] = useMutation(update_template_item, { variables: { id, title: title || '' } });

  //--------------Save and Delete functions -----------------------

  const save = useMemo(
    () => async (title: string) => {
      try {
        await UpdateTask({ variables: { id, title } });
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [id, UpdateTask],
  );

  useLayoutEffect(() => {
    if (!ref.current) return;

    ref.current.input.focus();
  }, [ref, focused]);

  function handleChange(key: string, value: moment.Moment | string) {
    setTitle(value.toString());
  }

  return (
    <List.Item
      className={styles.item}
      style={{ padding: 0, display: 'flex', flexDirection: 'row', paddingTop: 4, paddingBottom: 4 }}
      onClick={() => openItem(id)}>
      <Checkbox
        id={id}
        style={{ margin: '0 6px', padding: 0 }}
        checked={selected}
        onChange={({ target }) => {
          setSelection(target.checked);
        }}
        onClick={(e) => e.stopPropagation()}
      />

      <Clickable
        onClick={() => openItem(id)}
        onDbClick={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          height: '30px',
          width: `calc(100% - 34px)`,
        }}>
        {!focused || displayOnly ? (
          <Typography.Text ellipsis style={{ width: '100%', fontSize: 14 }}>
            {title}
          </Typography.Text>
        ) : (
          <Input
            ref={ref}
            value={title}
            onClick={(e) => e.stopPropagation()}
            style={{ border: 'none', width: '100%' }}
            onChange={({ target }) => handleChange('title', target.value)}
            onBlur={() => save(title)}
          />
        )}
      </Clickable>
    </List.Item>
  );
};

export default TemplateItemListItem;
