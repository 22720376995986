import { NexusGenFieldTypes } from 'schema';

import TaskEvent from './TaskEvent';
import FromTo from './FromTo';

interface Props {
  data: NexusGenFieldTypes['TaskEventCellChanged'];
}

const TaskEventCellChanged: React.FC<Props> = ({ data }) => {
  const from = data.old_cell?.name ?? data.old_name;

  return (
    <TaskEvent data={data}>
      alterou a célula <FromTo from={from} to={data.new_cell?.name ?? data.new_name} />
    </TaskEvent>
  );
};

export default TaskEventCellChanged;
