import { NexusGenFieldTypes } from 'schema';
import TaskEvent from './TaskEvent';
import FromTo from './FromTo';

interface Props {
  data: NexusGenFieldTypes['TaskEventUpdateFeedbackStatus'];
}

const TaskEventUpdateFeedbackStatus: React.FC<Props> = ({ data }) => {
  return (
    <TaskEvent data={data}>
      alterou o status do feedback&nbsp;
      <FromTo
        fromPrefix="criado por"
        from={data.from_user?.name ?? data.name}
        to={data.status === 'resolved' ? 'resolvido' : 'pendente'}
      />
    </TaskEvent>
  );
};

export default TaskEventUpdateFeedbackStatus;
