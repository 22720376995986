import React, { useState, useEffect } from 'react';
import { Draggable, DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { List, Input, Button, notification } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import ActivitytListItem from '@pages/Activities/ActivitiesLists/ActivitiesList/ActivityListItem';

import { create_task, move_before, move_after } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  taskId: string;
  accountId: string;
  type: string;
  setSelected: (id: string) => void;
}

const subtasks = gql`
  query SubTasks($id: ID!) {
    task(id: $id) {
      id
      subtasks(pagination: { limit: -1, page: 1 }) {
        total
        data {
          id
          title
          step
          type
          finish_date
          ... on DefaultTask {
            execution_date
            has_problem
          }
          cell_id
          users {
            id
            name
            avatarUrl
          }
        }
      }
    }
  }
`;

const SubActivitytList: React.FC<Props> = ({ taskId, accountId, setSelected, type }) => {
  const [tasks, setTasks] = useState<any[]>();
  const [newTask, setNewTask] = useState('');

  const { data, loading, error, refetch } = useQuery(subtasks, {
    fetchPolicy: 'cache-and-network',
    variables: { id: taskId },
  });

  const [MoveAfter] = useMutation(move_after);
  const [MoveBefore] = useMutation(move_before);

  const [CreateTask] = useMutation(create_task);

  useEffect(() => {
    refetch({ id: taskId });
  }, [taskId, refetch]);

  useEffect(() => {
    if (!data) return;

    setTasks(data.task?.subtasks?.data);
  }, [data]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !tasks) return;

    const from = result.source.index;
    const to = result.destination.index;

    if (from === to) return;

    const reference = from < to ? 'after' : 'before';
    const taskOrdId = tasks[from].id;
    const taskRefId = tasks[to].id;

    Order(reference, taskRefId, taskOrdId);

    const newTasks = [...tasks];

    const originItem = newTasks.find((t: any) => t.id === taskOrdId);

    newTasks.splice(from, 1);

    newTasks.splice(to, 0, originItem);

    setTasks(newTasks);
  };

  async function Order(reference: 'before' | 'after', taskRefId: string, taskOrdId: string) {
    try {
      if (reference === 'after') await MoveAfter({ variables: { type: 'Task', id: taskOrdId, target: taskRefId } });
      if (reference === 'before') await MoveBefore({ variables: { type: 'Task', id: taskOrdId, target: taskRefId } });
    } catch (err) {
      console.error(err);
    } finally {
      refetch({ id: taskId });
    }
  }

  async function doCreate() {
    try {
      await CreateTask({ variables: { account: accountId, title: newTask, parent: taskId, type, step: 'execution' } });

      setNewTask('');

      refetch({ id: taskId });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNewTask(event?.target?.value);
  }

  if (error) return <ErrorAndLoading error={error} />;

  return (
    <List size="small" style={{ margin: '0 0 16px' }} loading={loading && !data}>
      {data && tasks && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId={taskId}
            renderClone={(provided, snapshot, rubric) => (
              <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                <ActivitytListItem
                  accountId={accountId}
                  showAccountLogo={false}
                  cellId={tasks[rubric.source.index].cell_id}
                  problem={tasks[rubric.source.index].has_problem}
                  taskId={tasks[rubric.source.index].id}
                  taskTitle={tasks[rubric.source.index].title}
                  taskStep={tasks[rubric.source.index].step}
                  taskExecutionDate={tasks[rubric.source.index].execution_date}
                  taskFinishDate={tasks[rubric.source.index].finish_date}
                  taskUsers={tasks[rubric.source.index].users}
                  taskType={tasks[rubric.source.index].type}
                  showSelectEditMultiple={false}
                  openTask={() => setSelected(tasks[rubric.source.index].id)}
                  refresh={() => refetch({ id: taskId })}
                />
              </div>
            )}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tasks.map(
                  (
                    {
                      id,
                      title,
                      execution_date,
                      finish_date,
                      step,
                      type,
                      has_problem,
                      cell_id,
                      account_id,
                      users,
                    }: any,
                    idx: number,
                  ) => (
                    <Draggable draggableId={id} key={`${id + idx}`} index={idx}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <ActivitytListItem
                            index={idx}
                            accountId={account_id}
                            showAccountLogo={false}
                            cellId={cell_id}
                            problem={has_problem}
                            taskId={id}
                            taskTitle={title}
                            taskStep={step}
                            taskExecutionDate={execution_date}
                            taskFinishDate={finish_date}
                            taskUsers={users}
                            taskType={type}
                            showSelectEditMultiple={false}
                            openTask={() => setSelected(id)}
                            refresh={() => refetch({ id: taskId })}
                          />
                        </div>
                      )}
                    </Draggable>
                  ),
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <List.Item className={styles.item} style={{ padding: 0, display: 'flex', flexDirection: 'row' }}>
        <Input
          style={{ border: 'none', backgroundColor: 'transparent' }}
          placeholder="Criar nova atividade"
          value={newTask}
          onChange={handleChange}
          onKeyPress={({ key }) => {
            if (key === 'Enter') {
              doCreate();
            }
          }}
        />
        <Button onClick={() => doCreate()}>Criar</Button>
      </List.Item>
    </List>
  );
};

export default SubActivitytList;
