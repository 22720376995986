import React from 'react';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import NewFunctionalities from '@comp/NewFunctionalities';
import TutorialButton from '@comp/TutorialButton';

import Accounts from './Accounts';
import NextActivities from './NextActivities';
import PendingFeedbacks from './PendingFeedbacks';
import Performance from './Performance/index';
import RecentUpdates from './RecentUpdates/index';
import TutorialHomePage from './TutorialHomePage';

import styles from './styles.module.less';

const HomePage: React.FC = () => {
  const { t } = useTranslation('tutorialTooltip');

  return (
    <>
      <div>
        <Row className={styles.root} gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={15}>
            <NewFunctionalities
              title="Performance"
              description={performanceDescription}
              configId="20.12.28.95188"
              ribbon={true}
              fixedTooltip={false}
              placement="bottomRight"
              primaryColor={true}>
              <Performance />
            </NewFunctionalities>

            <div className={styles.accounts_div}>
              <Accounts />
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={9}>
            <RecentUpdates />
          </Col>
        </Row>

        <Row className={styles.root} gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={15}>
            <div className={styles.item}>
              <NextActivities />
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={9}>
            <div className={styles.item}>
              <PendingFeedbacks />
            </div>
          </Col>
        </Row>
      </div>

      <TutorialButton tourId="001_HomeTutorial" top={80} right={20} placement="left" title={t('home_page')} />

      <TutorialHomePage />
    </>
  );
};

export default observer(HomePage);

const performanceDescription =
  'Aqui você pode ver a sua performance média e outros indicadores que podem te ajudar a ver onde você pode melhorar e onde você arrasa !';
