import { NexusGenFieldTypes } from 'schema';

import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventTaskCreated'];
}

const TaskEventTaskCreated: React.FC<Props> = ({ data }) => {
  return <TaskEvent data={data}>criou essa atividade</TaskEvent>;
};

export default TaskEventTaskCreated;
