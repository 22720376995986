import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Input, notification, Typography } from 'antd';
import { useMutation } from '@apollo/client';

import Clickable from '@comp/Clickable';

import errorMessage from '@logic/functions/errorHandeling';
import { update_template } from '@logic/mutations';

interface TemplateProps {
  openTemplate: () => void;
  templateTitle: string;
  id: string;
  account: string; // Unused
  displayOnly?: boolean;
}

const TemplatesListItem: React.FC<TemplateProps> = ({ templateTitle, openTemplate, id, account, displayOnly }) => {
  const [title, setTitle] = useState(templateTitle);
  const [focused, setFocused] = useState(false);

  const [UpdateTask] = useMutation(update_template, { variables: { id, title: title || '' } });

  const ref = useRef<any>();

  const save = useMemo(
    () => async (title: string) => {
      try {
        await UpdateTask({ variables: { id, title } });
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [id, UpdateTask],
  );

  useLayoutEffect(() => {
    if (!ref.current) return;

    ref.current.input.focus();
  }, [ref, focused]);

  function handleChange(key: string, value: moment.Moment | string) {
    setTitle(value.toString());
  }

  return (
    <Clickable
      onClick={() => openTemplate()}
      onDbClick={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      style={{ display: 'flex', flex: 1, cursor: 'pointer', width: `calc(100% - 25px)`, alignItems: 'baseline' }}>
      {!focused || displayOnly ? (
        <Typography.Text id="create_template_item" ellipsis style={{ border: 'none', width: '100%', fontSize: 16 }}>
          {title}
        </Typography.Text>
      ) : (
        <Input
          ref={ref}
          onClick={(e) => e.stopPropagation()}
          onKeyPress={(e) => e.stopPropagation()}
          value={title}
          style={{ border: 'none', width: '100%', fontSize: 16 }}
          onChange={({ target }) => handleChange('title', target.value)}
          onBlur={() => save(title)}
        />
      )}
    </Clickable>
  );
};

export default TemplatesListItem;
