import React, { useEffect, useState } from 'react';
import { Input, Select, Button, Tooltip } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Duration } from 'luxon';

import { ArrowRightOutlined } from '@ant-design/icons';

interface Props {
  taskId: string;
}

const TaskTimer: React.FC<Props> = ({ taskId }) => {
  const [{ planned, used, last }, setTimers] = useState({ planned: 0, used: 0, last: 0 });
  const { data, loading } = useQuery(task_timer_seconds, { variables: { id: taskId }, fetchPolicy: 'network-only' });

  useEffect(() => {
    if (!data) return;
    setTimers({
      planned: data?.task?.planned_seconds,
      used: data?.task?.timers_total_seconds,
      last: (data?.task?.planned_seconds - data?.task?.timers_total_seconds) * 1000,
    });
  }, [data]);

  const timeLastFormatted = planned
    ? Duration.fromMillis(last >= 0 ? last : -last).toFormat('hh:mm', { floor: true })
    : '00:00';

  if (loading) return null;

  return (
    <Tooltip
      destroyTooltipOnHide={{ keepParent: false }}
      placement="leftTop"
      color={last >= 0 ? '#55a630' : '#ff5333'}
      title={
        <div style={{ textAlign: 'center' }}>
          <p style={{ margin: 0 }}>{`${Duration.fromMillis(used * 1000).toFormat('hh:mm', { floor: true })} usadas`}</p>
          <p style={{ margin: 0 }}>{`${last >= 0 || !planned ? 'restam ' : 'ultrapassou '}${timeLastFormatted}`}</p>
        </div>
      }>
      <div>
        <SelectPlannedSeconds
          taskId={taskId}
          planned={planned}
          setPlanned={(value) => setTimers(({ used }) => ({ last: (value - used) * 1000, used, planned: value }))}
        />
      </div>
    </Tooltip>
  );
};

export default TaskTimer;

interface SelectPlannedSecondsProps {
  taskId: string;
  planned: number;
  setPlanned: (value: number) => void;
}

const SelectPlannedSeconds: React.FC<SelectPlannedSecondsProps> = ({ taskId, planned, setPlanned }) => {
  const [addOption, setAddOption] = useState<any>();
  let options = ['00:30', '01:00', '02:00', Duration.fromMillis(planned * 1000).toFormat('hh:mm')];
  options = options.filter((elem, index, self) => index === self.indexOf(elem));
  options = options.sort();

  const [UpdatePlannedSeconds] = useMutation(update_task_planned_seconds);

  const handleChange = (value: any) => {
    setPlanned(formataddOption(value));
    UpdatePlannedSeconds({ variables: { id: taskId, planned_seconds: formataddOption(value) } });
  };

  const handleKeyPress = ({ code }: any) => {
    if (code === 'Enter') handleChange(addOption);
  };

  return (
    <Select
      style={{ width: 100 }}
      size="small"
      value={Duration.fromMillis(planned * 1000).toFormat('hh:mm')}
      onChange={handleChange}
      dropdownRender={(menu) => (
        <div>
          <Input
            type="time"
            size="small"
            value={addOption}
            style={{ paddingRight: 0, paddingLeft: 2 }}
            onChange={({ target: { value } }) => setAddOption(value)}
            onKeyPress={handleKeyPress}
            suffix={<Button icon={<ArrowRightOutlined />} size="small" onClick={() => handleChange(addOption)} />}
          />
          {menu}
        </div>
      )}>
      {options.map((option, idx) => (
        <Select.Option key={`option_${idx}_${option}`} value={option}>
          {option}
        </Select.Option>
      ))}
    </Select>
  );
};

function formataddOption(time: string) {
  const [hours, minutes] = time.split(':');
  return Number(hours) * 60 * 60 + Number(minutes) * 60;
}

const task_timer_seconds = gql`
  query TaskTimersListOnlySeconds($id: ID!) {
    task(id: $id) {
      id
      planned_seconds
      timers_total_seconds
    }
  }
`;

const update_task_planned_seconds = gql`
  mutation UpdateTaskPlannedSeconds($id: ID!, $planned_seconds: Int) {
    updateTask(id: $id, planned_seconds: $planned_seconds) {
      id
      planned_seconds
    }
  }
`;
