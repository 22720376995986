import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

const TutorialProfile: React.FC = () => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '016_Profile' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="avatar_profile"]',
      content: 'Clique aqui para adicionar uma foto ou imagem de perfil.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="name_profile"]',
      content: 'Edite o seu nome.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="email_profile"]',
      content: 'Altere o seu email.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="password_profile"]',
      content: 'Altere a sua senha. Ela deve conter no mínimo 8 caracteres.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="confirm_password_profile"]',
      content: 'Confirme a sua senha.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="save_button_profile"]',
      content: 'Após realizar as alterações desejadas, clique em "Salvar" para salvar as alterações.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
  ];

  const ids: any[] = [
    'avatar_profile',
    'name_profile',
    'email_profile',
    'password_profile',
    'confirm_password_profile',
    'save_button_profile',
  ];

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialProfile);
