import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Input, Card, Checkbox, Button, notification } from 'antd';

import idLogo from '../../img/id_lab_logo_vertical.png';

import Navigate from '@comp/Navigate';
import { useStore } from '@logic/context';
import { user_login } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

const Login: React.FC = () => {
  const store = useStore();
  const [UserLogin, { loading }] = useMutation(user_login);

  async function doLogin(email: string, password: string) {
    try {
      const res = await UserLogin({ variables: { email, password } });
      await store.storeToken(res.data?.login);
      notification.open({ type: 'success', message: 'Bem vindo(a)' });
    } catch (err) {
      notification.error({ ...errorMessage('graph_err', err) });
    }
  }

  const onFinish = (values: any) => {
    doLogin(values.email, values.password);
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('', errorInfo) });
  };

  return (
    <div className={styles.root}>
      <Card hoverable={false} className={styles.card} bodyStyle={{ flex: 1, width: '100%', padding: '32px 32px 8px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={idLogo} alt="id escrito em forma de uma seta" />
        </div>

        <Form
          name="login"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          wrapperCol={{ span: 24 }}>
          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Você precisa inserir seu email' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: 'Você precisa inserir sua senha' }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Manter conectado</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Entrar
            </Button>
          </Form.Item>

          {process.env.NODE_ENV !== 'production' && (
            <Button type="primary" onClick={() => doLogin('admin@test.com', '1234')} block loading={loading}>
              Development login
            </Button>
          )}
        </Form>

        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Navigate to="/recover_password">
            <Button type="link" style={{ padding: 0 }}>
              Recuperar senha
            </Button>
          </Navigate>
        </div>
      </Card>
    </div>
  );
};

export default Login;
