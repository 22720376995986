import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  isNew: boolean;
}

const TutorialInfodrawer: React.FC<Props> = ({ isNew }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '013_InfoDrawer' ? true : false;

  const steps: any[] = [
    ...[
      !isNew
        ? {
            selector: '[id="delete_info"]',
            content: 'Clique aqui para excluir a informação',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="title_info"]',
      content: isNew
        ? 'Escreva um título para a informação. Este é um campo obrigatório'
        : 'Edite o título da informação clicando sobre o campo. Após a alteração, ao clicar fora do campo a alteração será salva.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="description_info"]',
      content: isNew
        ? 'Escreva a informação útil relacionada ao cliente'
        : 'Edite a descrição da informação clicando sobre o campo. Após a alteração, ao clicar fora do campo a alteração será salva.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      !isNew
        ? {
            selector: '[id="upload_file_info"]',
            content:
              'Faça upload de arquivos em informações úteis, e se quiser depois pode ser feito download de todos os arquivos anexados.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      isNew
        ? {
            selector: '[id="save_info_button"]',
            content: 'Após preencher os campos de título e descrição, clique em "Salvar"',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    ...[!isNew ? 'delete_info' : undefined],
    'title_info',
    'description_info',
    ...[!isNew ? 'upload_file_info' : undefined],
    ...[isNew ? 'save_info_button' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('ant-tabs-content-holder', 'class_name')}
      onBeforeClose={() => enableScroll('ant-tabs-content-holder', 'class_name')}
    />
  );
};

export default observer(TutorialInfodrawer);
