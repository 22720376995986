import { NexusGenFieldTypes } from 'schema';

import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventDetachUser'];
}

const TaskEventDetachUser: React.FC<Props> = ({ data }) => {
  return (
    <TaskEvent data={data}>
      desanexou o usuário <b>{data.user?.name ?? data.name}</b>
    </TaskEvent>
  );
};

export default TaskEventDetachUser;
