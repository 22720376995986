import React from 'react';
import { Tooltip, Typography } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { QuestionCircleOutlined } from '@ant-design/icons';

import styles from './styles.module.less';

interface Props {
  title: string;
  description: string;
  tooltipColor?: string;
  text?: any;
  placement?: TooltipPlacement;
  iconStyle?: any;
  overlayStyle?: any;
}

// (?) Tooltip com textos de ajuda

const Help: React.FC<Props> = ({ title, description, text, overlayStyle, tooltipColor, placement, iconStyle }) => {
  return (
    <Tooltip
      destroyTooltipOnHide={{ keepParent: false }}
      overlayStyle={overlayStyle}
      title={
        <div style={{ flexDirection: 'column', padding: 10 }}>
          <Typography.Text className={styles.title}>{title}</Typography.Text>

          <Typography.Text className={styles.description}>{description}</Typography.Text>

          <div className={styles.text} style={{ color: 'rgba(255,255,255.85)' }}>
            {text}
          </div>
        </div>
      }
      color={tooltipColor ? tooltipColor : 'rgba(0,0,0,.85)'}
      placement={placement}>
      <QuestionCircleOutlined style={{ fontSize: 18, ...iconStyle }} />
    </Tooltip>
  );
};

export default Help;
