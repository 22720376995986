import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { List, Empty } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import LinkItem from './LinkItem';

import { selected_account_links_list } from '@logic/queries';
import { useStore } from '@logic/context';

interface Props {
  accountId: string;
  setSelected: (id: string) => void;
  linkId: string;
}

const LinksList: React.FC<Props> = ({ accountId, setSelected, linkId }) => {
  const [links, setLinks] = useState<any[]>();

  const store = useStore();

  const { data, loading, error, refetch } = useQuery(selected_account_links_list, {
    fetchPolicy: 'network-only',
    variables: { id: accountId },
  });

  useEffect(() => {
    refetch({ id: accountId });
  }, [accountId, refetch, linkId]);

  useEffect(() => {
    if (!data) return;

    setLinks(data.account?.links?.data);
    store.setHasLink(data.account?.links?.data.length > 0);
  }, [links, data, store]);

  const refetchLinks = useCallback(() => {
    if (refetch) refetch();
  }, [refetch]);

  //------------------------------------------------------------------------------

  if (error) return <ErrorAndLoading error={error} />;

  return (
    <List size="small" style={{ margin: 0 }} loading={loading && !links}>
      {data && !loading && (!links || links.length === 0) && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ margin: 0, paddingTop: 8, paddingBottom: 8 }}
          description="Não existem links nessa conta"
        />
      )}

      {!!links &&
        links.map(({ id, type, url }: any, idx: number) => (
          <div id="linkListItem" key={`${id}_${idx}`}>
            <LinkItem
              linkId={id}
              linkType={type}
              url={url}
              setSelected={(id) => setSelected(id)}
              refresh={refetchLinks}
            />
          </div>
        ))}
    </List>
  );
};

export default LinksList;
