import React from 'react';
import { Card, Col, Row, Typography } from 'antd';

import { LineChartOutlined } from '@ant-design/icons';
import useWindowSize from '@logic/functions/windowSize';

import StatsBlock from '@comp/StatsBlock';

import styles from './styles.module.less';

//Card de performance de relatório do facebook
//são quadrados que se ajustam ao tamanho da tela
//em clientes > estratégico > relatórios > facebook

interface Props {
  insights: any;
  previous: any;
}

const Performance: React.FC<Props> = ({ insights, previous }) => {
  //utilizado para desenhar as bordas conforme o tamanho da tela
  const { width } = useWindowSize();

  const data = {
    now: {
      click: insights.reduce((acc: number, i: any) => acc + i['clicks'], 0),
      ctr: insights.reduce((acc: number, i: any) => acc + i['ctr'], 0) / insights.length,
      cpc: insights.reduce((acc: number, i: any) => acc + i['cpc'], 0) / insights.length,
      reach: insights.reduce((acc: number, i: any) => acc + i['reach'], 0),
      frequency: insights.reduce((acc: number, i: any) => acc + i['frequency'], 0) / insights.length,
      impressions: insights.reduce((acc: number, i: any) => acc + i['impressions'], 0),
      amount_spent: insights.reduce((acc: number, i: any) => acc + i['spend'], 0),
    },
    before: {
      click: previous.reduce((acc: number, i: any) => acc + i['clicks'], 0),
      ctr: previous.reduce((acc: number, i: any) => acc + i['ctr'], 0) / previous.length,
      cpc: previous.reduce((acc: number, i: any) => acc + i['cpc'], 0) / previous.length,
      reach: previous.reduce((acc: number, i: any) => acc + i['reach'], 0),
      frequency: previous.reduce((acc: number, i: any) => acc + i['frequency'], 0) / previous.length,
      impressions: previous.reduce((acc: number, i: any) => acc + i['impressions'], 0),
      amount_spent: previous.reduce((acc: number, i: any) => acc + i['spend'], 0),
    },
  };

  //verifica se o valor atual é maior que o anterior
  const upClick = data.now.click > data.before.click;
  const upFrequency = data.now.frequency > data.before.frequency;
  const upCtr = data.now.ctr > data.before.ctr;
  const upImpressions = data.now.impressions > data.before.impressions;
  const upCpc = data.now.cpc > data.before.cpc;
  const upSpentAmount = data.now.amount_spent > data.before.amount_spent;
  const upReach = data.now.reach > data.before.reach;

  //calcula o percentual de aumento ou de queda em relação ao período anterior
  //são valores com até 2 casas decimais
  const clickPercent = parseFloat((((data.now.click - data.before.click) * 100) / data.before.click).toFixed(2));
  const frequencyPercent = parseFloat(
    (((data.now.frequency - data.before.frequency) * 100) / data.before.frequency).toFixed(2),
  );
  const ctrPercent = parseFloat((((data.now.ctr - data.before.ctr) * 100) / data.before.ctr).toFixed(2));
  const impressionsPercent = parseFloat(
    (((data.now.impressions - data.before.impressions) * 100) / data.before.impressions).toFixed(2),
  );
  const cpcPercent = parseFloat((((data.now.cpc - data.before.cpc) * 100) / data.before.cpc).toFixed(2));
  const spentAmountPercent = parseFloat(
    (((data.now.amount_spent - data.before.amount_spent) * 100) / data.before.amount_spent).toFixed(2),
  );
  const reachPercent = parseFloat((((data.now.reach - data.before.reach) * 100) / data.before.reach).toFixed(2));

  //textos padrões utilizados para falar se obteve queda ou alta nos valores calculados
  const higherValueText = 'maior que o período anterior';
  const lowerValueText = 'menor que o período anterior';

  return (
    <Card style={cardStyle} bodyStyle={cardBodyStyle}>
      <Row className={styles.title}>
        <Typography.Title className={styles.title_text}>
          <LineChartOutlined className={styles.title_icon} size={18} />
          Performance conversão landing page
        </Typography.Title>
      </Row>

      <Row className={styles.row}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
          style={{ borderBottom: '1px solid rgba(106, 107, 108, 0.2)' }}
          className={styles.collumn}>
          <StatsBlock
            title="CLIQUES (TODOS)"
            value={data.now.click}
            format="decimal"
            up={upClick}
            text={upClick ? `${clickPercent}% ` + higherValueText : `${clickPercent * -1}% ` + lowerValueText}
          />
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
          style={{
            borderRight:
              (width < 1600 && width > 1200) || width > 1600 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined,
            borderBottom: '1px solid rgba(106, 107, 108, 0.2)',
          }}
          className={styles.last_collumn}>
          <StatsBlock
            title="CTR (TODOS)"
            value={data.now.ctr}
            format="decimal"
            up={upCtr}
            text={upCtr ? `${ctrPercent}% ` + higherValueText : `${ctrPercent * -1}% ` + lowerValueText}
          />
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
          style={{
            borderRight: width > 1600 || width < 1200 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined,
            borderBottom: '1px solid rgba(106, 107, 108, 0.2)',
          }}
          className={styles.last_collumn}>
          <StatsBlock
            title="CPC (TODOS)"
            value={data.now.ctr}
            format="currency"
            up={upCpc}
            text={upCpc ? `${cpcPercent}% ` + higherValueText : `${cpcPercent * -1}% ` + lowerValueText}
          />
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
          style={{
            borderRight: width < 1600 && width > 1200 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined,
            borderBottom: '1px solid rgba(106, 107, 108, 0.2)',
          }}
          className={styles.last_collumn}>
          <StatsBlock
            title="ALCANCE"
            value={data.now.reach}
            format="decimal"
            up={upReach}
            text={upReach ? `${reachPercent}% ` + higherValueText : `${reachPercent * -1}% ` + lowerValueText}
            rootFlex={true}
          />
        </Col>

        <div style={{ borderBottom: '1px solid rgba(106, 107, 108, 0.2)' }}></div>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
          style={{ borderBottom: width < 1600 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined }}
          className={styles.collumn}>
          <StatsBlock
            title="FREQUÊNCIA"
            value={data.now.frequency}
            format="decimal"
            up={upFrequency}
            text={
              upFrequency ? `${frequencyPercent}% ` + higherValueText : `${frequencyPercent * -1}% ` + lowerValueText
            }
          />
          <div style={{ borderBottom: width > 1600 ? undefined : '1px solid rgba(106, 107, 108, 0.2)' }}></div>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
          style={{
            borderRight: width < 1600 ? undefined : '1px solid rgba(106, 107, 108, 0.2)',
            borderBottom: width < 1600 ? '1px solid rgba(106, 107, 108, 0.2)' : undefined,
          }}
          className={styles.last_collumn}>
          <StatsBlock
            title="IMPRESSÕES"
            value={data.now.impressions}
            format="decimal"
            up={upImpressions}
            text={
              upImpressions
                ? `${impressionsPercent}% ` + higherValueText
                : `${impressionsPercent * -1}% ` + lowerValueText
            }
          />
          <div style={{ borderBottom: width > 1600 ? undefined : '1px solid rgba(106, 107, 108, 0.2)' }}></div>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={6} className={styles.collumn}>
          <StatsBlock
            title="VALOR GASTO"
            value={data.now.amount_spent}
            format="currency"
            up={upSpentAmount}
            text={
              upSpentAmount
                ? `${spentAmountPercent}% ` + higherValueText
                : `${spentAmountPercent * -1}% ` + lowerValueText
            }
          />
        </Col>
      </Row>
    </Card>
  );
};
export default Performance;

const cardStyle = { borderRadius: 4, overflow: 'hidden', boxShadow: '0 1px 4px #00000040', display: 'flex' };
const cardBodyStyle = { padding: 0, minHeight: 360 };
