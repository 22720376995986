import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

const TutorialNewCell: React.FC = () => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '019_NewCellManagement' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="write_cell_name"]',
      content: 'Digite o nome da nova célula',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'left',
    },
    {
      selector: '[id="save_cell"]',
      content: 'Para salvar a nova célula, clique em "Salvar"',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
  ];

  const ids: any[] = ['write_cell_name', 'save_cell'];

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialNewCell);
