import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'antd';

import { IoMdPeople, IoIosLogOut } from 'react-icons/io';
import { RiBuildingLine } from 'react-icons/ri';

import Navigate from '../Navigate';
import { route } from '@router';
import { useStore } from '@logic/context';

import styles from './styles.module.less';
import AvatarPicture from '@comp/AvatarPicture';

const ProfileMenu: React.FC = () => {
  const store = useStore();

  const { t } = useTranslation('profileMenu');

  const isAdmin = store.profile && store.profile.roles.includes('admin');

  async function handleLogOut() {
    try {
      await store.storeToken(null);
      store.setProfile(undefined);
      window.location.pathname = '/';
    } catch (err) {}
  }

  return (
    <Dropdown
      menu={{
        items: [
          isAdmin
            ? {
                key: 'admin',
                label: (
                  <Navigate to={route('admin.realm')}>
                    <span className={styles.menuItem}>
                      <RiBuildingLine style={{ fontSize: '18px', marginRight: '4px' }} />
                      <span>{t('admin')}</span>
                    </span>
                  </Navigate>
                ),
              }
            : null,
          isAdmin ? { type: 'divider' } : null,
          {
            key: 'profile',
            label: (
              <Navigate to={route('profile')}>
                <span className={styles.menuItem}>
                  <IoMdPeople style={{ fontSize: '18px', marginRight: '4px' }} />
                  <span>{t('profile')}</span>
                </span>
              </Navigate>
            ),
          },
          {
            key: 'logout',
            label: (
              <span className={styles.menuItem} onClick={handleLogOut}>
                <IoIosLogOut style={{ fontSize: '18px', marginRight: '4px' }} />
                <span>{t('logout')}</span>
              </span>
            ),
          },
        ],
      }}
      trigger={['click']}>
      <div
        id="profile_logout"
        style={{
          marginLeft: 16,
          cursor: 'pointer',
        }}>
        <AvatarPicture pictureLink={store.profile?.avatarUrl} target="User" size={40} name={store.profile?.name} />
      </div>
    </Dropdown>
  );
};

export default observer(ProfileMenu);
