import React from 'react';
import { useParams } from 'react-router-dom';

import NewCell from './NewCell';
import EditCell from './EditCell';

const Cell: React.FC = () => {
  const { cell }= useParams<any>();

  const newCell = cell === 'new';

  if (newCell) return <NewCell />;
  else return <EditCell />;
};

export default Cell;
