import React from 'react';
import Tour from 'reactour';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  addCellButton: boolean;
  hasCell: boolean;
  addUserButton: boolean;
  hasUser: boolean;
}

const TutorialEditAccount: React.FC<Props> = ({ addCellButton, addUserButton, hasCell, hasUser }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '025_EditAccountManagement' ? true : false;

  const { t } = useTranslation('tutorialEditAccount');

  const steps: any[] = [
    {
      selector: '[id="avatar_account"]',
      content: t('account_avatar'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="name_account"]',
      content: t('account_name'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="status_account"]',
      content: t('account_status'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="planned_hours_account"]',
      content: t('planned_hours_account'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="mailerlite_key"]',
      content: t('mailerlite_key'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="save_button"]',
      content: t('save_button'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="facebook_account"]',
      content: t('facebook_account'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="google_account"]',
      content: t('google_account'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      addCellButton
        ? {
            selector: '[id="add_cell"]',
            content: t('add_cell'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasCell
        ? {
            selector: '[id="open_cell_button"]',
            content: t('open_cell_button'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasCell
        ? {
            selector: '[id="open_cell"]',
            content: t('open_cell'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasCell
        ? {
            selector: '[id="delete_cell"]',
            content: t('delete_cell'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      addUserButton
        ? {
            selector: '[id="add_user"]',
            content: t('add_user'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasUser
        ? {
            selector: '[id="open_user_button"]',
            content: t('open_user_button'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasUser
        ? {
            selector: '[id="open_user"]',
            content: t('open_user'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      hasUser
        ? {
            selector: '[id="delete_account_cell"]',
            content: t('delete_account_cell'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    'avatar_account',
    'name_account',
    'status_account',
    'planned_hours_account',
    'mailerlite_key',
    'save_button',
    'facebook_account',
    'google_account',
    ...[addCellButton ? 'add_cell' : undefined],
    ...[hasCell ? 'open_cell_button' : undefined],
    ...[hasCell ? 'open_cell' : undefined],
    ...[hasCell ? 'delete_cell' : undefined],
    ...[addUserButton ? 'add_user' : undefined],
    ...[hasUser ? 'open_user_button' : undefined],
    ...[hasUser ? 'open_user' : undefined],
    ...[hasUser ? 'delete_account_cell' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialEditAccount);
