import React, { useLayoutEffect, useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Form, notification, Typography, Radio } from 'antd';

import EmojiLinkInput from '@comp/EmojiLinkInput';
import ErrorAndLoading from '@comp/ErrorAndLoading';
import SubTemplates from '../SubTemplates';
import TemplateTimer from '../TemplateTimer';

import { update_template_item } from '@logic/mutations';
import { template_item } from '@logic/queries';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  templateId: string;
  accountId: string; // Unused
  setSelected: (id: string) => void;
  displayOnly?: boolean;
}

const TemplateItemTab: React.FC<Props> = ({ templateId, accountId, setSelected, displayOnly }) => {
  const [form] = Form.useForm();

  const [template, setTemplate] = useState({ id: templateId, title: '', body: '', type: '' });

  //--------------------- Queries  --------------------------------

  const { data, loading, error, refetch } = useQuery(template_item, {
    variables: { id: templateId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setTemplate({
        id: data.templateItem.id,
        title: data.templateItem.title,
        body: data.templateItem.body,
        type: data.templateItem.type,
      });
    }
  }, [data]);

  //---------------------- mutations ------------------------------

  const [UpdateTemplate] = useMutation(update_template_item);

  //----------------- Resets the form fields -----------------------

  useLayoutEffect(() => {
    if (!template) return;

    form.setFieldsValue({ title: template.title, body: template.body, type: template.type });
  }, [template, form]);

  //--------------Save and Delete functions -----------------------

  async function doSave(id: string, title: string, body: string, type: string) {
    try {
      await UpdateTemplate({ variables: { id, title, body, type } });

      refetch({ id: templateId });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  //------------- Form functions ------------------------------------------------

  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        doSave(templateId, values.title, values.body, values.type);
      })
      .catch((info) => {
        notification.open({ type: 'error', ...errorMessage('form_validation', info) });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('', errorInfo) });
  };

  const submit = useCallback(() => form.submit(), [form]);

  //------------------------------------------------------------------------------

  if (error) return <ErrorAndLoading error={error} />;

  if (loading) return <ErrorAndLoading loading={loading} />;

  return (
    <div style={{ paddingBottom: '16px' }}>
      {!!template && (
        <>
          <Form
            form={form}
            onBlur={submit}
            labelCol={{ style: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' } }}
            wrapperCol={{ style: { display: 'flex', flex: 1 } }}
            layout="horizontal"
            name="template"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ title: template.title, body: template.body }}>
            <Form.Item name="type">
              <Radio.Group>
                <Radio.Button value="default">Atividade</Radio.Button>
                <Radio.Button value="assignment">Atividade pontual</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="title">
              <EmojiLinkInput multiline emojiPicker="focus" placeholder="Título" className={styles.title} />
            </Form.Item>

            <div style={{ margin: '-8px 0 16px' }}>
              <Form.Item label={<Typography.Text className={styles.divisor}>Horas previstas</Typography.Text>} />
              <TemplateTimer templateId={templateId} />
            </div>

            <Form.Item label={<Typography.Text className={styles.divisor}>Descrição</Typography.Text>}></Form.Item>

            <Form.Item name="body">
              <EmojiLinkInput md multiline border emojiPicker="focus" placeholder="Descrição" className={styles.body} />
            </Form.Item>
          </Form>

          <SubTemplates setSelected={setSelected} templateItemId={templateId} displayOnly={displayOnly} />
        </>
      )}
    </div>
  );
};

export default TemplateItemTab;
