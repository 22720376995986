import React from 'react';
import { Image, Typography } from 'antd';
import Tour from 'reactour';
import { observer } from 'mobx-react-lite';

import { useStore } from '@logic/context';

import profileLogout from './img/menu_perfil_logout.gif';
import notification from './img/notification_app_bar.gif';

import styles from './tutorial.module.less';

const TutorialMenu: React.FC = () => {
  const store = useStore();
  const isAdmin = store.profile && store.profile.roles.includes('admin');
  const hasAccounts = store.profile && store.profile.accounts.length > 0;

  const isTourOpen = !!store.tour && store.tour === '002_MenuTutorial' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="button_collapsed_menu"]',
      content: 'Clique aqui para minimizar o menu lateral',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'bottom',
    },
    {
      selector: '[id="button_start_independent_timer"]',
      content:
        'Inicie o tempo por aqui, se não houver outro tempo já iniciado. Este tempo pode ser iniciado sem estar vinculado a uma atividade, mas para encerrar o tempo deve-se abrir uma atividade para finalizar',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="search"]',
      content: 'Neste campo podem ser realizadas pesquisas sobre o conteúdo presente na IDActionLab',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="notifications"]',
      content: () => {
        return (
          <div className={styles.avatar_menu}>
            <div className={styles.image_div}>
              <Image
                alt="Notification Dropdown Menu"
                width={300}
                src={notification}
                preview={false}
                className={styles.avatar_menu_gif}></Image>
            </div>

            <Typography>
              Veja as suas notificações aqui. As notificações são atualizações das suas atividades, que podem ter
              mudanças de etapa e informar o usuário que realizou a alteração.
            </Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
      position: 'bottom',
    },
    {
      selector: '[id="profile_logout"]',
      content: () => {
        return (
          <div className={styles.avatar_menu}>
            <div className={styles.image_div}>
              <Image
                alt="Avatar Dropdown Menu"
                width={150}
                src={profileLogout}
                preview={false}
                className={styles.avatar_menu_gif}></Image>
            </div>

            <Typography>
              Ao colocar o mouse sobre sua foto do perfil ou o avatar no canto superior direito, possibilita o acesso ao
              menu para editar seu perfil ou fazer logout
            </Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)', width: 600 },
      position: 'bottom',
    },
    ...[
      isAdmin
        ? {
            selector: '[id="general_view_menu"]',
            content: 'Em visão geral é onde você verifica as atividades das células e dos usuários da sua organização',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="my_tasks_menu"]',
      content:
        'As minhas atividades contém todas as suas atividades e podem ser visualizados conforme as etapas, período, e outros filtros',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      isAdmin
        ? {
            selector: '[id="templates_menu"]',
            content:
              'Os templates globais da sua organização são atividades padrões que podem ser utilizadas como modelos para criar outras atividades. Nele você pode criar, editar e excluir os templates.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="timers_menu"]',
      content:
        'Os tempos são relatórios diários dos tempos utilizados em atividades. Pode ser visto também conforme células e clientes selecionados.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="reports_menu"]',
      content: 'Em relatórios estão suas atividades de acordo com a etapa da atividade.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      hasAccounts
        ? {
            selector: '[id="accounts_menu"]',
            content: 'Cada cliente contém atividades, informações, reuniões e ferramentas.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
            stepInteraction: false,
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  return (
    <>
      <Tour
        startAt={0}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => store.setTour(undefined)}
        accentColor="#ff5333"
        disableInteraction
        maskSpace={6}
        maskClassName={styles.mask}
        rounded={4}
      />
    </>
  );
};

export default observer(TutorialMenu);
