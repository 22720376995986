import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  hasTimers?: boolean;
}

const TutorialTimers: React.FC<Props> = ({ hasTimers }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '010_TimersPage' ? true : false;

  const stepsWithoutTimers: any[] = [
    {
      selector: '[id="filter_period"]',
      content: 'Filtre os tempos por período.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="filter_user_cell_account"]',
      content: 'Filtre os tempos por usuário, célula ou cliente.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="timer_views"]',
      content: 'Os tempos podem ser visualizados por data, usuário, tipo de usuário, célula e cliente.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="export_button"]',
      content: 'Exporte o relatório de tempos.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
  ];

  const stepsWithtimers: any[] = [
    {
      selector: '[id="bar_chart"]',
      content: 'No gráfico de barra estão todos os tempos diários.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="pizza_chart"]',
      content:
        'Dependendo da visibilidade escolhida de data, usuários, tipo de usuários, células ou clientes, isso altera o gráfico de pizza ou setores.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="item_timers"]',
      content: 'Clique em um dos tempos, e veja o tempo dentro da atividade.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
  ];

  const idWithoutTimers: any[] = ['filter_period', 'filter_user_cell_account', 'timer_views', 'export_button'];

  return (
    <Tour
      startAt={0}
      steps={hasTimers ? stepsWithoutTimers.concat(stepsWithtimers) : stepsWithoutTimers}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => {
        changeStep(curr, hasTimers ? [...idWithoutTimers, 'bar_chart', 'pizza_chart', 'item_timers'] : idWithoutTimers);
      }}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialTimers);
