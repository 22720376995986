import { Image, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';
import { useTranslation } from 'react-i18next';

import {
  AlertOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileSearchOutlined,
  ReloadOutlined,
  SoundOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import dragAndDropGif from '../../../../img/drag_drop.gif';
import styles from './styles.module.less';

interface Props {
  page?: string;
  hasMoreItems?: boolean;
}

const TutorialActivitiesCalendar: React.FC<Props> = ({ page, hasMoreItems }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '005_ActivitiesCalendar' ? true : false;

  const isAccountPage = page === 'account_activities' ? true : false;

  const { t } = useTranslation('tutorialCalendar');

  const steps: any[] = [
    ...[
      !isAccountPage
        ? {
            selector: '[id="icon_account_activities_calendar"]',
            content: t('icon_account_activities_calendar'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="step_activities_calendar"]',
      content: () => {
        return (
          <div style={{ marginLeft: 10 }}>
            <Typography className={styles.text}>{t('step_activities_calendar_intro')}</Typography>

            <Typography className={styles.item}>
              {<CloseCircleOutlined className={styles.icon} style={{ color: '#FF2362' }} />} {t('step_problem')}
            </Typography>
            <Typography className={styles.item}>
              {<ReloadOutlined className={styles.icon} style={{ color: '#3385FF' }} />} {t('step_execution')}
            </Typography>
            <Typography className={styles.item}>
              {<FileSearchOutlined className={styles.icon} style={{ color: '#FF5333' }} />} {t('step_check')}
            </Typography>
            <Typography className={styles.item}>
              {<UserOutlined className={styles.icon} style={{ color: '#B03591' }} />} {t('step_approval')}
            </Typography>
            <Typography className={styles.item}>
              {<SoundOutlined className={styles.icon} style={{ color: '#55A630' }} />} {t('step_finish')}
            </Typography>
            <Typography className={styles.item}>
              {<CheckCircleOutlined className={styles.icon} style={{ color: '#8f8f8f' }} />} {t('step_done')}
            </Typography>

            <Typography className={styles.text}>{t('step_activities_calendar_exception')}</Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="title_activities_calendar"]',
      content: t('title_activities_calendar'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="drag_drop_activities_calendar"]',
      content: () => {
        return (
          <div style={{ marginLeft: 10 }}>
            <div className={styles.image_div}>
              <Image
                alt="Avatar Dropdown Menu"
                width={300}
                src={dragAndDropGif}
                preview={false}
                className={styles.avatar_menu_gif}></Image>
            </div>

            <Typography className={styles.text}>{t('drag_drop_activities_calendar')}</Typography>

            <Typography className={styles.orange}>
              {<AlertOutlined />}
              {t('warning')}
            </Typography>
            <Typography className={styles.attention_text}>{t('attention_text')}</Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
  ].filter((step) => !!step);

  const ids: any[] = [
    'item_activities_calendar',
    ...[!isAccountPage ? 'icon_account_activities_calendar' : undefined],
    'step_activities_calendar',
    'title_activities_calendar',
    'see_more_activities_calendar',
    'drag_drop_activities_calendar',
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      accentColor="#ff5333"
      maskSpace={4}
      rounded={4}
      maskClassName={styles.mask}
      disableInteraction
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialActivitiesCalendar);
