import React from 'react';
import { Col, Row } from 'antd';

import styles from "./styles.module.less";
import { classifications } from '@pages/Leads/constants';

interface LeadClassificationProps {
  quantity: any;
  onChange?: (options: string[]) => void;
};

const LeadClassificationFilter: React.FC<LeadClassificationProps> = ({ quantity, onChange }) => {
  const [checked, setChecked] = React.useState<string[]>([]);
  const classificationsList = Object.entries(classifications);

  function handleSelect(key: string) {
    let newArr: string[] = [];

    if (checked.includes(key)) {
      newArr = [...checked.filter((v) => v !== key)];
    } else {
      newArr = [...checked, key];
    }

    setChecked(newArr);
    if (onChange) onChange(newArr);
  }

  return (
    <Row gutter={[8, 16]} className={styles.classificationcontainer}>
      {classificationsList.map(([key, classification]: [any, any]) => {
        const isChecked = checked.includes(key);

        return (
          <Col span={4} key={key}>
            <div 
              className={styles.classificationcontent} 
              role="button"
              style={{ backgroundColor: isChecked ? classification.color : '#ffffff' }}
              onClick={() => handleSelect(key)}
            >
              <span style={{ color: !isChecked ? 'inherit' : '#ffffff' }}>{classification.name}</span>
              <div className={styles.classificationtype}>
                <span style={{ color: !isChecked ? 'inherit' : '#ffffff' }}>{quantity?.[key]}</span>
                {React.cloneElement(
                  classification.icon, 
                  { color: !isChecked ? classification.color : '#ffffff', size: 18 }
                )}
              </div>
            </div>
          </Col>
        )}
      )}
    </Row>
  )
};

export default LeadClassificationFilter;
