import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';

import styles from './styles.module.less';
import { fields } from '@pages/Leads/constants';
import { parsePhoneNumber } from 'awesome-phonenumber';
import ErrorAndLoading from '@comp/ErrorAndLoading';

interface LeadFieldProps {
  info: string;
  type: 'mail' | 'phone' | 'custom';
}

const LeadField: React.FC<LeadFieldProps> = ({ info, type }) => {
  const [text, setText] = useState(info);
  const [additional, setAdditional] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (type === 'phone') {
      let rfc3966 = info;
      let national = info;

      const phoneObj = parsePhoneNumber(info, { regionCode: 'BR' });

      if (phoneObj.valid) {
        rfc3966 = phoneObj.number.rfc3966;
        national = phoneObj.number.national;

        setText(national);
        setAdditional({ rfc3966 });
      }
    }

    setLoading(false);
  }, [info, type]);

  const redirect = (link: string) => (window.location.href = link);

  if (loading) return <ErrorAndLoading loading />;

  return (
    <div className={styles.info}>
      {type === 'mail' && (
        <Button className={styles.infoicon} type="primary" ghost onClick={() => redirect(`mailto:${info}`)}>
          {fields.mail.icon}
        </Button>
      )}
      {type === 'phone' && (
        <>
          <a href={`https://api.whatsApp.com/send?phone=${info}`} target="_blank" rel="noopener noreferrer">
            <Button className={styles.infoicon} type="primary" ghost>
              {fields.whatsapp.icon}
            </Button>
          </a>
          <Button className={styles.infoicon} type="primary" ghost onClick={() => redirect(additional?.rfc3966)}>
            {fields.phone.icon}
          </Button>
        </>
      )}
      {type === 'custom' && <Input type="text" readOnly bordered className={styles.custominput} value={info} />}
      {type !== 'custom' && <span className={styles.infoname}>{text}</span>}
    </div>
  );
};

export default LeadField;
