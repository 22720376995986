import React, { PropsWithChildren } from 'react';

import styles from './styles.module.less';

type Value = {
  key: string;
  value: number;
  color: string;
};

interface Props {
  values: Value[];
  checked?: string[];
}

const Progress: React.FC<Props> = ({ values, checked }) => {
  const max = values.reduce((prev, curr) => prev + curr.value, 0);
  if (values.length === 0) return null;

  return (
    <div className={styles.progresscontainer}>
      {values.map((value, i) => {
        const hasChecked = checked ? checked.length > 0 : false;
        const perc = (value.value / max) * 100;

        return (
          <ValueComponent
            key={value.key}
            value={value.value}
            color={value.color}
            checked={checked ? checked.includes(value.key) : false}
            hasChecked={hasChecked}>
            {perc >= 10 && <span className={styles.valuespercentage}>{perc.toFixed(0)}%</span>}
          </ValueComponent>
        );
      })}
    </div>
  );
};

const ValueComponent: React.FC<PropsWithChildren<Value & { checked: boolean; hasChecked: boolean }>> = ({
  color,
  hasChecked,
  checked,
  value,
  children,
}) => {
  return (
    <div
      className={styles.value}
      style={{
        flex: value,
        backgroundColor: hasChecked && !checked ? 'transparent' : color,
      }}>
      {children}
    </div>
  );
};

export default Progress;
