import { NexusGenFieldTypes } from 'schema';
import TaskEvent from './TaskEvent';
import FromTo from './FromTo';

interface Props {
  data: NexusGenFieldTypes['TaskEventUpdateFeedback'];
}

const TaskEventUpdateFeedback: React.FC<Props> = ({ data }) => {
  return (
    <TaskEvent data={data}>
      alterou o feedback&nbsp;
      <FromTo fromPrefix="criado por" from={data.from_user?.name ?? data.name} />
    </TaskEvent>
  );
};

export default TaskEventUpdateFeedback;
