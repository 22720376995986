import React from 'react';
import { Button, Card } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import TutorialButton from '@comp/TutorialButton';
import LinksList from './LinksList';
import TutorialLinkList from './TutorialLinkList';

import { route } from '@router';

import styles from './styles.module.less';

const Links: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: accountId } = useParams<any>();

  const linkId = location.hash.split('/')[1];

  return (
    <>
      <Card className={styles.root}>
        <div style={{ marginBottom: 16 }}>
          <Button
            id="linkListItem"
            type="primary"
            onClick={() => navigate(route('id.home.links', { id: accountId }) + '#url_link/new')}>
            Criar novo link
          </Button>
        </div>

        <LinksList
          linkId={linkId}
          accountId={accountId ?? ''}
          setSelected={(id) => navigate(route('id.home.links', { id: accountId }) + '#url_link/' + id)}
        />

        <TutorialButton
          tourId="014_LinkList"
          top={80}
          right={20}
          placement="left"
          title="Tutorial de link de redirecionamento"
        />

        <TutorialLinkList />
      </Card>
    </>
  );
};

export default Links;
