import { NexusGenFieldTypes } from 'schema';

import { useNavigate } from 'react-router-dom';

import TaskEvent from './TaskEvent';
import { Typography } from 'antd';

interface Props {
  data: NexusGenFieldTypes['TaskEventSubTaskCreated'];
}

const TaskEventSubTaskCreated: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();

  return (
    <TaskEvent data={data}>
      criou uma subtarefa chamada&nbsp;
      {!data.subtask && <b>"{data.title}"</b>}
      {!!data.subtask && (
        <Typography.Link onClick={() => navigate(window.location.pathname + `#task/${data.subtask?.id}/task_tab`)}>
          "{data.subtask.title}"
        </Typography.Link>
      )}
    </TaskEvent>
  );
};

export default TaskEventSubTaskCreated;
