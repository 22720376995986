import { NexusGenFieldTypes } from 'schema';

import TaskEvent from './TaskEvent';
import FromTo from './FromTo';

import { parseDate } from '@logic/functions/date';

interface Props {
  data: NexusGenFieldTypes['TaskEventFinishDateChanged'];
}

const TaskEventFinishDateChanged: React.FC<Props> = ({ data }) => {
  const from = data.old_finish_date ? parseDate(data.old_finish_date).toFormat('dd/LL/yyyy') : null;
  const to = data.new_finish_date ? parseDate(data.new_finish_date).toFormat('dd/LL/yyyy') : 'em branco';

  return (
    <TaskEvent data={data}>
      alterou a data de publicação <FromTo from={from} to={to} />
    </TaskEvent>
  );
};

export default TaskEventFinishDateChanged;
