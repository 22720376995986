import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';

import {
  CheckCircleOutlined,
  ReloadOutlined,
  UserOutlined,
  SoundOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';

import ActivitiesLists from './ActivitiesLists';
import ActivitiesCalendar from './ActivitiesCalendar';
import ActivitiesDashboard from './ActivitiesDashboard';

import { route } from '@router';
import useConfig from '@logic/config';

import {
  task_info,
  my_tasks_list,
  realm_tasks_list,
  selected_account_tasks_list,
  my_current_task_list,
} from './tasksQueries';

interface Props {
  page: 'my_activities' | 'account_activities' | 'realm_activities' | 'home_dashboard';
}

const Activities: React.FC<Props> = ({ page }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const config = useConfig();

  const task = location.hash.split('/')[1];

  const { id: paramAccountId } = useParams<any>();

  const view = `${config.tasks_view || 'show_as_calendar'}`;

  const [fetchTask, { data }] = useLazyQuery(task_info);

  // Ids da conta e da atividade em qualquer rota
  const { accountId, taskId } = useMemo(
    () => ({
      accountId: paramAccountId || data?.task?.account?.id || '',
      taskId: task,
    }),
    [paramAccountId, task, data],
  );

  // Se há uma atividade aberta, busca as informações da mesma
  useEffect(() => {
    if (!taskId || taskId === 'all') return;

    fetchTask({ variables: { id: taskId } });
  }, [taskId, fetchTask]);

  // Toda a navegação de lista, calendário e subtarefas
  const tasksRouter = useCallback(
    (taskId: string, date?: string) => {
      let path = '';

      switch (page) {
        case 'account_activities':
          path = route('id.home.tasks', { id: accountId });
          break;
        case 'my_activities':
          path = route('activities');
          break;
        case 'realm_activities':
          path = route('realm.activities');
          break;
        case 'home_dashboard':
          path = '/';
          break;
      }

      navigate(path + '#task/' + taskId + '/task_tab' + (!!date ? '/' + date : ''));
    },
    [page, navigate, accountId],
  );

  // parametros das queries de acordo com a tela
  const variables = useMemo(() => {
    let vars: any = {
      begin: config.tasks_filter_begin ? config.tasks_filter_begin : null,
      end: config.tasks_filter_end ? config.tasks_filter_end : null,
      users: config.tasks_filter_users,
      steps: config.tasks_filter_steps,
      limit: -1,
      page: 1,
      excludeSubtasks: config.tasks_filter_subtasks,
      excludeAllExceptTasksWithPendingFeedbacks: false,
    };

    switch (page) {
      case 'account_activities':
        vars = { ...vars, id: accountId };
        break;
      case 'my_activities':
        vars = { ...vars };
        break;
      case 'realm_activities':
        vars = {
          ...vars,
          users: ((config.tasks_filter_users as any) || []).length > 0 ? config.tasks_filter_users : undefined,
          cells: ((config.tasks_filter_cells as any) || []).length > 0 ? config.tasks_filter_cells : undefined,
        };
        break;
      case 'home_dashboard':
        vars = { ...vars, begin: null, end: null };
        break;
    }

    return vars;
  }, [
    accountId,
    page,
    config.tasks_filter_begin,
    config.tasks_filter_end,
    config.tasks_filter_users,
    config.tasks_filter_steps,
    config.tasks_filter_subtasks,
    config.tasks_filter_cells,
  ]);

  const query = useMemo(() => {
    switch (page) {
      case 'account_activities':
        return selected_account_tasks_list;
      case 'my_activities':
        return my_tasks_list;
      case 'realm_activities':
        return realm_tasks_list;
      case 'home_dashboard':
        return my_current_task_list;
    }
  }, [page]);

  // Extrai os dados de cada query específica da respectiva tela
  const getData = useCallback(
    (data: any) => {
      switch (page) {
        case 'account_activities':
          return { data: data.account?.tasks?.data, total: data.account?.tasks?.total };
        case 'my_activities':
          return { data: data.me?.tasks?.data, total: data.me?.tasks?.total };
        case 'realm_activities':
          return { data: data.me?.realm?.tasks?.data, total: data.me?.realm?.tasks?.total };
        case 'home_dashboard':
          return { data: data.me?.tasks?.data, total: data.me?.tasks?.total };
      }
    },
    [page],
  );

  return (
    <>
      {page !== 'home_dashboard' && view === 'show_as_calendar' && (
        <ActivitiesCalendar
          page={page}
          accountId={accountId}
          setSelected={tasksRouter}
          query={query}
          variables={{ ...variables }}
          getData={getData}
          view={view}
        />
      )}

      {page !== 'home_dashboard' && view !== 'show_as_calendar' && (
        <ActivitiesLists
          page={page}
          accountId={accountId}
          setSelected={tasksRouter}
          query={query}
          variables={{ ...variables }}
          getData={getData}
          view={view}
        />
      )}

      {page === 'home_dashboard' && (
        <ActivitiesDashboard
          page={page}
          accountId={accountId}
          taskId={taskId}
          setSelected={tasksRouter}
          query={query}
          params={{ ...variables }}
          getData={getData}
        />
      )}
    </>
  );
};

export default observer(Activities);

export const stepsKeys = [
  {
    key: 'execution',
    step: ['execution'],
    title: 'Em execução',
    tagLabel: 'Execução',
    //color: '#2A6F97',
    //color: '#1982C4',
    //color: '#219EBC',
    color: '#3385FF',
  },
  {
    key: 'check',
    step: ['check'],
    title: 'Em revisão',
    tagLabel: 'Revisão',
    //color: '#E07A5F',
    //color: '#E76F00',
    //color: '#F4A259',
    color: '#FF5333',
  },
  {
    key: 'approval',
    step: ['approval'],
    title: 'Em aprovação',
    tagLabel: 'Aprovação',
    //color: '#69306D',
    //color: '#6A4C93',
    //color: '#A5668B',
    color: '#B03591',
  },
  {
    key: 'finish',
    step: ['finish'],
    title: 'Publicação',
    tagLabel: 'Publicação',
    //color: '#52796F',
    //color: '#2A9D67',
    //color: '#8CB369',
    color: '#55A630',
  },
  {
    key: 'done',
    step: ['done'],
    title: 'Finalizado',
    tagLabel: 'Finalizado',
    color: '#8f8f8f',
  },
];

export const stepsIconsArray = (style?: any) => [
  <ReloadOutlined style={style} />,
  <FileSearchOutlined style={style} />,
  <UserOutlined style={style} />,
  <SoundOutlined style={style} />,
  <CheckCircleOutlined style={style} />,
];

export const stepsKeysArray = ['execution', 'check', 'approval', 'finish', 'done'];
