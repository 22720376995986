import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Typography } from 'antd';
import moment from 'moment';

import { PlusOutlined } from '@ant-design/icons';
import { BiBook, BiUserCheck } from 'react-icons/bi';
import { FiLayers } from 'react-icons/fi';

import {
  AccountName,
  ChangeViews,
  DateFilter,
  EditMultipleButton,
  FilterMine,
  FilterUserAndCell,
  HideWeekend,
  PeriodFilter,
  StepsFilter,
  SubtasksFilter,
} from './Filters';
import Help from '@comp/Help';

import useConfig from '@logic/config';
import { route } from '@router';

import styles from './styles.module.less';

interface Props {
  accountId: string;
  params: any;
  page: 'my_activities' | 'account_activities' | 'realm_activities' | 'home_dashboard';
  newTask: () => void;
  view: string;
  value?: moment.Moment;
  onChange?: (date: moment.Moment) => void;
  totalWeekendActivities?: number;
}

const ActivitiesFilters: React.FC<Props> = ({
  value,
  onChange,
  page,
  accountId,
  newTask,
  params,
  totalWeekendActivities,
}) => {
  const config = useConfig();
  const navigate = useNavigate();

  const { t } = useTranslation('activitiesFilters');

  const view = config.tasks_view;

  return (
    <Row className={styles.filters}>
      <Col className={styles.filters}>
        {/* {view !== 'show_as_calendar' && page === 'account_activities' && (
          <Col style={{ marginRight: 6 }}>
            <Button
              title="Criar atividade"
              type="primary"
              icon={<PlusOutlined style={{ fontSize: 20, padding: 0 }} />}
              onClick={newTask}
              id="drawerException"
            />
          </Col>
        )}

        {view !== 'show_as_calendar' && page === 'account_activities' && (
          <Col style={{ marginRight: 6 }}>
            <Button
              type="primary"
              className={styles.blue_button}
              title="Templates"
              icon={<BiBook style={{ fontSize: 24, paddingTop: 4 }} />}
              onClick={() => history.push(route('id.home.templates', { id: accountId }))}
            />
          </Col>
        )} */}

        <Col style={{ marginRight: 6 }}>
          {view !== 'show_as_calendar' && page === 'realm_activities' && (
            <Typography.Title
              style={{ fontSize: 20, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <FiLayers style={{ marginRight: 12, marginLeft: 4, marginBottom: -2 }} size={24} />
              {t('general_view')}
              <Help
                title={t('general_view')}
                description={t('general_view_description')}
                placement="right"
                iconStyle={{ color: '#5c5c5c', fontSize: 12, marginBottom: -4, marginLeft: 6 }}
              />
            </Typography.Title>
          )}

          {page === 'account_activities' && <AccountName accountId={accountId} />}

          {view !== 'show_as_calendar' && page === 'my_activities' && (
            <Typography.Title
              style={{ fontSize: 20, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <BiUserCheck style={{ marginRight: 6 }} size={24} />
              {t('my_tasks')}
              <Help
                title={t('my_tasks')}
                description={t('my_tasks_description')}
                placement="right"
                iconStyle={{ color: '#5c5c5c', fontSize: 12, marginBottom: -4, marginLeft: 4 }}
              />
            </Typography.Title>
          )}
        </Col>

        <Col>{view === 'show_as_calendar' && <StepsFilter params={params} />}</Col>
      </Col>

      <Col className={styles.filters}>
        <Col style={{ marginLeft: 6 }}>
          <div id="create_task">
            <Button
              title={t('create_task')}
              type="primary"
              icon={<PlusOutlined style={{ fontSize: 20, padding: 0 }} />}
              onClick={newTask}
              id="drawerException"
            />
          </div>
        </Col>

        {page === 'account_activities' && (
          <Col style={{ marginLeft: 6 }}>
            <Button
              id="show_account_templates"
              type="primary"
              className={styles.blue_button}
              title={t('show_account_templates')}
              icon={<BiBook style={{ fontSize: 24, paddingTop: 4 }} />}
              onClick={() => navigate(route('id.home.templates', { id: accountId }))}
            />
          </Col>
        )}

        <Col style={{ marginLeft: 6 }}>
          <EditMultipleButton />
        </Col>

        {view !== 'show_by_templates' && <PeriodFilter params={params} value={value} onChange={onChange} />}

        {page === 'realm_activities' && (
          <Col style={{ marginLeft: 6 }}>
            <FilterUserAndCell params={params} page={page} />
          </Col>
        )}

        {page === 'account_activities' && (
          <Col>
            <FilterMine params={params} page={page} />
          </Col>
        )}

        <Col>
          <SubtasksFilter params={params} />
        </Col>

        <Col>
          <ChangeViews />
        </Col>

        {view === 'show_as_calendar' && (
          <Col>
            <DateFilter />
          </Col>
        )}

        {view === 'show_as_calendar' && (!!totalWeekendActivities || totalWeekendActivities === 0) && (
          <Col>
            <HideWeekend totalWeekendActivities={totalWeekendActivities} />
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default observer(ActivitiesFilters);
