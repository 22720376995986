import { Image, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { AlertOutlined } from '@ant-design/icons';
import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import rolesGif from '../../../../../img/roles_realm.gif';
import styles from './styles.module.less';

interface Props {
  hasCell: boolean;
  hasAccount: boolean;
  addCellButton: boolean;
  addAccountButton: boolean;
  role: string[];
}

const TutorialEditUser: React.FC<Props> = ({ hasAccount, hasCell, addAccountButton, addCellButton, role }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '021_EditUserManagement' ? true : false;

  const roleAllowsCell = role.includes('customer_success') || role.includes('media_tester') || role.includes('creator');
  const roleAllowsAccount = role.includes('customer');
  const roleIsAdmin = role.includes('admin');

  const steps: any[] = [
    {
      selector: '[id="avatar_user"]',
      content: 'Para adicionar uma foto ou imagem para o usuário, clique sobre o avatar.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="role_user"]',
      content: () => {
        return (
          <div className={styles.avatar_menu}>
            <div className={styles.image_div}>
              <Image
                alt="Avatar Dropdown Menu"
                width={300}
                src={rolesGif}
                preview={false}
                className={styles.avatar_menu_gif}></Image>
            </div>

            <Typography>Você pode adicionar ou remover um novo tipo de usuário, clique e veja as opções</Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="status_user"]',
      content:
        'O status do usuário pode ser ativo ou inativo. Quando ativo o usuário consegue acessar a IDLab, caso contrário, ele não consegue.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="name_user"]',
      content: 'Para alterar o nome do usuário, digite no campo "Nome".',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="email_user"]',
      content: () => {
        return (
          <div style={{ marginLeft: 10 }}>
            <Typography className={styles.text}>
              No campo "E-mail" altere o e-mail atual do usuário, inserindo o novo e-mail.
            </Typography>

            <Typography className={styles.orange}>{<AlertOutlined />} Atenção!</Typography>
            <Typography className={styles.attention_text}>
              O e-mail deve ser único, ou seja, os usuários não podem ter e-mails que já estão sendo utilizados por
              outros usuários na IDLab.
            </Typography>
          </div>
        );
      },
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="password_user"]',
      content: 'Para alterar a senha, ela deve ter no mínimo 8 caracteres.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="confirm_password_user"]',
      content: 'Confirme a senha, deve ser a mesma digitada no campo senha.',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="save_user"]',
      content: 'Ao terminar as alterações, clique em "Salvar".',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      roleAllowsCell && addCellButton
        ? {
            selector: '[id="add_cell_user"]',
            content: 'Adicione o usuário a uma nova célula.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      roleAllowsCell && hasCell
        ? {
            selector: '[id="open_cell_user_button"]',
            content: 'Você pode abrir o perfil da célula em "Visualizar".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      roleAllowsCell && hasCell
        ? {
            selector: '[id="open_cell_user"]',
            content: 'Ou clique sobre a célula, que você também pode ver o perfil dele.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      roleAllowsCell && hasCell && !roleIsAdmin
        ? {
            selector: '[id="delete_user_cell"]',
            content: 'Remova o usuário da célula, clicando em "Remover".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      roleAllowsAccount && addAccountButton
        ? {
            selector: '[id="add_account_user"]',
            content: 'Adicione um novo cliente ao usuário.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      roleAllowsAccount && hasAccount
        ? {
            selector: '[id="open_account_user_button"]',
            content: 'Você pode abrir o perfil do cliente em "Visualizar".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      roleAllowsAccount && hasAccount
        ? {
            selector: '[id="open_account_user"]',
            content: 'Ou clique sobre o cliente, que você também pode ver o perfil dele.',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      roleAllowsAccount && hasAccount
        ? {
            selector: '[id="delete_account_user"]',
            content: 'Remova o usuário do cliente, clicando em "Remover".',
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    'avatar_cell',
    'name_cell',
    ...[roleAllowsCell && addCellButton ? 'add_user_cell' : undefined],
    ...[roleAllowsCell && hasCell ? 'open_user_cell_button' : undefined],
    ...[roleAllowsCell && hasCell ? 'open_user_cell' : undefined],
    ...[roleAllowsCell && hasCell && !roleIsAdmin ? 'delete_user_cell' : undefined],
    ...[roleAllowsAccount && addAccountButton ? 'add_account_cell' : undefined],
    ...[roleAllowsAccount && hasAccount ? 'open_account_cell_button' : undefined],
    ...[roleAllowsAccount && hasAccount ? 'open_account_cell' : undefined],
    ...[roleAllowsAccount && hasAccount ? 'delete_account_cell' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      disableInteraction
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialEditUser);
