import React, { useMemo } from 'react';
import { Collapse, Empty } from 'antd';
import { Duration } from 'luxon';
import FlatList from 'flatlist-react';

import TimerListItem from './TimerListItem';
import AvatarPicture from '@comp/AvatarPicture';

import { parseDateTime } from '@logic/functions/date';

import styles from './styles.module.less';

interface Props {
  data: any[];
  totals: any;
  view: string;
  refetch: () => void;
  showUsersAvatar: boolean;
}

const TimersListMap: React.FC<Props> = ({ data, totals, view, refetch, showUsersAvatar }) => {
  const timersObject = useMemo(
    () =>
      data.reduce((acc, curr, idx) => {
        const start = parseDateTime(curr.start);

        let key = '';
        let label = '';
        let photo = '';

        switch (view) {
          case 'date':
            key = start.toFormat('yyyy_LL_dd');
            label = start.toFormat("dd 'de' LLL");
            break;
          case 'user':
            key = curr.userId;
            label = curr.userName;
            photo = curr.userAvatar;
            break;
          case 'user_type':
            key = curr.userType;
            label = userTypeDivisor(curr.userType);
            break;
          case 'cell':
            key = curr.cellId;
            label = curr.cellName;
            photo = curr.cellLogo;
            break;
          case 'account':
            key = curr.clientId;
            label = curr.clientName;
            photo = curr.clientLogo;
            break;
        }

        if (!acc[key] || acc[key].data?.length === 0) {
          acc[key] = { data: [], label, photo };
        }

        const accKey = acc[key];

        return { ...acc, [key]: { ...accKey, data: [...accKey.data, curr] } };
      }, {}),
    [data, view],
  );

  return (
    <>
      {data.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ margin: 0, paddingTop: 8, paddingBottom: 8 }}
          description="Nenhum tempo registrado com estes filtros"
        />
      )}

      <Collapse
        defaultActiveKey={Object.keys(timersObject)[0]}
        destroyInactivePanel
        accordion
        bordered={false}
        className={styles.collapse_timers}
        expandIconPosition="right">
        {Object.keys(timersObject).map((key) => {
          const target = view === 'user' ? 'User' : view === 'cell' ? 'Cell' : 'Account';
          return (
            <Collapse.Panel
              key={key}
              prefixCls="timers"
              header={
                <span className={styles.divisor}>
                  {['user', 'cell', 'account'].includes(view) && (
                    <div style={{ margin: '-1px 6px 0 0' }}>
                      <AvatarPicture
                        pictureLink={timersObject[key].photo}
                        target={target}
                        size={24}
                        name={timersObject[key].label}
                      />
                    </div>
                  )}
                  {timersObject[key].label}
                  &nbsp;&nbsp;&nbsp;
                  <b>{Duration.fromMillis(totals[view][key]).toFormat('hh:mm:ss')}</b>
                </span>
              }>
              <TimersList
                data={timersObject[key].data}
                refetch={refetch}
                view={view}
                showUsersAvatar={showUsersAvatar}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </>
  );
};

export default TimersListMap;

function userTypeDivisor(type: 'admin' | 'customer_success' | 'creator' | 'media_tester' | 'customer') {
  switch (type) {
    case 'admin':
      return 'Administrador';
    case 'customer_success':
      return 'Sucesso de cliente';
    case 'creator':
      return 'Criação';
    case 'media_tester':
      return 'Media tester';
    case 'customer':
      return 'Cliente';
  }
}

interface TimersListProps {
  data: any[];
  view: string;
  showUsersAvatar: boolean;
  refetch: () => void;
}

const TimersList: React.FC<TimersListProps> = ({ data, view, refetch, showUsersAvatar }) => {
  return (
    <FlatList
      list={data}
      renderOnScroll
      renderItem={({
        id,
        activity,
        start,
        end,
        taskId,
        clientId,
        clientName,
        clientLogo,
        userAvatar,
        userName,
      }: any) => (
        <TimerListItem
          id={id}
          key={id}
          refresh={refetch}
          activity={activity}
          userName={userName}
          userAvatar={userAvatar}
          showUserAvatar={view !== 'user' && showUsersAvatar}
          showAccountLogo={view !== 'account'}
          client={clientName}
          task={taskId}
          clientId={clientId}
          clientLogo={clientLogo}
          start={start}
          end={end}
          showDate={view !== 'date'}
        />
      )}
    />
  );
};
