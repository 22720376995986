import activitiesFilter from './activitiesFilter.json';
import tutorialTooltip from './tutorialTooltip.json';
import accountSelector from './components/accountSelector/index.json';
import accountsAppBar from './components/appBar/accountsAppBar.json';
import adminAppBar from './components/appBar/adminAppBar.json';
import reportsAppBar from './components/appBar/reportsAppBar.json';
import cellSelector from './components/cellSelector/index.json';
import errorLoading from './components/errorLoading/index.json';
import listWithAddTo from './components/listWithAddTo/index.json';
import newFunctionalities from './components/newFunctionalities/index.json';
import newVersionButton from './components/newVersionButton/index.json';
import profileMenu from './components/profileMenu/index.json';
import sideMenu from './components/sideMenu/index.json';
import upload from './components/upload/index.json';
import uploadAvatar from './components/uploadAvatar/index.json';
import userTimer from './components/userTimer/index.json';
import searchBar from './components/searchBar.json';
import logic from './logic/index.json';
import rightMenu from './components/appBar/rightMenu.json';
import loginButtons from './pages/accountsPage/editAccount/loginButtons/index.json';
import editAccount from './pages/accountsPage/editAccount/index.json';
import newAccount from './pages/accountsPage/newAccount/index.json';
import accountsPage from './pages/accountsPage/index.json';
import activitiesCalendar from './pages/activities/activitiesCalendar/index.json';
import tutorialCalendar from './pages/activities/activitiesCalendar/tutorialCalendar/index.json';
import activitiesDashboard from './pages/activities/activitiesDashboard/index.json';
import filters from './pages/activities/activitiesFilters/filters/index.json';
import editMultiple from './pages/activities/activitiesFilters/filters/editMultiple/index.json';
import tutorialFilters from './pages/activities/activitiesFilters/tutorialFilters/index.json';
import activitiesFilters from './pages/activities/activitiesFilters/index.json';
import activityActions from './pages/activities/activitiesLists/activitiesList/activityListItem/activityActions/index.json';
import activityDates from './pages/activities/activitiesLists/activitiesList/activityListItem/activityDates/index.json';
import activityResponsibles from './pages/activities/activitiesLists/activitiesList/activityListItem/activityResponsibles/index.json';
import activityStep from './pages/activities/activitiesLists/activitiesList/activityListItem/activityStep/index.json';
import activityListItem from './pages/activities/activitiesLists/activitiesList/activityListItem/index.json';
import newActivity from './pages/activities/activitiesLists/activitiesList/newActivity/index.json';
import activitiesList from './pages/activities/activitiesLists/activitiesList/index.json';
import facebookAccounts from './pages/realmPage/facebookAccounts/index.json';
import googleAccounts from './pages/realmPage/googleAccounts/index.json';
import realmPage from './pages/realmPage/index.json';
import tutorialRealmManagement from './pages/realmPage/tutorialRealmManagement/index.json';
import tutorialEditAccount from './pages/accountsPage/editAccount/tutorialEditAccount/index.json';
import activitiesListBySteps from './pages/activities/activitiesLists/activitiesListBySteps/index.json';
import activitiesListByTemplate from './pages/activities/activitiesLists/activitiesListByTemplates/index.json';
import tutorialActivitiesList from './pages/activities/activitiesLists/TutorialActivitiesList/index.json';
import activitiesLists from './pages/activities/activitiesLists/index.json';
import assignmentTab from './pages/activities/task/assignmentTask/assignmentTab/index.json';
import newAssignment from './pages/activities/task/assignmentTask/newAssignment/index.json';
import assignmentTask from './pages/activities/task/assignmentTask/index.json';
import editTimer from './pages/activities/task/clockTab/editTimer/index.json';
import tutorialClockTab from './pages/activities/task/clockTab/tutorialClockTab/index.json';
import clockTab from './pages/activities/task/clockTab/index.json';
import newTask from './pages/activities/task/defaultTask/newTask/index.json';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  activitiesFilter,
  tutorialTooltip,
  accountSelector,
  accountsAppBar,
  adminAppBar,
  reportsAppBar,
  cellSelector,
  errorLoading,
  listWithAddTo,
  newFunctionalities,
  newVersionButton,
  profileMenu,
  sideMenu,
  upload,
  uploadAvatar,
  userTimer,
  searchBar,
  logic,
  rightMenu,
  loginButtons,
  editAccount,
  newAccount,
  accountsPage,
  activitiesCalendar,
  tutorialCalendar,
  activitiesDashboard,
  filters,
  editMultiple,
  tutorialFilters,
  activitiesFilters,
  activityActions,
  activityDates,
  activityResponsibles,
  activityStep,
  activityListItem,
  newActivity,
  activitiesList,
  facebookAccounts,
  googleAccounts,
  realmPage,
  tutorialRealmManagement,
  tutorialEditAccount,
  activitiesListBySteps,
  activitiesListByTemplate,
  tutorialActivitiesList,
  activitiesLists,
  assignmentTab,
  newAssignment,
  assignmentTask,
  editTimer,
  tutorialClockTab,
  clockTab,
  newTask,
};
