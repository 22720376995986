import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';
import { useTranslation } from 'react-i18next';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  hasTimers?: boolean;
}

const TutorialClockTab: React.FC<Props> = ({ hasTimers }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '008_ClockTab' ? true : false;

  const { t } = useTranslation('tutorialClockTab');

  const isAdmin = !!store.profile && store.profile.roles.includes('admin') ? true : false;

  const steps: any[] = [
    {
      selector: '[id="start_timer"]',
      content: t('start_timer'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="create_timer"]',
      content: t('create_timer'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      hasTimers
        ? {
            selector: '[id="edit_timer"]',
            content: isAdmin ? t('edit_timer_admin') : t('edit_timer'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = ['start_timer', 'create_timer', ...[hasTimers ? 'edit_timer' : undefined]].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('ant-tabs-content-holder', 'class_name')}
      onBeforeClose={() => enableScroll('ant-tabs-content-holder', 'class_name')}
    />
  );
};

export default observer(TutorialClockTab);
