import React, { useState, useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { List, Empty } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import InfoItem from './InfoItem';

import { selected_account_infos_list } from '@logic/queries';
import { useStore } from '@logic/context';

interface Props {
  infoId: string;
  accountId?: string;
  setSelected: (id: string) => void;
}

const InfosList: React.FC<Props> = ({ infoId, accountId, setSelected }) => {
  const [infos, setInfos] = useState<any[]>();

  const store = useStore();

  //------------------------------ QUERIES ---------------------------------------

  const [fetchInfos, { data, loading, error, refetch }] = useLazyQuery(selected_account_infos_list, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    fetchInfos({ variables: { id: accountId } });
  }, [accountId, fetchInfos, infoId]);

  useEffect(() => {
    if (!data) return;

    setInfos(data.account?.infos?.data);
    store.setHasInfo(data.account?.infos?.data.length > 0);
  }, [data, infos, store]);

  const refetchInfos = useCallback(() => {
    if (refetch) refetch();
  }, [refetch]);

  //------------------------------------------------------------------------------

  if (error) return <ErrorAndLoading error={error} borderless />;

  return (
    <List size="small" style={{ margin: 0 }} loading={loading && !infos}>
      {data && (!infos || infos.length === 0) && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ margin: 0, paddingTop: 8, paddingBottom: 8 }}
          description="Não existem notas nessa conta"
        />
      )}

      {!!infos &&
        infos.length > 0 &&
        infos.map(({ id, title }: any, idx: number) => (
          <div id="infoListItem" key={`${id}_${idx}`}>
            <InfoItem infoId={id} title={title} setSelected={(id) => setSelected(id)} refresh={refetchInfos} />
          </div>
        ))}
    </List>
  );
};

export default InfosList;
