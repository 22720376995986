import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { Card, Button, Form, Input, notification, Typography, Row, Col } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';

import { update_mailerlite_apikey, sync_leads, add_mailerlite_field, delete_mailerlite_field } from '@logic/mutations';
import { accounts_info } from '@logic/queries';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';
import Help from '@comp/Help';

const Info: React.FC = () => {
  const [accountInfo, setAccountInfo] = useState<{
    name: string;
    status: string;
    journey: string;
    monthly_planned_seconds: number;
    mailerlite_apikey: string;
  }>();

  const { t } = useTranslation('editAccount');

  const [form] = Form.useForm();
  const { id: account } = useParams<any>();

  const [mailerliteField, setMailerliteField] = useState({ key: '', name: '' });

  //---------------------- QUERIES & MUTATIONS -----------------------------

  //----------------------- INFO ------------------------

  const [UpdateMailerliteApiKey] = useMutation(update_mailerlite_apikey);
  const { data, loading, error, refetch } = useQuery(accounts_info, {
    variables: { id: account },
    fetchPolicy: 'network-only',
  });

  const [SyncLeads] = useMutation(sync_leads);
  const [AddMailerliteField] = useMutation(add_mailerlite_field);
  const [DeleteMailerliteField] = useMutation(delete_mailerlite_field);

  //--------- cria o array para o select de celulas e de usuários -------------

  useEffect(() => {
    if (!data) return;

    const newAccountInfo = data.account;
    setAccountInfo({
      name: newAccountInfo.name,
      status: newAccountInfo.status,
      journey: newAccountInfo.journey,
      monthly_planned_seconds: newAccountInfo.monthly_planned_seconds / 3600,
      mailerlite_apikey: newAccountInfo.mailerlite_apikey,
    });
  }, [data]);

  useLayoutEffect(() => {
    if (!accountInfo) return;

    form.setFieldsValue({
      name: accountInfo.name,
      status: accountInfo.status,
      journey: accountInfo.journey,
      monthly_planned_seconds: accountInfo.monthly_planned_seconds,
      mailerlite_apikey: accountInfo.mailerlite_apikey,
    });
  }, [accountInfo, form]);

  //----------------- Save, delete, edit functions -----------------------------

  async function doSave(account: string, mailerlite_apikey: string) {
    try {
      const res = await UpdateMailerliteApiKey({
        variables: { account, mailerlite_apikey },
      });

      notification.open({
        type: 'success',
        message: t('update_account_message'),
        description: res.data?.updateAccount?.name,
      });
      refetch({ id: account });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then((values) => {
        doSave(account ?? '', values.mailerlite_apikey);
        form.resetFields();
      })
      .catch((info) => {
        notification.open({
          type: 'error',
          ...errorMessage('form_validation', info),
        });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    notification.open({
      type: 'error',
      ...errorMessage('', errorInfo),
    });
  };

  async function doSyncLeads() {
    try {
      await SyncLeads({ variables: { account } });

      notification.open({
        type: 'success',
        message: 'Sincronização iniciada',
        description: 'Dependendo da quantidade de leads, este processo pode levar vários minutos.',
      });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  async function doAddMailerliteField() {
    try {
      await AddMailerliteField({ variables: { account, key: mailerliteField.key, name: mailerliteField.name } });

      setMailerliteField({ key: '', name: '' });

      notification.open({
        type: 'success',
        message: 'Campo adicionado',
        description: `O campo "${mailerliteField.key}" foi adicionado com sucesso`,
      });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  async function doDeleteMailerliteField(key: string) {
    try {
      await DeleteMailerliteField({ variables: { account, key: key } });

      notification.open({
        type: 'success',
        message: 'Campo removido',
        description: `O campo "${key}" foi removido com sucesso`,
      });
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  //----------------------------------------------------------------------
  if (loading) return <ErrorAndLoading loading={loading} />;
  if (error) return <ErrorAndLoading error={error} />;

  const mailerliteFields = data?.account?.mailerlite_fields;

  return (
    <>
      <Card style={{ marginBottom: '16px' }}>
        <Row style={{ width: '100%' }} gutter={0}>
          <Col span={24}>
            <div className={styles.div_avatar}>
              <div className={styles.form}>
                <Typography.Title style={{ fontSize: '20px' }}>Configurações</Typography.Title>

                <br />

                <Form
                  form={form}
                  labelCol={{
                    span: 2,
                    style: {
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      minWidth: 120,
                    },
                  }}
                  wrapperCol={{ style: { display: 'flex', flex: 1 } }}
                  layout="horizontal"
                  name="account_edit"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={{
                    name: accountInfo?.name || '',
                    status: accountInfo?.status || '',
                    monthly_planned_seconds: accountInfo?.monthly_planned_seconds || '',
                    mailerlite_apikey: accountInfo?.mailerlite_apikey || '',
                  }}>
                  <div id="mailerlite_key" style={{ display: 'flex' }}>
                    <div style={{ flex: 1, paddingRight: 4 }}>
                      <Form.Item name="mailerlite_apikey" label={t('mailerlite')}>
                        <Input />
                      </Form.Item>
                    </div>

                    <Button type="default" onClick={() => doSyncLeads()}>
                      Sincronizar leads
                    </Button>
                  </div>

                  <Form.Item noStyle>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        id="save_button"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{ marginLeft: 6 }}>
                        {t('save_button')}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Card>

      <Card style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex' }}>
          <Typography.Title level={4}>Campos Mailerlite</Typography.Title>
          <Help
            title="Campos personalizados"
            description="Cadastre os campos personalizados do Mailerlite para serem exibidos na ID Action"
            iconStyle={{ color: '#5c5c5c', fontSize: 12, position: 'relative', left: 4, top: 9 }}
          />
        </div>

        <div>
          {mailerliteFields && mailerliteFields.length === 0 && (
            <div
              style={{
                textAlign: 'center',
                backgroundColor: '#f6f6f6',
                borderRadius: 4,
                padding: 8,
              }}>
              Não há campos personalizados cadastrados
            </div>
          )}

          {mailerliteFields &&
            mailerliteFields.map((field: any) => (
              <div key={field.key} style={{ display: 'flex', marginBottom: 4, alignItems: 'center' }}>
                <div
                  style={{
                    flex: 1,
                    border: '1px solid #ddd',
                    padding: '4px 10px',
                    borderRadius: 2,
                    backgroundColor: '#f6f6fa',
                  }}>
                  {field.key}
                </div>
                <div style={{ width: 8 }} />
                <div
                  style={{
                    flex: 1,
                    border: '1px solid #ddd',
                    padding: '4px 10px',
                    borderRadius: 2,
                    backgroundColor: '#f6f6fa',
                  }}>
                  {field.name}
                </div>
                <div style={{ width: 8 }} />
                <Button onClick={() => doDeleteMailerliteField(field.key)}>Remover</Button>
              </div>
            ))}

          <Typography.Title level={5} style={{ marginTop: 36 }}>
            Adicionar novo campo personalizado
          </Typography.Title>

          <div style={{ display: 'flex' }}>
            <Input
              placeholder="Key"
              value={mailerliteField.key}
              onChange={({ target }) => setMailerliteField((f) => ({ ...f, key: target.value }))}
            />
            <div style={{ width: 12 }} />
            <Input
              placeholder="Nome"
              value={mailerliteField.name}
              onChange={({ target }) => setMailerliteField((f) => ({ ...f, name: target.value }))}
            />
            <div style={{ width: 12 }} />
            <Button onClick={() => doAddMailerliteField()}>Adicionar</Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Info;
