import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {} from 'luxon';
import { Typography, Button, Divider } from 'antd';
import ErrorAndLoading from '@comp/ErrorAndLoading';
import Help from '@comp/Help';
import EditFeedback from './EditFeedback';

import { task_feedbacks } from '@logic/queries';

import styles from './styles.module.less';
import { useStore } from '@logic/context';

interface Props {
  taskId: string;
}

const FeedbacksTab: React.FC<Props> = ({ taskId }) => {
  const [all, setAll] = useState(false);
  const [feedbacks, setFeedbacks] = useState<any[]>();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const store = useStore();

  //--------------------------- Queries and mutations --------------------------

  //  FIXME  feedback some depois de salvar o arquivo
  const { data, loading, error, fetchMore, refetch } = useQuery(task_feedbacks, {
    variables: { id: taskId, limit: 10, page: page, status: ['resolved', 'unresolved'] },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!data) {
      setPage(1);
      setTotal(0);
      setFeedbacks(undefined);
      return;
    }

    setFeedbacks(data.task?.feedbacks?.data);
    setTotal(data.task?.feedbacks?.total);
    store.setHasFeedback(data.task?.feedbacks?.data?.length > 0);
  }, [data, store, all]);

  useEffect(() => {
    if (!!feedbacks) return store.setHasPendingFeedback(feedbacks.some((item: any) => item.status === 'unresolved'));
  }, [feedbacks, store]);

  //------------------------------------------------------------------------------

  if (error) return <ErrorAndLoading error={error} />;

  if (loading) return <ErrorAndLoading loading={loading} />;

  if (feedbacks && feedbacks.length > 0) {
    return (
      <div>
        <Divider />

        <div className={styles.title}>
          <Typography.Text className={styles.feedbacksDivisor}>Feedbacks</Typography.Text>

          <Help
            title="Feedbacks"
            description="São respostas do cliente ou de sucesso de cliente referente a atividade, que até serem aplicadas permanecem como 'Pendente', após a execução pode ser alterada para 'Concluído'"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            placement="right"
          />
        </div>

        <div style={{ height: 16 }} />

        {feedbacks.map((f: any, idx: number) => {
          const isResolved = f.status === 'resolved';

          if (!all && isResolved) return null;

          return (
            <EditFeedback
              taskId={taskId}
              key={f.id}
              id={f.id}
              nps={f.nps}
              feedback={f.feedback}
              status={f.status}
              createdAt={f.created_at}
              user={f.user.name}
              refetch={refetch}
              userID={f.user.id}
              avatarUrl={f.user.avatarUrl}
            />
          );
        })}

        {feedbacks && feedbacks.length < total && (
          <div style={{ display: 'flex' }}>
            <Button
              type="text"
              onClick={() => {
                fetchMore({
                  variables: { page: page + 1 },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    setFeedbacks([...feedbacks, ...fetchMoreResult.task?.feedback?.data]);
                  },
                });
                setPage(page + 1);
              }}>
              Exibindo {feedbacks.length} de {total}. Clique para ver mais
            </Button>
          </div>
        )}

        {feedbacks.filter((f) => f.status === 'resolved').length > 0 && (
          <div style={{ display: 'flex' }}>
            <Button id="feedbacks_see_all_task" block onClick={() => setAll((a) => !a)} type="text">
              {all ? 'Ver apenas pendentes' : 'Ver todos'}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default FeedbacksTab;
