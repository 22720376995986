import { Image, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';
import { useTranslation } from 'react-i18next';

import {
  AlertOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileSearchOutlined,
  ReloadOutlined,
  SoundOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import gifFilterYearMonth from '../../../../img/filter_year_month.gif';
import gifShowHideWeekend from '../../../../img/weekend.gif';
import styles from './styles.module.less';

interface Props {
  page?: string;
  view?: string;
}

const TutorialFilters: React.FC<Props> = ({ page, view }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '003_ActivitiesFilters' ? true : false;

  const isAccountPage = page === 'account_activities' ? true : false;
  const isGeneralViewPage = page === 'realm_activities' ? true : false;
  const isCalendarView = view === 'show_as_calendar' ? true : false;

  const { t } = useTranslation('tutorialFilters');

  const steps: any[] = [
    ...[
      isCalendarView
        ? {
            selector: '[id="filter_steps"]',
            content: () => {
              return (
                <div style={{ marginLeft: 10 }}>
                  <Typography className={styles.text}>{t('filter_steps_intro')}</Typography>

                  <Typography className={styles.item}>
                    {<ReloadOutlined className={styles.icon} style={{ color: '#3385FF' }} />} {t('step_execution')}
                  </Typography>
                  <Typography className={styles.item}>
                    {<FileSearchOutlined className={styles.icon} style={{ color: '#FF5333' }} />} {t('step_check')}
                  </Typography>
                  <Typography className={styles.item}>
                    {<UserOutlined className={styles.icon} style={{ color: '#B03591' }} />} {t('step_approval')}
                  </Typography>
                  <Typography className={styles.item}>
                    {<SoundOutlined className={styles.icon} style={{ color: '#55A630' }} />} {t('step_finish')}
                  </Typography>
                  <Typography className={styles.item}>
                    {<CheckCircleOutlined className={styles.icon} style={{ color: '#8f8f8f' }} />} {t('step_done')}
                  </Typography>

                  <Typography className={styles.text}>{t('filter_steps_exception')}</Typography>
                  <Typography className={styles.item}>
                    {<CloseCircleOutlined className={styles.icon} style={{ color: '#FF2362' }} />} {t('problem')}
                  </Typography>
                </div>
              );
            },
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="create_task"]',
      content: t('create_task'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="create_task_bottom"]',
      content: t('create_task_bottom'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      isAccountPage
        ? {
            selector: '[id="show_account_templates"]',
            content: t('show_account_templates'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      isAccountPage
        ? {
            selector: '[id="show_account_templates_bottom"]',
            content: t('show_account_templates_bottom'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="edit_task"]',
      content: t('edit_task'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      view !== 'show_by_templates'
        ? {
            selector: '[id="filter_year_month"]',
            content: () => {
              return (
                <div className={styles.avatar_menu}>
                  <div className={styles.image_div}>
                    <Image
                      alt="Filtro de período de ano e mês"
                      width={200}
                      src={gifFilterYearMonth}
                      preview={false}
                      className={styles.gif}></Image>
                  </div>

                  <Typography>{t('filter_year_month')}</Typography>
                </div>
              );
            },
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      isGeneralViewPage
        ? {
            selector: '[id="see_users_team"]',
            content: t('see_users_team'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      isAccountPage
        ? {
            selector: '[id="my_tasks_all_tasks"]',
            content: t('my_tasks_all_tasks'),
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    {
      selector: '[id="show_hide_subtask"]',
      content: t('show_hide_subtask'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="activities_views"]',
      content: t('activities_views'),
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    ...[
      isCalendarView
        ? {
            selector: '[id="filter_date_execution_finish"]',
            content: () => {
              return (
                <div style={{ marginLeft: 10 }}>
                  <Typography className={styles.text}>{t('filter_date_execution_finish')}</Typography>

                  <Typography className={styles.orange}>
                    {<AlertOutlined />} {t('warning')}
                  </Typography>

                  <Typography className={styles.attention_text}>{t('warning_text')}</Typography>
                </div>
              );
            },
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
    ...[
      isCalendarView
        ? {
            selector: '[id="show_hide_weekend"]',
            content: () => {
              return (
                <div style={{ marginLeft: 10 }}>
                  <div className={styles.image_div}>
                    <Image
                      alt="Filtro de período de ano e mês"
                      width={200}
                      src={gifShowHideWeekend}
                      preview={false}
                      className={styles.gif}></Image>
                  </div>

                  <Typography className={styles.text}>{t('show_hide_weekend_p1')}</Typography>

                  <Typography className={styles.text}>{t('show_hide_weekend_p2')}</Typography>
                </div>
              );
            },
            style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
          }
        : undefined,
    ],
  ].filter((step) => !!step);

  const ids: any[] = [
    ...[isCalendarView ? 'filter_steps' : undefined],
    'create_task',
    'create_task_bottom',
    ...[isAccountPage ? 'show_account_templates' : undefined],
    ...[isAccountPage ? 'show_account_templates_bottom' : undefined],
    'filter_year_month',
    ...[isAccountPage ? 'my_tasks_all_tasks' : undefined],
    'show_hide_subtask',
    'activities_views',
    ...[isCalendarView ? 'filter_date_execution_finish' : undefined],
    ...[isCalendarView ? 'show_hide_weekend' : undefined],
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      accentColor="#ff5333"
      maskSpace={6}
      rounded={4}
      maskClassName={styles.mask}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('main', 'tag_name')}
      onBeforeClose={() => enableScroll('main', 'tag_name')}
    />
  );
};

export default observer(TutorialFilters);
