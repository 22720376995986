import { NexusGenFieldTypes } from 'schema';
import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventFileDeleted'];
}

const TaskEventFileDeleted: React.FC<Props> = ({ data }) => {
  return (
    <TaskEvent data={data}>
      deletou o arquivo <b>{data.name}</b>
    </TaskEvent>
  );
};

export default TaskEventFileDeleted;
