import { NexusGenFieldTypes } from 'schema';
import TaskEvent from './TaskEvent';

interface Props {
  data: NexusGenFieldTypes['TaskEventNoteDeleted'];
}

const TaskEventNoteDeleted: React.FC<Props> = ({ data }) => {
  return <TaskEvent data={data}>deletou uma nota</TaskEvent>;
};

export default TaskEventNoteDeleted;
