import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { Card, Button, notification } from 'antd';

import CreateTimer from './CreateTimer';
import CurrentTimer from './CurrentTimer';
import Clock from './Clock';
import ErrorAndLoading from '../ErrorAndLoading';

import { start_timer, end_timer } from '@logic/mutations';
import { user_timer } from '@logic/queries';
import errorMessage from '@logic/functions/errorHandeling';

interface Props {
  button?: boolean;
  taskId?: string;
  from?: 'task' | 'assignment';
  refresh?: () => void;
}

const Timer: React.FC<Props> = ({ button, taskId, from, refresh }) => {
  const [hovered, setHovered] = useState(false);
  const [timer, setTimer] = useState<any>();

  const { t } = useTranslation('userTimer');

  const { data, loading, refetch } = useQuery(user_timer, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data) return;

    setTimer(data?.me?.timer);
  }, [data]);

  //--------------------------- MUTATIONS ---------------------------------

  const [StartTimer] = useMutation(start_timer);
  const [EndTimer] = useMutation(end_timer);

  //---------------------------- FUNCTIONS ----------------------------------

  async function StartClean() {
    try {
      await StartTimer();

      notification.open({
        placement: 'bottomRight',
        type: 'success',
        message: t('start_message'),
      });

      refetch();
    } catch (err) {
      notification.open({
        placement: 'bottomRight',
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }
  async function Start(body?: string) {
    if (!from) return;
    try {
      await StartTimer({ variables: { task: taskId || '', body: body || '' } });

      notification.open({
        placement: 'bottomRight',
        type: 'success',
        message: t('start_message'),
      });

      if (refresh) refresh();
      refetch();
    } catch (err) {
      notification.open({
        placement: 'bottomRight',
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }
  async function Stop() {
    if (!(timer?.task?.id || taskId)) {
      notification.open({
        placement: 'bottomRight',
        type: 'error',
        message: t('message_without_task'),
        description: t('description_without_task'),
      });
      return;
    }
    try {
      await EndTimer({ variables: { task: timer?.task?.id || taskId } });

      notification.open({
        placement: 'bottomRight',
        type: 'success',
        message: t('stop_message'),
      });

      if (refresh) refresh();
      refetch();
    } catch (err) {
      notification.open({
        placement: 'bottomRight',
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  //------------------------------------------------------------------------------

  if (taskId && !button) {
    if (loading) return <ErrorAndLoading loading />;

    return (
      <>
        {!!timer && (timer.task?.id === taskId || !timer.task?.id) && (
          <CurrentTimer
            taskId={taskId}
            timerId={timer.id}
            timerBody={timer.body}
            startTime={timer.start_date}
            refresh={() => {
              if (refresh) refresh();
              refetch();
            }}
          />
        )}

        {!timer && (
          <Card id="start_timer" style={{ marginBottom: '16px' }}>
            <Button title={t('button_tooltip_start_timer')} type="primary" block onClick={() => Start()}>
              {t('button_start_timer')}
            </Button>
          </Card>
        )}

        <CreateTimer
          taskId={taskId}
          refresh={() => {
            if (refresh) refresh();
            refetch();
          }}
        />
      </>
    );
  }

  if (!!timer) {
    if (!!taskId && timer.task?.id !== taskId) return null;

    return (
      <Button
        loading={loading}
        type="primary"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => Stop()}>
        {hovered && !!timer.task?.id ? t('stop_timer') : <Clock time={timer.start_date} />}
      </Button>
    );
  }

  if (!timer && !taskId) {
    return (
      <Button
        id="button_start_independent_timer"
        type="primary"
        onClick={() => {
          StartClean();
        }}>
        {t('button_start_timer')}
      </Button>
    );
  }

  return null;
};

export default Timer;
